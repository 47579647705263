import { changePwd, login, logout, register, resendPwd, loginOpenId, loginGuest, getUserPreferences } from '@/api/auth';
import { updateUser, getUser } from '@/api/user';
import { clearCredentials, getCredentials, checkAuth } from '@/utils/utils';
import { hasUserWebauthn, userHasPin } from '@/api/pin';

import { UserData, RegisterUserT, MarketingPermissions, ThingAvatars, OdometerScreen } from './types';
import router from '@/router.js';

const defaultAddress = {
  city: '',
  houseNumber: '',
  street: '',
  street2: '',
  zip: ''
};

function isDefaultData(address) {
  return Object.keys(defaultAddress).every(key => defaultAddress[key] === address[key]);
}

function generatePayload(user) {
  const userData = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    address: {
      ...user.address,
      countryCode: user.address.countryCode || user.localisation.country
    },
    taxInfo: {
      country: user.taxInfo?.country,
      taxId: user.taxInfo?.taxId,
    },
    id: user._id,
    accountId: user.accounts[0].id,
    thingId: user.things[0].id,
    localisationCountry: user.localisation.country,
  };
  // API missing address data when user is new
  if (!userData.address.street) {
    userData.address = {
      ...userData.address,
      city: '',
      houseNumber: '',
      street: '',
      street2: '',
      zip: ''
    };
  }

  // Remove default address data
  if (isDefaultData(userData.address)) {
    userData.address = {
      city: '',
      houseNumber: '',
      street: '',
      street2: '',
      zip: '',
      countryCode: userData.address.countryCode
    };
  }

  return userData;
}

const defaultState: UserData = {
  email: null,
  id: null,
  accountId: null,
  thingId: null,
  firstName: null,
  lastName: null,
  taxInfo: null,
  address: null,
  twoFactorMethod: null,
  localisationCountry: null,
  quickCheckout: false,
  isGuest: false,
  odometerScreenData: null,
  marketingPermissions: [],
  userCurrency: null,
  userPreferences: null,
  hasBoxAssociated: false,
  selectedVehicle: null
};

const mutations = {
  setData(state: UserData, data: UserData) {
    Object.assign(state, data);
  },
  resetState(state: UserData) {
    Object.assign(state, defaultState);
  },
  updateUserProfile(state: UserData, data: UserData) {
    state = { ...state, ...data };
  },
  setTwoFactorMethod(state: UserData, data: string) {
    state.twoFactorMethod = data;
  },
  setQuickCheckout(state: UserData, data: boolean) {
    state.quickCheckout = data;
  },
  setGuestUser(state: UserData, data: boolean) {
    state.isGuest = data;
  },
  setOdometerScreen(state: UserData, data: OdometerScreen) {
    state.odometerScreenData = data;
  },
  setMarketingPermissions(state: UserData, data: MarketingPermissions[]) {
    state.marketingPermissions = data;
  },
  setUserPreferences(state: UserData, data: ThingAvatars[] | null) {
    state.userPreferences = data;
  },
  setUserCurrency(state: UserData, data: string) {
    state.userCurrency = data;
  },
  setHasBoxAssociated(state: UserData, data: boolean) {
    state.hasBoxAssociated = data;
  },
  setSelectedVehicle(state: UserData, data: ThingAvatars) {
    state.selectedVehicle = data;
  }
};

const actions = {
  async loadAllUserFeatures({ dispatch }, accountId) {
    await dispatch('psp/loadFeatures', accountId, { root: true });
    await dispatch('psp/loadActivePM', accountId, { root: true });
    // We load the loyalty at the beginning because otherwise we should make 3 additional request to this endpoint
    // In the loyalty page, in the summary postpay and prepay
    await dispatch('loyalty/loadActiveLoyalty', undefined, { root: true });
  },
  /**
   * @name checkAuthStatus
   * @param store 
   * @param {Boolean} forceAuthorization If true we force to query the API 
   */
  async checkAuthStatus({ commit, state, dispatch }, forceAuthorization: boolean) {

    if (!checkAuth()) {
      throw new Error('no Cookie found');
    }

    if (state.email && !forceAuthorization) {
      return;
    }

    commit('ui/setLoading', true, { root: true });

    try {
      const { userId } = getCredentials();
      const userData = await getUser(userId);
      commit('setUserCurrency', userData.localisation.currency);

      if (userData.passwordExpired) {
        window.location.assign('/change-tmp-password');
      }
      commit('setData', generatePayload(userData));

      await dispatch('loadAllUserFeatures', state.accountId);
      await dispatch('ui/saveURLParamsForUi', false, { root: true });
    } catch (error) {
      console.log('check Auth Status', error);
      commit('auth/clearLoyaltyState', { root: true });
      error.response?.status === 401 && await dispatch('loginGuest');
    } finally {
      commit('ui/setLoading', false, { root: true });
    }

    dispatch('setTwoFactorMethod');
  },
  async login({ commit, dispatch, state }, { email, password }: { email: string; password: string; }) {
    commit('ui/setLoading', true, { root: true });

    try {
      const authData = await login(email, password);
      if (authData.passwordExpired) {
        throw new Error('temp_pass');
      }

      commit('setData', generatePayload(authData));
      await dispatch('loadAllUserFeatures', state.accountId);
    } catch (error) {
      if (error.response?.data.errorCode === 125 || error.response?.data.errorCode === 127) {
        throw new Error('wrong_login_details');
      } else {
        throw error;
      }
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
    dispatch('setTwoFactorMethod');
  },
  async loginGuest({ commit, dispatch, state }) {
    commit('ui/setLoading', true, { root: true });

    try {
      const authData = await loginGuest();

      commit('setData', generatePayload(authData));
      
      await dispatch('loadAllUserFeatures', state.accountId);
      commit('history/resetHistory', undefined, { root: true });

    } catch (error) {
      console.log('login guest error', error);
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async loginOpenId({ commit, dispatch, state }, { token, provider = 'GOOGLE' }: { token: string; provider: string; }) {
    commit('ui/setLoading', true, { root: true });

    try {
      const authData = await loginOpenId(provider, token);
      commit('setData', generatePayload(authData));
      await dispatch('loadAllUserFeatures', state.accountId);

    } finally {
      commit('ui/setLoading', false, { root: true });
    }
    dispatch('setTwoFactorMethod');
  },
  clearAllStore({ commit }) {
    clearCredentials();
    commit('resetState');
    commit('history/resetHistory', undefined, { root: true });
    commit('loyalty/clearLoyaltyState', undefined, { root: true });
    commit('psp/clearPSPState', undefined, { root: true });
    commit('vouchers/clearVouchersState', undefined, { root: true });
  },
  async logout({ commit, dispatch }) {
    if (!checkAuth()) {
      throw new Error('nothing to logout');
    }
    commit('ui/setLoading', true, { root: true });
    try {
      await logout();
      dispatch('clearAllStore');

    } finally {
      commit('ui/setLoading', false, { root: true });
    }
    commit('ui/setLoading', false, { root: true });
  },
  async register({ state, commit }, data: RegisterUserT) {
    commit('ui/setLoading', true, { root: true });
    try {
      const partnerFromURL = router.currentRoute.value.query.partner;
      partnerFromURL && localStorage.setItem('partner', partnerFromURL as string);

      await register({
        ...data,
        referrer: partnerFromURL || undefined,
        marketingPermissions: state.marketingPermissions
      });
    } catch (error) {
      if (error.response?.data?.message.includes('Already registered')) {
        throw new Error('email_has_account');
      }
      throw error;
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async forgotPwd({ commit }, email: string) {
    commit('ui/setLoading', true, { root: true });
    try {
      await resendPwd(email);
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async changePwd({ commit }, newPassword: string) {
    commit('ui/setLoading', true, { root: true });
    try {
      await changePwd(newPassword);
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },

  async updateUser({ commit }, data: UserData) {
    commit('ui/setLoading', true, { root: true });
    try {
      const { userId } = getCredentials();
      await updateUser(userId, data);
      commit('setData', data);
      commit('updateUserProfile', data);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error;
      throw new Error(errorMessage);
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async setTwoFactorMethod({ state, commit, rootGetters }) {

    const [hasWebauthn, hasPin] = await Promise.all([hasUserWebauthn({ userId: state.id }), userHasPin()]);

    const twoFactorMethod = hasWebauthn ? 'webauthn' : hasPin ? 'pin' : 'password';
    commit('setTwoFactorMethod', twoFactorMethod);

    try {
      const origin = state.twoFactorMethod === 'password' && rootGetters['psp/checkoutMethod'] ? 'native' : 'webapp';
      window._paq.push(['setCustomDimension', 1, origin]);
    } catch (e) {
      console.debug(e);
    }
  },
  async storeUserPreferences({ state, commit }) {
    try {
      const userPreferences = await getUserPreferences();
      commit('setUserPreferences', userPreferences);
      if (!state.userPreferences.length) throw new Error('things empty');

      const hasBoxAssociated = userPreferences.some(item => item.package === 'PREMIUM');
      commit('setHasBoxAssociated', hasBoxAssociated);

      const selectedVehicle = state.userPreferences[0];
      commit('setSelectedVehicle', selectedVehicle);

    } catch (error) {
      console.log('Get preferences error', error);
    }
  }
};

const getters = {
  isLoggedIn(state) {
    return !!state.email && !state.isGuest;
  },
  getAccountId(state) {
    return state.accountId;
  }
};

export default {
  state: Object.assign({}, defaultState),
  mutations,
  actions,
  getters
};
