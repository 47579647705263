// import store from '../index.js';

// https://vitejs.dev/guide/features.html#glob-import
const storeModules = import.meta.glob('./*/*.ts', { eager: true });
const modules = {};

Object.keys(storeModules).forEach(key => {
  const moduleName = key.replace(/(\.\/|\.ts)/g, '').split('/')[0];

  if (storeModules[key].default) {
    modules[moduleName] = storeModules[key].default;
    modules[moduleName].namespaced = true;
  }
});

// /*
// This here seems to be crazy - unfortunately It's needed and can't be done by a array.map because Vite use string 
// replacement on the serve to cover this kind of stuff.

// Maybe there is a better solution for Vite + VueX + Hot Module Reloading i dont find. Also it seems like this 
// functionality is build in without any config on Pinia.js => Maybe a good argument to try it?
// */
if (import.meta.hot) {
  import.meta.hot.accept(
    [
      './ui/ui.ts',
      './pay/pay.ts',
      './psp/psp.ts',
      './auth/auth.ts',
      './station/station.ts',
      './register/register.ts',
      './loyalty/loyalty.ts',
      './vouchers/vouchers.ts',
      './history/history.ts'
    ],
    data => {
      const changedModule = data.findIndex(module => module);
      const moduleName = [
        'ui/ui',
        'pay/pay',
        'psp/psp',
        'auth/auth',
        'station/station',
        'register/register',
        'loyalty/loyalty',
        'vouchers/vouchers',
        'history/history'
      ];
      store.hotUpdate({
        modules: {
          [moduleName[changedModule]]: {
            ...data[changedModule].default,
            namespaced: true
          }
        }
      });
    }
  );
}

export default modules;
