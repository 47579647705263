import { createApp } from 'vue';
import VueMatomo from 'vue-matomo';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './veevalidate.js';

import * as Sentry from '@sentry/vue';
import './assets/styles/tailwind.css';

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

window.env = VITE_GIT;

const app = createApp(App);
const siteId = parseInt(import.meta.env.VUE_APP_MATOMO_ID);
if (siteId) {
  app.use(VueMatomo, {
    host: 'https://rydpaytwo.matomo.cloud',
    siteId,
    router: router,
    disableCookies: true,
    requireConsent: true,
  });
}

Sentry.init({
  app,
  dsn: 'https://a0f2fdf3f3a844058a15682aa3cf9d10@o933751.ingest.sentry.io/5882917',
  release: VITE_GIT.commit,
  enabled: import.meta.env.VUE_APP_ENV != 'dev',
  environment: import.meta.env.VUE_APP_ENV
});

app.use(store);
app.use(i18n);
app.use(router);

const mounted = app.mount('#vue');
router.app = mounted; // https://next.router.vuejs.org/guide/migration/#removal-of-router-app

if (import.meta.env.VUE_APP_ENV != 'prod') {
  console.debug(`Starting: ${VITE_GIT.branch}/${VITE_GIT.commit}`);
}
