<template>
  <TopBarAuth :isGuest="isGuest" />
  <TabNav />
  <div v-if="componentReady" class="view-block w-full px-4">
    <component :is="componentToLoad" />
  </div>
</template>
<script>
import TopBarAuth from '../components/TopBarAuth.vue';
import TabNav from '../components/TabNav.vue';
import Login from './Login.vue';
import Register from './Register.vue';

import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';

export default {
  components: {
    TopBarAuth,
    TabNav,
    Login,
    Register
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isGuest = computed(() => store.state.auth.isGuest);
    const componentToLoad = computed(() => (route.path === '/login' ? 'Login' : 'Register'));
    const componentReady = ref(false);

    onMounted(async () => {
      try {
        await store.dispatch('auth/checkAuthStatus');

        // if user was a guest with an order and wants to login
        // and user refresh the page we need to retrieve the station and the pump chosen
        // so we can create a new order
        if (route.query.orderType) {
          await store.dispatch('station/getOnce');
          store.commit('station/saveURLParamsForTrackOrder', { pumpId: route.query.pumpId });
        }
      } catch (e) {
        console.error(e);
      } finally {
        componentReady.value = true;
      }
    });
    return { componentToLoad, componentReady, isGuest };
  }
};
</script>