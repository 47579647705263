/**
HOW THIS WORKS
All Script are seperated in different levels:

**Level 1**: Things that needs to be there to make the app working
How: Directly import it so it is part of the bundle
Examples: Vue, Vuex, VueRouter

**Level 2**: Things that will be used by a lot of users but not needed for the first load
How: Import it async (Import()) so it is in a external chunk and directly loaded after the app is started
Examples: VueMatomo, Paypal, Zendesk

**Level 3**: Things that will be used potentially used in the App and should be prefetched
How: Import it async where it's needed but also put it here in level 3 so it will be added to the prefetch header
Examples: Apple SSO, Google SSO, Apple Pay

**Level 4**: Scripts that only be needed in specific cases
How: Async import this Scripts only on user interaction (e.g. open a specific route) with Loading Indicator
Examples: Other Languages than german, VueAgile (Carousel-Component)
*/

export async function loadScript(src, addionalAttributes = {}) {
  return new Promise(function (resolve, reject) {
    let el = document.querySelector('script[src="' + src + '"]');
    if (!el) {
      el = document.createElement('script');
      el.type = 'text/javascript';
      el.src = src;
      for (let key in addionalAttributes) {
        el.setAttribute(key, addionalAttributes[key]);
      }
      document.body.appendChild(el);
    } else if (el.hasAttribute('data-loaded')) {
      resolve(el);
      return;
    }

    el.addEventListener('error', reject);
    el.addEventListener('abort', reject);
    el.addEventListener('load', function loadScriptHandler() {
      el.setAttribute('data-loaded', true);
      resolve(el);
    });
  });
}
