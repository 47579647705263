<template>
  <label class="font-medium text-tertiary-text" for="country">{{ t('account.country_label') }}</label>
  <select
    id="country"
    name="country"
    :value="props.modelValue"
    class="py-1 px-0 border-b-2 h-[31px] mb-6"
    @change="countryChanged"
  >
    <option v-for="country in countries" :key="country.key" :value="country.key" :selected="country.key === modelValue">
      {{ country.value }}
    </option>
  </select>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const options = ['DE', 'AT', 'CH', 'NL', 'BE', 'LU', 'PT', 'DK', 'PL', 'ES'];

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  }
});

const emits = defineEmits(['update:modelValue']);

const countries = computed(() => {
  return options
    .map(countryCode => {
      return {
        key: countryCode,
        value: t(`account.countries.${countryCode}`)
      };
    })
    .sort((a, b) => a.value.localeCompare(b.value));
});

const countryChanged = (event) => {
  emits('update:modelValue', event.target.value);
};
</script>