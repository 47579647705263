<template>
  <Teleport to="#topBarIconLeft">
    <RPButton
      data-cy="goToProfile"
      theme="custom"
      class="bg-transparent w-10 py-0 px-0 min-w-min"
      @click="backButtonRedirect()"
    >
      <RPIcon type="arrowLeft" svgFill="text" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span data-cy="historyTitle">
      {{ $t(componentTitle) }}
    </span>
  </Teleport>
  <template v-if="componentReady">
    <TripDetails 
      v-if="event && event.type === 'TRIP'" 
      :event="(event as HistoryTripEventDetail)" 
    />
    <PaymentDetails 
      v-else-if="event && event.type === 'PAYMENT'" 
      :event="(event as HistoryPaymentEventDetail)" 
    />
    <CostDetails 
      v-else-if="event && event.type === 'COST'" 
      :event="(event as HistoryCostDetail)"
      :error="APIError"
      @update="updateEvent"
      @delete="deleteEvent"
    />
 
    <div v-else class="px-4">
      {{ $t('history.event_not_found') }}
    </div>
  </template>
  <LoadingOverlay v-else />
</template>

<script setup lang="ts">
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import TripDetails from './components/TripDetails.vue';
import PaymentDetails from './components/PaymentDetails.vue';
import CostDetails from './components/CostDetails.vue';
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';

import { useStore } from 'vuex';
import { HistoryPaymentEventDetail, HistoryTripEventDetail, HistoryCostDetail, HistoryCreateCostPayload } from '@/store/modules/history/types';

import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';

const store = useStore();
const route = useRoute();
const router = useRouter();

const componentReady = ref(false);
onMounted(async () => {
  try {
    !store.state.auth.userPreferences && await store.dispatch('auth/storeUserPreferences');
    await store.dispatch('history/getEventById', route.params.id as string);
  } catch (error) {
    console.log(error);
  } finally {
    componentReady.value = true;
  }
});

const event = computed(() => store.state.history.event);

const componentTitle = computed(() => {
  const titleMap = {
    TRIP: 'history.details.title_trip',
    PAYMENT: 'history.details.title_payments',
    COST: 'history.details.title_cost',
  };

  return event.value ? titleMap[event?.value.type as keyof typeof titleMap] : '';

});

const backButtonRedirect = () => {
  const urlCallbackObject = new URL('webViewEvent://eventDetailBack');
  return route.query.detailsCallback ? window.location.replace(urlCallbackObject.toString()) : router.push('/history');
};

// Update
interface PayloadUpdate {
  form: HistoryCreateCostPayload;
  images: {
    imageBuffer: ArrayBuffer;
    type: string;
  }[];
}
const APIError = ref();
const updateEvent = async (payload: PayloadUpdate) => {
  try {
    componentReady.value = false;
    APIError.value = null;

    await store.dispatch('history/updateEventCost', { payload: payload.form, id: route.params.id as string });

    if (payload.images.length) {
      payload.images.map(async (image) => {
        await store.dispatch('history/uploadImage', { image: image.imageBuffer, type: image.type });
      });

    }
    router.push('/history');
  } catch (error: any) {

    console.log(error);
    if (error.response?.data?.message?.includes('carOdometerM')) {
      APIError.value = 'mileage';
    } else {
      router.push('/history/error');
    }
  } finally {
    componentReady.value = true;
  }
};

// Delete
interface PayloadDelete {
  category: string;
  deleteType: string;
}
const deleteEvent = async (payload: PayloadDelete) => {
  componentReady.value = false;
  try {
    await store.dispatch(
      'history/deleteEventCost',
      { category: payload.category, id: (route.params.id as string), type: payload.deleteType }
    );
    router.push('/history');
  } catch (error) {
    console.log(error);
    router.push('/history/error');
  } finally {
    componentReady.value = true;
  }
};
</script>