<template>
  <div v-if="isPumpChosen" class="px-6 l-scape:max-w-[var(--appMaxWidthContainerLandscape)] l-scape:mx-auto">
    <section
      class="
        flex flex-col space-y-4 l-scape:space-y-0
        font-medium
        l-scape:grid
        l-scape:grid-cols-2
        l-scape:gap-[var(--space-lg)]
      ">
      <RPCard
        v-for="(fuel, index) in fuels"
        :key="fuel.id"
        :data-cy="`fuel-${index}`"
        small
        hasClick
        class="justify-center l-scape:h-20 flex"
        @click="setFuelId(fuel.id)">
        <span>{{ fuel.name }}</span>
        <span class="text-fs-14 block l-scape:text-fs-title">
          {{ getFuelPrice(fuel.name) }}
        </span>
      </RPCard>
    </section>
  </div>
</template>
<script>
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import { userLanguage } from '@/i18n';
import { mapState, mapGetters } from 'vuex';

export default {
  components: { RPCard },
  mounted() {
    !this.isPumpChosen && this.$router.push({ path: '/pay' });
  },
  computed: {
    ...mapState({
      isLandscape: state => Boolean(state.ui.query.landscape),
      stationFeatures: state => state.station.data.features,
      fuelPrices: state => state.station.data.fuelPrices
    }),
    ...mapGetters({
      payFlowPages: 'pay/payFlowPages',
      isPumpChosen: 'station/isPumpChoosen'
    }),
    fuels() {
      const fuelTypes = [...this.$store.getters['station/chosenPump'].fuelTypes];
      const [lang] = userLanguage().split('-');
      return fuelTypes.sort((a, b) => a.name.localeCompare(b.name, lang));
    }
  },
  methods: {
    setFuelId(fuelTypeId) {
      this.$store.commit('station/saveURLParamsForTrackOrder', { fuelTypeId });

      const nextPagePath = this.payFlowPages.nextPagePath;
      this.$router.push(nextPagePath);
    },
    formatAmount(fuelPrice) {
      return new Intl.NumberFormat(localStorage.getItem('lang') || undefined, {
        style: 'currency',
        currency: fuelPrice.currency,
        minimumFractionDigits: fuelPrice.precision,
        maximumFractionDigits: fuelPrice.precision
      }).format(fuelPrice.amount);
    },
    getFuelPrice(fuelName) {
      if (this.stationFeatures?.includes('pumpsWithoutDisplay') && this.fuelPrices?.length > 0) {
        const fuelPrice = this.fuelPrices.find(price => price.name.toLowerCase() === fuelName.toLowerCase());
        return fuelPrice?.isRealTimePrice ? this.formatAmount(fuelPrice) : '-.---';
      }
      return '';
    }
  }
};
</script>
<style lang="scss" scoped></style>
