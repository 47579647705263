// Doc: https://tt4.thinxcloud-dev.de/doc/#api-Accountss-getAccountFeatures

import axios from './index';
import { isNil } from 'lodash';

const parseResponseActivePM = response => {
  return response.reduce((acc, item) => {
    const { card, paypal, sepa, customProperties, createdAt } = item;
    const notWalletPayments = [card, paypal, sepa, customProperties];

    const payment = notWalletPayments.find(el => !isNil(el));

    const paymentData = {
      createdAt,
      id: item.id,
      createdAt,
      provider: item.provider,
      method: item.providerMethod,
      active: item.state === 'ACTIVE',
      isPreferred: item.isPreferred,
      details: { ...payment }
    };
    acc.push(paymentData);
    return acc;
  }, []);
};

export async function getFeatures(accountId) {
  const response = await axios.get(`/accounts/${accountId}/features`);

  const quickCheckoutData = response.data.find(i => i.feature === 'quickCheckout');
  const quickCheckout = quickCheckoutData?.status === 'ENABLED' ?? false;

  const guestUserData = response.data.find(i => i.feature === 'guestUser');
  const guestUser = guestUserData?.status === 'ENABLED' ?? false;

  const odometerScreenData = response.data.find(i => i.feature === 'showOdometerScreenBeforeCheckout');
  const odometerScreenFeat = odometerScreenData?.status === 'ENABLED' ? odometerScreenData : false;

  const loyaltyProgramsData = response.data.find(i => i.feature === 'loyaltyPrograms');
  const loyaltyPrograms = loyaltyProgramsData?.status === 'ENABLED' ? loyaltyProgramsData.programs : null;

  const pspData = response.data.find(i => i.feature === 'psp');

  /* https://phabricator.thinxcloud.de/T13330
      In some cases the user doesn't have PSPs - so there is no use to login
      */
  if (!pspData.providers.length) {
    throw new Error('noPSP');
  }

  const psp = pspData.providers
    .map(provider => {
      return provider.methods.map(method => {
        return {
          provider: provider.provider,
          method: method.method,
          active: method.subscriptionStatus === 'SUBSCRIBED',
          details: method.payload || {}
        };
      });
    })
    .flat();

  const supportHotline = response.data.find(i => i.feature === 'helpScreen')?.supportHotline || '+49 89 4520 663 21';

  return { psp, supportHotline, quickCheckout, guestUser, loyaltyPrograms, odometerScreenFeat };
}

export const getActivePayments = async accountId => {
  const response = await axios.get(`/v4/payment-methods/?customerId=${accountId}&state=ACTIVE`, {
    validateStatus: status => {
      return status;
    }
  });
  if (response.status >= 400 && response.status <= 502) {
    throw new Error('Retrieving active payment methods failed');
  }

  const psp = parseResponseActivePM(response.data);
  return psp;
};

export const getSupportedActivePayments = async (accountId, poiID) => {
  const response = await axios.get(
    `/v4/payment-methods/?customerId=${accountId}&state=ACTIVE&supportedByPoi=${poiID}`,
    {
      validateStatus: status => {
        return status;
      }
    }
  );

  if (response.status >= 400 && response.status <= 502) {
    throw new Error('Retrieving supported active payment methods failed');
  }

  const psp = parseResponseActivePM(response.data);
  return psp;
};

export const setNewPaymentMethod = async (method, provider, customerId) => {
  await axios({
    method: 'post',
    url: '/payment-methods/',
    data: {
      provider,
      providerMethod: method,
      customerId
    }
  });
};

export const setPreferredPayment = async paymentId => {
  await axios.post(`/v4/payment-methods/${paymentId}/set-as-preferred`);
};

export const deactivatePayment = async paymentId => {
  await axios.post(`/v4/payment-methods/${paymentId}/deactivate`);
};

export const getActiveLoyalty = async () => {
  const response = await axios.get(`/loyalty-subscriptions`, {
    validateStatus: status => {
      return status;
    }
  });
  if (response.status >= 400 && response.status <= 502) {
    throw new Error('Retrieving active loyalty programs failed');
  }

  return response.data;
};
