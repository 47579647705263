<template>
  <div>
    <div id="payWithGoogleButton" />
  </div>
</template>

<script setup lang="ts">
import { prefetch, createButton } from '@/utils/gpay';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
  onClickEvent: {
    type: Function,
    required: true
  }
})

const loadGPay = computed(() => store.getters['pay/gpayPayload'])

onMounted(async () => {
  const payButton = await createButton(props.onClickEvent);
  document.getElementById('payWithGoogleButton').replaceWith(payButton);
  await store.dispatch('pay/getMerchantConfig')
  prefetch(loadGPay.value);
})
</script>
