import { getActiveLoyalty, subscribeLoyalty, deactivateLoyalty } from '@/api/loyalty';
import { LOYALTY_NAME_MAP } from '@/composable/useLoyalty';
import { LoyaltyState, LoyaltyFeatureProvider, LoyaltySubscription } from './types';

const getDefaultState = (): LoyaltyState => {
  return {
    loyaltyPrograms: [],
    activeLoyalty: [],
    error: null,
    loyaltyIcons: null
  };
};
const state: LoyaltyState = getDefaultState();


const mutations = {
  setActiveLoyalty(state: LoyaltyState, payload: LoyaltySubscription[]) {
    state.activeLoyalty = payload;
  },
  setLoyaltyPrograms(state: LoyaltyState, providers: LoyaltyFeatureProvider[]) {

    const loyaltyProvidersMapped = providers.filter(item => LOYALTY_NAME_MAP.includes(item.name));
    state.loyaltyIcons = loyaltyProvidersMapped.map(({ name, icon }) => ({ name, icon }));

    state.loyaltyPrograms = providers;
  },
  setError(state: LoyaltyState, error) {
    state.error = error;
  },
  clearLoyaltyState(state) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  async loadActiveLoyalty({ commit, dispatch, rootState }) {
    try {

      const loyalty: LoyaltySubscription[] = await getActiveLoyalty();
      commit('setActiveLoyalty', loyalty);
    } catch (e) {
      commit('setActiveLoyalty', []);
      console.error("Retrieving loyalty action failed'", e);
    }
  },

  async registerLoyalty({ commit, dispatch }, { loyaltyId, cardNumber }: { loyaltyId: string, cardNumber: number; }) {
    commit('ui/setLoading', true, { root: true });
    try {
      await subscribeLoyalty(loyaltyId, cardNumber);

      // Force reload AuthData
      await dispatch('loadActiveLoyalty');

    } catch (e) {
      console.log('register Loyalty error', e);
      throw new Error(e);

    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async deactivateLoyalty({ commit, dispatch }, { loyaltyId }: { loyaltyId: string; }) {
    commit('ui/setLoading', true, { root: true });
    try {
      await deactivateLoyalty(loyaltyId);

      // Force reload AuthData
      await dispatch('loadActiveLoyalty');

    } catch (e) {
      console.log('deactivate Loyalty error', e);
      throw new Error(e);

    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
