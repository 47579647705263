<template>
  <div class="grid grid-cols-[40%_auto] gap-x-2.5">
    <div>
      <label 
        class="font-medium text-tertiary-text" 
        for="country" 
        v-html="t('account.country_label')"
      ></label>
      <select 
        id="country" 
        ref="countryCode" 
        v-model="country"
        class="appearance-none py-1 px-0 border-b-2"
      >
        <option v-for="(item, key) of countryOptions" :key="key" :value="key">
          {{ `${item.name} (+${item.countryCode})` }}
        </option>
      </select>
    </div>
    <div>
      <label for="phone-number" :class="{
        'text-error': customError,
        'text-tertiary-text': !customError
      }" v-html="t('account.phone')"
      ></label>
      <input 
        id="phone-number" 
        ref="inputRef" 
        v-model="number" 
        type="tel"
        class="py-1 border-b-2 mb-4 read-only:border-b-0"
        @blur="checkField"
        @input="phoneInputChange"
      />
      <span 
        v-if="customError" 
        class="block py-[5px] px-0 text-error text-fs-caption"
        data-cy="error-message"
      >
        {{ customError }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
// https://gitlab.com/catamphetamine/libphonenumber-js#min-vs-max-vs-mobile-vs-core
import parsePhoneNumber from 'libphonenumber-js/max';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

// From https://raw.githubusercontent.com/annexare/Countries/master/data/countries.json (but filtert for european countries)
const config = {
  AD: { name: 'Andorra', countryCode: 376 },
  AL: { name: 'Shqipëria', countryCode: 355 },
  AT: { name: 'Österreich', countryCode: 43 },
  AX: { name: 'Åland', countryCode: 358 },
  BA: { name: 'Bosna i Hercegovina', countryCode: 387 },
  BE: { name: 'België', countryCode: 32 },
  BG: { name: 'България', countryCode: 359 },
  BY: { name: 'Белару́сь', countryCode: 375 },
  CH: { name: 'Schweiz', countryCode: 41 },
  CY: { name: 'Κύπρος', countryCode: 357 },
  CZ: { name: 'Česká republika', countryCode: 420 },
  DE: { name: 'Deutschland', countryCode: 49 },
  DK: { name: 'Danmark', countryCode: 45 },
  EE: { name: 'Eesti', countryCode: 372 },
  ES: { name: 'España', countryCode: 34 },
  FI: { name: 'Suomi', countryCode: 358 },
  FO: { name: 'Føroyar', countryCode: 298 },
  FR: { name: 'France', countryCode: 33 },
  GB: { name: 'United Kingdom', countryCode: 44 },
  GG: { name: 'Guernsey', countryCode: 44 },
  GI: { name: 'Gibraltar', countryCode: 350 },
  GR: { name: 'Ελλάδα', countryCode: 30 },
  HR: { name: 'Hrvatska', countryCode: 385 },
  HU: { name: 'Magyarország', countryCode: 36 },
  IE: { name: 'Éire', countryCode: 353 },
  IM: { name: 'Isle of Man', countryCode: 44 },
  IS: { name: 'Ísland', countryCode: 354 },
  IT: { name: 'Italia', countryCode: 39 },
  JE: { name: 'Jersey', countryCode: 44 },
  LI: { name: 'Liechtenstein', countryCode: 423 },
  LT: { name: 'Lietuva', countryCode: 370 },
  LU: { name: 'Luxembourg', countryCode: 352 },
  LV: { name: 'Latvija', countryCode: 371 },
  MC: { name: 'Monaco', countryCode: 377 },
  MD: { name: 'Moldova', countryCode: 373 },
  ME: { name: 'Црна Гора', countryCode: 382 },
  MK: { name: 'Северна Македонија', countryCode: 389 },
  MT: { name: 'Malta', countryCode: 356 },
  NL: { name: 'Nederland', countryCode: 31 },
  NO: { name: 'Norge', countryCode: 47 },
  PL: { name: 'Polska', countryCode: 48 },
  PT: { name: 'Portugal', countryCode: 351 },
  RO: { name: 'România', countryCode: 40 },
  RS: { name: 'Србија', countryCode: 381 },
  RU: { name: 'Россия', countryCode: 7 },
  SE: { name: 'Sverige', countryCode: 46 },
  SI: { name: 'Slovenija', countryCode: 386 },
  SJ: { name: 'Svalbard og Jan Mayen', countryCode: 4779 },
  SK: { name: 'Slovensko', countryCode: 421 },
  SM: { name: 'San Marino', countryCode: 378 },
  UA: { name: 'Україна', countryCode: 380 },
  VA: { name: 'Vaticano', countryCode: 379 },
  XK: { name: 'Republika e Kosovës', countryCode: 377 }
};

const emits = defineEmits(['update:modelValue', 'update:customError']);

const store = useStore();
const { t } = useI18n();

const country = ref(store.state.auth.address?.countryCode || '');
const number = ref(null);
const customError = ref('');
const inputRef = ref(null);

const formattedNumber = computed(() => {
  if (!number.value) {
    return null;
  }
  try {
    const phoneNumber = parsePhoneNumber(number.value, country.value);
    const { countryCallingCode, nationalNumber } = phoneNumber || {};

    if (countryCallingCode && nationalNumber) {
      return `${countryCallingCode}#${nationalNumber}`;
    }
  } catch (error) {
    console.error(error);
  }

  return null;
});

const countryOptions = computed(() => config);

watch(formattedNumber, (newVal, oldVal) => {
  emits('update:modelValue', newVal);
});

watch(country, (newVal, oldVal) => {
  if (number.value && newVal !== oldVal) {
    inputRef.value.focus();
    inputRef.value.blur();
  }
});

const checkField = () => {
  if (!number.value) {
    customError.value = t('errors.required_field_error', { field: 'Phone number' });
  } else if (parsePhoneNumber(number.value, country.value)?.isValid()) {
    customError.value = '';
  } else {
    customError.value = t('errors.invalid_phone_number');
  }
  emits('update:customError', customError.value);
};

const phoneInputChange = () => customError.value = '';



onMounted(() => {
  store.commit('ui/setLoading', true);
  store.commit('ui/setLoading', false);
});
</script>

<style lang="scss" scoped></style>
