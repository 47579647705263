import axios from './index';

export const getOrder = async(orderId) => {
  const response = await axios.get(`v4/order/${orderId}`);
  return response.data
};

export const patchOdometer = async (orderId, carOdometerM) => {
  await axios({
    method: 'patch',
    url: `/events/fastlane-topup/${orderId}`,
    data: {
      tankIsFull: false,
      carOdometerM
    }
  });
};