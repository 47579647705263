<template>
  <div class="px-4">
    <RPIcon :type="`hints/${hintMAp[type].icon}`" noFill class="my-6" />
    <p 
    v-if="type === 'h2LegalHint'" 
    class="text-fs-14 font-medium text-center" 
    v-html="$t(hintMAp.h2LegalHint.text, {
      videoTutorial: 'https://www.youtube.com/watch?v=7PLpO3BiNLw'
    })" 
    />
    <p v-else class="text-fs-14 font-medium text-center">{{ $t(hintMAp[type].text) }}</p>
  </div>
  <div class="px-4">
    <RPButton @click="$emit('closeHint')" >
      {{ type === 'h2LegalHint' ? $t(hintMAp.h2LegalHint.button) : $t('hints.btn_cta') }}
    </RPButton>
  </div>    
</template>

<script>
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

const HINT_TYPES = ['prePayHint', 'cannotUsePhoneOutsideCar', 'h2LegalHint'];
export default {
  components: {
    RPButton,
    RPIcon
  },
  emits: ['closeHint'],
  props: {
    type: {
      type: String,
      required: true,
      validator: value => HINT_TYPES.includes(value)
    }
  },
  setup(props) {
    const hintMAp = {
      prePayHint: {
        text: 'hints.poi_aral_info.text',
        icon: 'prePayHint'
      },
      cannotUsePhoneOutsideCar: {
        text: 'hints.poi_info.text',
        icon: 'infoHint'
      },
      h2LegalHint: {
        text: 'hints.poi_h2Legal.text',
        icon: 'h2LegalHint',
        button: 'hints.poi_h2Legal.btn_cta'
      }
    };
    return { hintMAp };
  }
};
</script>

