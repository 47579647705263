import { platformAuthenticatorIsAvailable as supportsWebauthn } from '@simplewebauthn/browser';
import { omit } from 'lodash';
import { userLanguage } from '@/i18n'

export const getCorrectLang = () => {
  const userLang = userLanguage();
  const convertUSinGB = userLang === 'en' ? 'en-GB' : userLang;
 return convertUSinGB || 'en-GB';
}

export const CURRENCY_DECICENT_MAP = {
  euroDeciCent: 'EUR',
  chfDeciCent: 'CHF',
  plnDeciCent: 'PLN',
  dkkDeciCent: 'DKK',
  gbpDeciCent: 'GBP',
  sekDeciCent: 'SEK',
  czkDeciCent: 'CZK',
  nokDeciCent: 'NOK',
  iskDeciCent: 'ISK',
  hrkDeciCent: 'HRK',
  bamDeciCent: 'BAM'
};
export const CURRENCY_DECICENT_SYMBOL_MAP = {
  euroDeciCent: '€',
  chfDeciCent: 'CHF',
  plnDeciCent: 'zł',
  dkkDeciCent: 'kr',
  gbpDeciCent: '£',
  sekDeciCent: 'kr',
  czkDeciCent: 'Kč',
  nokDeciCent: 'kr',
  iskDeciCent: 'kr',
  hrkDeciCent: 'kn',
  bamDeciCent: 'KM'
};
// Date Utils

/**
 * @name dateString
 * @desc We need to format the date accordingly to the design (WeekDay dd/mm/yyyy - HH-mm)
 * We return and object wit 3 key-values
 * @param input date object
 * @return {
 *  weekday: Mon, Tue, Wed... in locale lang
 *  date: dd/mm/yyyy
 *  time: hh:ss 24h format
 * }
 */
export function dateStringHistory(input) {
  const weekday = new Intl.DateTimeFormat(getCorrectLang(), {
    weekday: 'short',
  }).format(input);
  const date = new Intl.DateTimeFormat(getCorrectLang(), {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(input);
  const time = new Intl.DateTimeFormat(getCorrectLang(), {
    hour: 'numeric',
    hour12: false,
    minute: 'numeric',
  }).format(input);

  return {
    weekday,
    date,
    time
  };
}

export function dateString(input) {
  return new Intl.DateTimeFormat(localStorage.getItem('lang') || undefined, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }).format(input);
}

/**
 * @name convertUTCDateToLocalDate
 * @description This function is for  insert the right format into the HTML element <input type=datetiame-local>
 * This is what we got from API 2023-04-09T09:34:24.486Z
 * This is what the element needs 2023-04-09T11:34
 * We need to add the timezone to the hours of the string
 * Then remove the timezone from the string generated with toISOString()
 * @param {String} date The date in the string format
 */
export const convertUTCDateToLocalDate = (date) => {
  const newDate = (new Date(date))
  const timestampOffset = new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000);
  const offset = newDate.getTimezoneOffset() / 60;
  const hours = newDate.getHours();

  timestampOffset.setHours(hours - offset);
  return timestampOffset.toISOString().slice(0, 19);
};

// Currency Utils
export const currencyStringHistory = (input, currency = 'EUR') => {
  const parseCurrency = CURRENCY_DECICENT_MAP[currency]
    ? CURRENCY_DECICENT_MAP[currency]
    : currency
  return new Intl.NumberFormat(getCorrectLang() || undefined, {
    style: 'currency',
    currency: parseCurrency
  }).format(Number(input));
}
export const currencySymbol = (currency) => {
  return CURRENCY_DECICENT_SYMBOL_MAP[currency]
}
export const localeNumber = (numb) => {
  const convertNumber = Number(numb)
  return new Intl.NumberFormat(getCorrectLang()).format(convertNumber);
}
export const localeNumberWithFractionDigits = (numb) => {
  return new Intl.NumberFormat(getCorrectLang(), {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(numb);
}
export function currencyString(input, currency = 'EUR') {
  return new Intl.NumberFormat(localStorage.getItem('lang') || undefined, {
    style: 'currency',
    currency
  }).format(input);
}

export function getCredentials() {
  const storageToken = localStorage.getItem('auth');

  if (!storageToken) {
    return null;
  }

  const saveObj = JSON.parse(storageToken.replace(/\s/g, ''));
  if (new Date().getTime() > saveObj.expiry) {
    localStorage.removeItem('auth');
    return null;
  }
  return { token: saveObj.token, userId: saveObj.userId };
}

export const checkAuth = () => {
  const auth = getCredentials();
  if (auth?.token && auth?.userId) {
    return true;
  }

  return false;
};

export function setCredentials(token, userId) {
  const expiry = new Date();
  expiry.setMonth(expiry.getMonth() + 1);

  const saveObj = {
    expiry: expiry.getTime(),
    token,
    userId
  };
  localStorage.setItem('auth', JSON.stringify(saveObj));
}

export function clearCredentials() {
  localStorage.removeItem('auth');
}

export function arrayIsSame(array1, array2) {
  return array2.filter(e => !array1.includes(e)).length === 0;
}

export function isValidUrlString(str) {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
}

// Redirect and params check Utils
export const redirectToCallbackUrl = (callbackParam, paymentPayload) => {
  let urlCallbackObject;

  if (callbackParam === true || callbackParam === 'true') {
    urlCallbackObject = new URL('rydpaywebsdk://finish');
  } else if (isValidUrlString(callbackParam)) {
    urlCallbackObject = new URL(callbackParam);
  } else {
    throw new Error('Invalid callback parameter.');
  }

  paymentPayload && urlCallbackObject.searchParams.append('paymentData', JSON.stringify(paymentPayload));
  return window.location.replace(urlCallbackObject.toString());
};

const ORDER_PARAMS = [
  'orderId', 'orderType', 'amount', 'fuelTypeId', 'pumpId', 'type'
];
const PAYMENT_PARAM = [
  'registration',
  'resultIndicator',
  'sessionVersion',
  'nonce',
  'phoneNumber',
  'provider',
  'method',
  'sca_feedback',
  'errorDescription',
  'amazonCheckoutSessionId',
  'amazonIsCancel'
];
export const resetOrderURLparams = (params) => {
  return omit(params, ORDER_PARAMS);
};
export const resetPaymentsURLparams = (params) => {
  return omit(params, PAYMENT_PARAM);
};
export const resetAllURLparams = (params) => {
  return omit(params, [...PAYMENT_PARAM, ...ORDER_PARAMS]);
};
export const resetOrders = () => {
  for (const key in localStorage) {
    if (key.includes('rydPaySDK-')) {
      localStorage.removeItem(key);
    }
  }
};

export { supportsWebauthn };

