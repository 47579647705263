<template>
  <div class="relative flex">
    <div id="googleButton" class="mx-auto" />
  </div>
</template>

<script setup lang="ts">
import { loadScript } from '@/utils/externalScriptLoader.js';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n()

const currentLanguage = ref(localStorage.getItem('lang') || locale)

const emits = defineEmits(['token', 'registrationNotValid'])

const getGoogleClientId = computed(() => import.meta.env.VUE_APP_GOOGLE_CLIENTID)

watch(currentLanguage, (newVal, oldVal) => {
  currentLanguage.value = newVal
  renderGoogleButton();
})

onMounted(async () => {
  await loadScript('https://accounts.google.com/gsi/client');
  initGoogleSSO();
})

const handleCredentialResponse = async (response) => {
  const token = await response.credential;
  emits('token', token);
}

/**
  * Why render Google button by javascript
  * @see https://developers.google.com/identity/gsi/web/guides/display-button#button_rendering
  *
  * All Google button style attributes
  * @see https://developers.google.com/identity/gsi/web/reference/js-reference#attribute_types
*/

interface WindowWithGoogle extends Window {
    google?: any;
}
const google: WindowWithGoogle = window;

const initGoogleSSO = async () => {
  google.google.accounts.id.initialize({
    client_id: getGoogleClientId.value,
    callback: response => {
      handleCredentialResponse(response);
    }
  });
  renderGoogleButton();
}

const renderGoogleButton = () => {
  const getViewportWidth = window.innerWidth - 32;
  google.google.accounts.id.renderButton(document.getElementById('googleButton'), {
    text: 'continue_with',
    theme: 'outline',
    size: 'large',
    shape: 'pill',
    width: `${getViewportWidth}px`,
    logo_alignment: 'center',
    locale: currentLanguage.value
  });
}

</script>
