<template>
  <PinValidate v-if="twoFactorMethod === 'pin'" :on-finish="onFinish" :on-switch-to-password="switchToPassword" />
  <WebAuthnValidate v-else-if="twoFactorMethod === 'webauthn'" :on-finish="onFinish" />
  <PasswordValidate v-else :on-finish="onFinish" />
</template>

<script>
import PinValidate from './PinValidate.vue';
import WebAuthnValidate from './WebAuthnValidate.vue';
import PasswordValidate from './PasswordValidate.vue';
import RPButton from '../Atoms/RPButton/RPButton.vue';

export default {
  components: { PinValidate, WebAuthnValidate, PasswordValidate, RPButton },
  props: {
    onFinish: {
      type: Function,
      required: true
    }
  },
  emits: ['close'],
  data() {
    return {
      twoFactorMethod: null
    };
  },
  mounted() {
    this.twoFactorMethod = this.$store.state.auth.twoFactorMethod;
    this.$store.commit('ui/setPinPageIsShowing', true);
  },
  unmounted() {
    this.$store.commit('ui/setPinPageIsShowing', false);
  },
  methods: {
    switchToPassword() {
      this.twoFactorMethod = 'password';
    }
  }
};
</script>

<style lang="scss" scoped></style>
