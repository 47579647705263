<template>
  <div class="w-full l-scape:w-1/2">
    <div class="l-scape:hidden text-tertiary-text font-medium text-left">
      {{ $t('profile.main.title_pay') }}
    </div>
    <RPCard
      class="mb-8 mt-2 flex justify-center l-scape:mb-0"
      :class="{
        'border-card-disabled': ['user.poiRejectedPM', 'user.deviceRejectedPM'].some(props.state.matches)
      }"
      small
      hasClick
      @click="handleCardClick()"
    >
      <!-- user does not have PM -->
      <div v-if="props.state.matches('user.noPM')" class="flex items-center justify-between font-medium w-full">
        <div class="flex items-center max-w-[80%]">
          <RPIcon no-fill type="warning" class="w-14 l-scape:w-20 mr-3 flex items-center" />
          <span class="whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ $t('fueling.please_add_pm') }}
          </span>
        </div>
        <RPButton link class="l-scape:min-w-min ml-auto mr-0">
          {{ $t('fueling.summary.buttons.add') }}
        </RPButton>
      </div>
      <!-- choose another PM -->
      <div v-else class="flex items-center justify-between font-medium w-full">
        <div class="flex items-center flex-1 min-w-[80%]">
          <RPIcon no-fill :type="paymentSvg" class="w-14 l-scape:w-20 mr-3 flex items-center" />
          <p v-if="props.state.matches('user.deviceRejectedPM')" class="m-0 mt-[2px]">
            {{ $t('profile.main.payment-method.not_supported_pm') }}
          </p>
          <p v-else-if="props.state.matches('user.poiRejectedPM')" class="m-0 mt-[2px]">
            {{ $t('pm_not_supported.poi') }}
          </p>
          <span v-else class="whitespace-nowrap overflow-ellipsis overflow-hidden">
            {{ paymentInfo }}
          </span>
        </div>
        <RPButton link class="l-scape:min-w-min ml-auto mr-0">
          {{ $t('fueling.summary.buttons.change') }}
        </RPButton>
      </div>
    </RPCard>
    <SummaryPaymentPicker
      v-if="methodPickerOpen"
      @close="methodPickerOpen = false"
      @changed-p-m="handlePMChange"
      @addPm="addPayment()"
    />
  </div>
</template>

<script setup lang="ts">
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import SummaryPaymentPicker from './SummaryPaymentPicker.vue';

import { useGetPaymentInfo } from '@/composable/usePayments';

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';


const props = defineProps({
  state: {
    type: Object,
    required: true
  }
});
const store = useStore();
const route = useRoute();
const router = useRouter();

const checkoutMethod = computed(() => store.getters['psp/checkoutMethod']);
const { paymentSvg, paymentInfo } = useGetPaymentInfo(checkoutMethod);

// Handle change PM
const orderSelectionState = computed(() => store.state.station.ui);
const orderId = computed(() => store.state.pay.orderStatus.id);
const methodPickerOpen = ref(false);

const handlePMChange = paymentMethod => {
  store.dispatch('psp/setTemporaryPM', paymentMethod);
  methodPickerOpen.value = false;
};

const addPayment = () => {
  const { amount, fuelTypeId, pumpId } = orderSelectionState.value;
  const orderType = orderSelectionState.value.orderType;
  const paramsToTrackOrder = orderType === 'PREPAY' ? { amount, fuelTypeId, pumpId } : { orderId: orderId.value };
  router.push({
    path: '/payment-method',
    query: { ...route.query, orderType, ...paramsToTrackOrder }
  });
};

const handleCardClick = () => {
  if (checkoutMethod.value) {
    methodPickerOpen.value = true;
  } else {
    addPayment();
  }
};
</script>
 
