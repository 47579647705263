<template>
  <Teleport to="#topBarIconLeft">
    <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" href="/profile" data-cy="back-to-profile">
      <RPIcon svg-fill="text" type="arrowLeft" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span>{{ $t('profile.main.label_payment') }}</span>
  </Teleport>
  <h2 class="text-fs-20 text-left w-full px-4 mb-6">{{ $t('profile.main.title_payment') }}</h2>
  <div class="view-block text-center w-full px-4">
    <p v-if="!activePayments.length">{{ $t('profile.no_payment') }}</p>
    <div v-else class="flex flex-col space-y-4 pb-4">
      <template v-for="payment in sortedActivePayments" :key="payment.id">
        <PaymentMethodCard
          v-if="isPMInTheMap(payment.method)"
          data-cy="paymentCard"
          :payment-method-name="getPaymentText(payment.method)"
          :payment-method-details="getDetails(payment)"
          :preferred="payment.isPreferred"
          :not-supported="payment.pmIsNotSupportedOnDevice"
          :class="{ 'h-20 max-h-20': isLandscape }"
          @click="gotoEditPage(payment)">
          <template #logo>
            <RPIcon :type="getPaymentSvg(payment.method)" noFill class="h-10 min-w-[60px]" />
          </template>
        </PaymentMethodCard>
      </template>
    </div>
    <RPButton
      data-cy="buttonAddPayment"
      :disabled="isPaymentMethodChangeDisabled"
      @click="goToAddPaymentMethod()">
      {{ $t('profile.main.payment-method.btn_add') }}
    </RPButton>
  </div>
</template>

<script setup lang="ts">

import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import PaymentMethodCard from '@/components/Layout/PaymentMethodCard.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';

import { PAYMENT_METHODS_MAP, PAYMENTS_WITHOUT_DETAILS } from '@/composable/usePayments';
import { PaymentType } from '@/store/modules/psp/types';

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, ref, onBeforeMount } from 'vue';
import { cloneDeep, remove } from 'lodash';

const store = useStore();

const isPaymentMethodChangeDisabled = ref(false);
const activePayments = computed(() => store.state.psp.activePayments);
const isLandscape = computed(() => store.state.ui.landscape);

/**
 * @name sortedActivePayments
 * @description Sort the Preferred method on first position
 */
const sortedActivePayments = computed(() => {
  const preferredPM = activePayments.value.filter((payment: PaymentType) => payment.isPreferred);
  const objClone = cloneDeep(activePayments.value);

  remove(objClone, (payment: PaymentType) => payment.isPreferred);
  objClone.unshift(...preferredPM);

  return objClone;
});

onBeforeMount(async () => {
  await isPaymentMethodsLimitExceeded();
});
const isPaymentMethodsLimitExceeded = async () => {
  isPaymentMethodChangeDisabled.value = await store.dispatch('pay/isPaymentMethodsLimitExceeded');
};

const setSelectedEditPM = (paymentMethodData) => store.commit('psp/setSelectedEditPM', paymentMethodData);
const isPMInTheMap = (method) => {
  return PAYMENT_METHODS_MAP[method] ?? false;
};
const getPaymentSvg = (method) => {
  return PAYMENT_METHODS_MAP[method].svg;
};
const getPaymentText = (method) => {
  return PAYMENT_METHODS_MAP[method].text;
};
const getDetails = (payment) => {
  const normalizeMethod = payment.method.toUpperCase();

  if (PAYMENTS_WITHOUT_DETAILS.includes(normalizeMethod)) return;

  return normalizeMethod == 'MBWAY'
    ? `+${payment.details.phoneNumber.replace('#', '')}`
    : normalizeMethod == 'PAYPAL'
      ? payment.details.email
      : `${payment.details.brand} ${payment.details.cardNumber}`;
};

const router = useRouter();

const gotoEditPage = (paymentInfo) => {
  setSelectedEditPM({
    name: getPaymentText(paymentInfo.method),
    icon: getPaymentSvg(paymentInfo.method),
    details: getDetails(paymentInfo),
    metaData: paymentInfo
  });
  router.push('/profile/payments/edit');
};

const goToAddPaymentMethod = () => {
  router.push('/payment-method');
}

</script>