<template>
  <Teleport to="#topBarIconLeft" v-if="isLoggedIn">
    <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" @click="onboardingClose">
      <RPIcon svg-fill="text" type="close" />
    </RPButton>
  </Teleport>
  <div class="view-block w-full">
    <VueAgile :nav-buttons="true" :infinite="false" class="vue-slider">
      <div v-for="n in 6" :key="n" class="slide">
        <div class="flex flex-col m-auto w-52">
          <img :src="`./img/slider/${n}.png`" alt="" height="187" />
          <div class="-mx-14">
            <h2>{{ n }}. {{ $t(`landing.slider.${n}.headline`) }}</h2>
            <p>{{ $t(`landing.slider.${n}.text`) }}</p>
          </div>
        </div>
      </div>
      <template #prevButton>
        <RPIcon svg-fill="text" type="icon-carousel-rght" class="w-11 rotate-180" />
      </template>
      <template #nextButton>
        <RPIcon svg-fill="text" type="icon-carousel-rght" class="w-11" />
      </template>
    </VueAgile>
    <div v-if="!isLoggedIn" class="flex flex-col items-center mt-6">
      <RPButton href="/register">
        {{ $t('landing.register_cta') }}
      </RPButton>
      <RPButton link @click="onboardingClose" class="mt-6">{{ $t('landing.guest_cta') }}</RPButton>
    </div>
  </div>
</template>

<script>
import RydLogo from '@/components/Atoms/RydLogo.vue';
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';

import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

import { mapGetters, mapState, mapActions } from 'vuex';
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    RydLogo,
    RPButton,
    VueAgile: defineAsyncComponent({
      loader: async () => {
        const { VueAgile } = await import('vue-agile');
        return VueAgile;
      },
      loadingComponent: LoadingOverlay,
      delay: 50
    }),
    RPIcon
  },
  async mounted() {
    try {
      await this.$store.dispatch('auth/checkAuthStatus');
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    ...mapState({
      isGuest: state => state.auth.isGuest,
      previousRoute: state => state.ui.previousRoute
    }),
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn'
    })
  },
  methods: {
    ...mapActions({
      loginGuest: 'auth/loginGuest'
    }),
    onboardingClose() {
      this.$router.push({ path: '/pay' });
    }
  }
};
</script>

<style lang="scss">
.vue-slider {
  &.agile {
    position: inherit;
  }
  .agile__list {
    position: inherit;
  }
  .agile__slides {
    align-items: flex-start;
  }
  .agile__actions {
    padding-top: 10px;
    justify-content: center;

    .agile__nav-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      z-index: 3;
      position: absolute;
      bottom: 130px;
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
      }
      &:disabled {
        display: none;
      }
    }
  }
  .slide {
    text-align: center;
    img {
      margin-bottom: 20px;
    }
  }
  .agile__dot {
    margin: 0 5px;
    button {
      background-color: #888;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      height: 10px;
      font-size: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      transition-duration: 0.3s;
      width: 10px;
    }
    &--current,
    &:hover {
      button {
        background-color: #eee;
      }
    }
  }
}
</style>
