<template>
  <div class="view-block px-6">
    <h3 data-cy="enter-pin-title">{{ $t('paymentPin.validate.title') }}</h3>
    <Pin :error-msg="error" :pin-rest-hint="true" :on-switch-to-password="onSwitchToPassword" @pin="pin" />
  </div>
</template>

<script>
import Pin from './../Atoms/Pin.vue';
import { validatePin } from './../../api/pin';

export default {
  components: { Pin },
  props: {
    onFinish: {
      type: Function,
      required: true
    },
    onSwitchToPassword: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      error: null
    };
  },
  methods: {
    async pin(pin) {
      this.error = null;

      try {
        this.$store.commit('ui/setLoading', true);
        await validatePin({ pin });

        // Push finish callback on nextTick to done it after the finally-block
        this.$nextTick(() => this.onFinish());
      } catch (e) {
        if (e.response?.status === 403) {
          this.error = this.$t('paymentPin.validate.error');
          return;
        }
        this.$router.replace('/pay');
      } finally {
        this.$store.commit('ui/setLoading', false);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
