<template>
  <apple-pay-button
    buttonstyle="white"
    type="pay"
    :locale="locale"
    class="apple-pay-button"
    @click="$emit('click', $event)" />
</template>

<script>
import { userLanguage } from '../../i18n';
export default {
  emits: ['click'],
  computed: {
    locale() {
      // Generate local String for apple https://developer.apple.com/documentation/apple_pay_on_the_web/applepaybuttonlocale
      const userLang = userLanguage();

      const langMapper = {
        de: 'de-DE',
        en: 'en-US',
        es: 'es-ES',
        fr: 'fr-FR',
        it: 'it-IT',
        nl: 'nl-NL',
        pt: 'pt-PT',
        pl: 'pl-PL',
        da: 'da-DK'
      };

      return langMapper[userLang];
    }
  }
};
</script>

<style lang="scss" scoped>
.apple-pay-button {
  display: inline;
  height: 50px;
  width: 70%;
  --apple-pay-button-border-radius: 25px;
  --apple-pay-button-padding: 10px;
  --apple-pay-button-height: calc(16px + 16px + var(--fontSizeBody));
  --apple-pay-button-box-sizing: border-box;
}
</style>
