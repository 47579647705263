import { defineRule, configure } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import { userLanguage } from './i18n.js';
import { required, min, max, confirmed, email, regex } from '@vee-validate/rules';

defineRule('required', required);
defineRule('min', min);
defineRule('max', max);
defineRule('confirmed', confirmed);
defineRule('email', email);
defineRule('regex', regex);

import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import it from '@vee-validate/i18n/dist/locale/it.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import da from '@vee-validate/i18n/dist/locale/da.json';
import pl from '@vee-validate/i18n/dist/locale/pl.json';
import pt from '@vee-validate/i18n/dist/locale/pt_PT.json';

configure({
  generateMessage: localize({
    en,
    de,
    fr,
    nl,
    it,
    es,
    pt,
    da,
    pl
  })
});

setLocale(userLanguage());
