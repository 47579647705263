<template>
  <div class="w-full px-6">
    <h3 class="mb-2 text-left">{{ $t('profile.main.payment-method.header') }}</h3>
    <small
      class="block mb-[30px]"
      v-html="$t('profile.main.payment-method.agb_text',
      { main_agb: 'https://rydapp.page.link/terms' })" />
    <div class="flax space-y-2 font-medium">
      <template v-for="(pm, index) in PMToShow" :key="index">
        <RPCard
          v-if="pm === 'mpgs/DIRECT_CREDIT'"
          small
          hasClick
          data-cy="addCreditCard"
          class="justify-center"
          @click="registerPSP('mpgs/DIRECT_CREDIT')">
          {{ $t('profile.main.payment-method.method_cc') }}
          <RPIcon svg-fill="text" type="creditcard" class="h-xxl" />
        </RPCard>
        <RPCard
          v-else-if="pm === 'mpgs/GOOGLE_PAY'"
          data-cy="addGooglePay"
          small
          hasClick
          class="justify-center"
          @click="registerPSP('mpgs/GOOGLE_PAY')">
          Google Pay
          <RPIcon svg-fill="text" type="gpay" class="h-xxl" />
        </RPCard>
        <RPCard
          v-else-if="pm === 'mpgs/APPLE_PAY'"
          data-cy="addApplePay"
          small
          hasClick
          class="justify-center"
          @click="registerPSP('mpgs/APPLE_PAY')">
          Apple Pay
          <RPIcon svg-fill="text" type="apple-pay" class="h-xxl" />
        </RPCard>
        <RPCard
          v-else-if="pm === 'amazon/AMAZON_PAY'"
          data-cy="addAmazonPay"
          small
          hasClick
          class="justify-center"
          @click="registerPSP('amazon/AMAZON_PAY')">
          Amazon Pay
          <RPIcon svg-fill="text" type="amazon" class="h-xxl" />
        </RPCard>
        <RPCard
          v-else-if="pm === 'sibs/MBWAY'"
          data-cy="addMbway"
          small
          hasClick
          class="justify-center"
          @click="addMBWay()">
          MB Way
          <RPIcon svg-fill="text" type="mbway" class="h-xxl" />
        </RPCard>
        <RPCard
          v-else-if="pm === 'braintree/PAYPAL'"
          data-cy="addPayPal"
          small
          hasClick>
          <div
            id="paypalBtn"
            class="payment-method-add__button-paypal
            absolute top-0 left-0
            w-full h-full
            z-10" />
          PayPal
          <RPIcon svg-fill="text" type="paypal" class="h-xxl" />
        </RPCard>
      </template>
    </div>
    <span v-if="error" class="pt-5 text-error">{{ $t('errors.generic.text') }}</span>
  </div>
</template>
<script>
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

import { createOptin } from '@/api/optin';

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

const supportedPaymentMethodsMap = [
  'mpgs/DIRECT_CREDIT',
  'braintree/PAYPAL',
  'mpgs/GOOGLE_PAY',
  'amazon/AMAZON_PAY',
  'sibs/MBWAY',
  'mpgs/APPLE_PAY'
];

let paypalButton;

export default {
  components: { RPIcon, RPCard },
  data() {
    return {
      error: false,
      paymentMethodsToShowSuccessDialogFor: ['APPLE_PAY', 'GOOGLE_PAY', 'AMAZON_PAY']
    };
  },
  async mounted() {
    // We save params for Ui (partner, landscape..)
    // and params for tracking the order (pumpId, orderType..)
    // if there are no params in URL, we NOT overwrite with null values the store
    // but we keep the current state of store
    this.saveURLParamsForUi();
    this.saveURLParamsForTrackOrder(this.$route.query);

    if (this.$route.query.pid) {
      this.$store.commit('ui/setLoading', true);
      await this.getStationData();
      this.$store.commit('ui/setLoading', false);
    }
    if (!this.PMToShow.includes('braintree/PAYPAL')) {
      return;
    }

    this.loadPayPal();
  },
  computed: {
    ...mapState({
      activePayments: state => state.psp.activePayments,
      paymentProviders: state => state.psp.paymentProviders,
      savedParamsTrackOrder: state => state.station.ui,
      pid: state => state.ui.query.pid
    }),
    ...mapGetters({
      stationSupportedPM: 'station/supportedPaymentMethods',
      chosenPump: 'station/chosenPump'
    }),
    orderType() {
      return this.savedParamsTrackOrder.orderType;
    },
    PMToShow() {
      const allowedPMByFeaturePSP = this.getProviderMethod(this.paymentProviders);
      if (this.chosenPump) {
        return allowedPMByFeaturePSP.filter(payment => {
          return this.stationSupportedPM.includes(payment.split('/')[1]);
        });
      }

      return allowedPMByFeaturePSP;
    },
    paramsToAppendTrackOrder() {
      if (this.$route.query.orderType === 'PREPAY') {
        const { amount, fuelTypeId, pumpId } = this.savedParamsTrackOrder;
        return { amount, fuelTypeId, pumpId };
      }
      return {};
    }
  },
  beforeUnmount() {
    paypalButton?.close();
  },
  methods: {
    ...mapActions({
      saveURLParamsForUi: 'ui/saveURLParamsForUi',
      getStationData: 'station/getOnce'
    }),
    ...mapMutations({ saveURLParamsForTrackOrder: 'station/saveURLParamsForTrackOrder' }),
    getProviderMethod(payments) {
      return payments
        .map(provider => `${provider.provider.toLowerCase()}/${provider.method.toUpperCase()}`)
        .filter(method => supportedPaymentMethodsMap.includes(method));
    },
    addMBWay() {
      this.routeToPush('/payment-method/mbway', { registration: 'sibs' });
    },
    routeToPush(path, additionalParams) {
      this.$router.push({
        path: path,
        query: {
          ...this.paramsToAppendTrackOrder,
          ...this.$route.query,
          ...additionalParams
        }
      });
    },
    async registerPSP(type) {
      try {
        const [provider, method] = type.split('/');
        const { accountId, id } = this.$store.state.auth;

        const optinData = {
          name: `${method}_${provider}`,
          action: 'set',
          accountId,
          userId: id
        };

        await createOptin(optinData);

        // Even if we do not need the response of this API for GPay, APay, Amazon Pay, we need to query it.
        // This operation will create a Payment Method in a CREATE state.
        const { subscription } = await this.$store.dispatch('psp/fetchProviderInformations', { provider, method });

        if (['APPLE_PAY', 'GOOGLE_PAY'].includes(method)) {
          this.routeToPush('/payment-register', { method, registration: 'mpgs' });
          return;
        }
        if (['AMAZON_PAY'].includes(method)) {
          this.routeToPush('/payment-register', { method, registration: 'amazon' });
          return;
        }

        if (subscription.startsWith('http')) {
          window.location.assign(subscription);
        }
      } catch (e) {
        console.error(e);
        this.error = true;
      }
    },
    async loadPayPal() {
      this.$store.commit('ui/setLoading', true);
      const { createPayPalButton } = await import('./../../utils/paypal');

      paypalButton = await createPayPalButton({
        onApproved: async nonce => {
          this.routeToPush('/payment-register', { nonce, registration: 'braintree' });
        }
      });
      paypalButton.render('#paypalBtn');
      this.$store.commit('ui/setLoading', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-method-add {
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 55px;
  }

  &__button-paypal {
    opacity: 0.001; // trick to be able to click on PayPal button.
  }
}
</style>
