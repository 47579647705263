import { createThirdPartyForm, deletePayment, handleThirdPartyGateway } from '@/api/payments';
import {
  getFeatures,
  getActivePayments,
  getSupportedActivePayments,
  setPreferredPayment,
  deactivatePayment
} from '@/api/accountPSP';
import { checkGPay } from '@/utils/gpay';
import { isApplePaySupported } from '@/utils/applePay';
import router from '@/router.js';

import { PSPTypes, PaymentType } from './types';
import { isEmpty, omit } from 'lodash';

const getDefaultState = (): PSPTypes => {
  return {
    paymentProviders: [],
    activePayments: [],
    activePaymentsSupportedByPoi: [],
    replacePreferred: null,
    supportHotline: null,
    registerQuery: {
      provider: null,
      query: null
    },
    editPaymentMethod: {
      name: null,
      icon: null,
      details: null,
      metaData: null
    },
    selectedCheckoutMethod: null,
    error: null
  };
};
const state: PSPTypes = getDefaultState();

const mutations = {
  setActivePayments(state, providers) {
    state.activePayments = providers;
  },
  setActivePaymentsSupportedByPoi(state, providers) {
    state.activePaymentsSupportedByPoi = providers;
  },
  setPaymentProviders(state, providers) {
    state.paymentProviders = providers;
  },
  setSupportHotline(state, hotline) {
    state.supportHotline = hotline;
  },
  saveRegisterQuery(state, cleanedQuery) {
    const { registration, ...query } = cleanedQuery;
    state.registerQuery.provider = registration;
    state.registerQuery.query = query;
  },
  setClearRegisterQuery(state) {
    state.registerQuery.provider = null;
    state.registerQuery.query = null;
  },
  setSelectedEditPM(state: PSPTypes, paymentMethodData) {
    // when editing a PM
    state.editPaymentMethod = paymentMethodData;
  },
  setSelectedCheckoutPM(state: PSPTypes, paymentMethod: PaymentType | null) {
    state.selectedCheckoutMethod = paymentMethod;
  },
  setError(state: PSPTypes, error) {
    state.error = error;
  },
  clearPSPState(state) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  /**
   * @name loadFeatures
   * @param {String} accountId User account ID
   * @desc retrieve and store the features of an account included Payment Methods that a user can adds (sponsor configuration)
   */
  async loadFeatures({ commit }, accountId) {

    const { guestUser, supportHotline, quickCheckout, psp, loyaltyPrograms, odometerScreenFeat } = await getFeatures(accountId);

    const isGPaySupported = await checkGPay();
    let supportedPSP = psp;

    if (!isGPaySupported) {
      supportedPSP = supportedPSP.filter(i => i.method !== 'GOOGLE_PAY');
    }

    if (!isApplePaySupported()) {
      supportedPSP = supportedPSP.filter(i => i.method !== 'APPLE_PAY');
    }

    commit('setPaymentProviders', supportedPSP);
    commit('auth/setQuickCheckout', quickCheckout, { root: true });
    loyaltyPrograms && commit('loyalty/setLoyaltyPrograms', loyaltyPrograms, { root: true });
    commit('auth/setGuestUser', guestUser, { root: true });
    commit('auth/setOdometerScreen', odometerScreenFeat, { root: true });
    commit('setSupportHotline', supportHotline);
  },

  /**
   * @name loadActivePM
   * @param {String} accountId User account ID
   * @desc retrieve and store the Payment Methods that a user already added
   */
  async loadActivePM({ commit, dispatch, rootState }, accountId) {

    try {

      // We load the PMs supported by POI only if there is a poi in the URL
      const url = new URLSearchParams(document.location.search);
      const poi = url.get("pid")

      if (poi) {
        await dispatch('loadSupportedActivePM', { accountId, poiID: poi });
      }
      let payments: PaymentType[] = await getActivePayments(accountId);

      const isGpaySupported = await checkGPay();

      payments = payments.map(payment => {
        payment.pmIsNotSupportedOnDevice = false;

        if (payment.method === 'GOOGLE_PAY' && !isGpaySupported) {
          payment.pmIsNotSupportedOnDevice = true;
        }

        if (payment.method === 'APPLE_PAY' && !isApplePaySupported()) {
          payment.pmIsNotSupportedOnDevice = true;
        }

        return payment;
      });

      commit('setActivePayments', payments);
    } catch (e) {

      // http error status have been catching in the API query (accountPSP.js)
      // this catch is to prevent unhandled error status or CORS error
      commit('setActivePayments', []);
      console.error("Retrieving active payment methods failed'", e);
    }
  },

  /**
   * @name loadSupportedActivePM
   * @param {String} accountId User account ID
   * @desc retrieve and store the Payment Methods that a user already added and are supported by the selected POI
   */
  async loadSupportedActivePM({ commit }, { accountId, poiID }) {
    commit('setError', null);
    try {
      let payments: PaymentType[] = await getSupportedActivePayments(accountId, poiID);

      const isGpaySupported = await checkGPay();

      payments = payments.map(payment => {
        payment.pmIsNotSupportedOnDevice = false;

        if (payment.method === 'GOOGLE_PAY' && !isGpaySupported) {
          payment.pmIsNotSupportedOnDevice = true;
        }

        if (payment.method === 'APPLE_PAY' && !isApplePaySupported()) {
          payment.pmIsNotSupportedOnDevice = true;
        }

        return payment;
      });

      commit('setActivePaymentsSupportedByPoi', payments);
    } catch (e) {
      commit('setError', `loadSupportedActivePM request has error: ${e}`);
      commit('setActivePaymentsSupportedByPoi', []);
      console.error(e);
    }
  },

  /**
   * @name fetchProviderInformations
   * @description fetch the query GET /urls that establish a subscription with the provider
   * @param {String} provider needed to build the endpoint [mpgs, concardis, sibis..]
   * @param {String} method has to be included in the URL params [DIRECT_CARD, GOOGLE, MBWAY..]
   * @param {String} query Additional query params to add at URL. Now is passed only by MBWay to append phone number
   */
  async fetchProviderInformations({ commit, rootGetters }, { provider, method, query }) {
    commit('ui/setLoading', true, { root: true });

    const paramsFromUI = rootGetters['ui/queryString'];
    const paramsForTrackOrder = rootGetters['station/buildURLParamsFromState'];

    try {
      const successRedirect = `${import.meta.env.VUE_APP_PSP_REDIRECT_URL}?sca_feedback=${encodeURIComponent(
        '/payment-register'
      )}&registration=${provider.toLowerCase()}${paramsFromUI}&${paramsForTrackOrder}`;
      const errorRedirect = `${import.meta.env.VUE_APP_PSP_REDIRECT_URL}?sca_feedback=${encodeURIComponent(
        '/error'
      )}&type=payment_register${paramsFromUI}&${paramsForTrackOrder}`;

      return await createThirdPartyForm(provider, method, successRedirect, errorRedirect, query);
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  /**
   * @name registerPayment
   * @description fetch the query HEAD /gateway that register the payment method
   * @param {String} provider [mpgs, concardis, sibis..]
   * @param {String} method has to be included in the URL params [DIRECT_CARD, GOOGLE, MBWAY..]
   * @param {String} query Additional query params to add at URL.
   *                       Now is passed only by MBWay to append phone number
   */
  async registerPayment({ commit, dispatch, rootState }, { query, provider }) {
    commit('ui/setLoading', true, { root: true });
    try {
      await handleThirdPartyGateway(provider, query);

      // Force reload AuthData
      commit('auth/setTwoFactorMethod', null, { root: true });
      await dispatch('auth/checkAuthStatus', true, { root: true });

      const accountId = rootState.auth.accountId;
      await dispatch('loadActivePM', accountId);
    } catch (e) {
      console.log('registerPayment error', e);
      throw new Error(e);

    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },

  async deletePayment({ commit }) {
    commit('ui/setLoading', true, { root: true });
    try {
      await deletePayment();
    } catch (e) {
      console.error(e);
    }
    commit('ui/setLoading', false, { root: true });
  },

  async setPreferredPaymentMethod({ commit, dispatch, rootGetters }, paymentId) {
    commit('ui/setLoading', true, { root: true });
    try {
      await setPreferredPayment(paymentId);
      const accountId = rootGetters['auth/getAccountId'];
      await dispatch('loadActivePM', accountId);
    } catch (e) {
      throw e;
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },

  async deactivatePaymentMethod({ commit, dispatch, rootGetters }, paymentId) {
    commit('ui/setLoading', true, { root: true });
    try {
      await deactivatePayment(paymentId);
      const accountId = rootGetters['auth/getAccountId'];
      await dispatch('loadActivePM', accountId);
    } catch (e) {
      throw e;
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  /**
   * @name urlParamsForRegisterPM
   * @decription If user is adding a new PM on checkout phase
   * we have a lot of params in the URL to track the order.
   * We don't want to send those params to the provider registration so we have to omit them
   */
  async urlParamsForRegisterPM({ commit, rootState }) {
    const query = { ...router.currentRoute.value.query };

    const paramsFromPrePay = Object.keys(rootState.station.ui);
    const paramsFromPostPay = Object.keys(rootState.ui.query);

    const paramsToRemove = [...paramsFromPrePay, ...paramsFromPostPay];
    const cleanedQuery = omit(query, paramsToRemove);
    commit('saveRegisterQuery', cleanedQuery);
  },
  setTemporaryPM({ commit }, paymentMethod: PaymentType | null) {
    commit('setSelectedCheckoutPM', paymentMethod);
    commit('pay/setPaymentMethodId', paymentMethod.id, { root: true });
  },
  resetTemporaryPM({ commit }) {
    commit('setSelectedCheckoutPM', null);
    commit('pay/setPaymentMethodId', null, { root: true });
  }
};

const getters = {
  checkoutMethod(state, getters): PaymentType {
    const selectedPayment = state.selectedCheckoutMethod || getters.preferredPM;
    if (isEmpty(selectedPayment)) return null;
    const isSupportedPOI = state.activePaymentsSupportedByPoi.some(
      (payment: PaymentType): boolean => payment.id === selectedPayment.id);

    // TODO: need a refactor 'cause pmIsNotSupportedOnDevice is really confusing in the logic
    const isSupportedDevice = !selectedPayment.pmIsNotSupportedOnDevice;
    return {
      ...selectedPayment,
      isSupportedPOI,
      isSupportedDevice
    };
  },
  paymentIsPayPal(state, getters) {
    return getters.checkoutMethod?.method === 'PAYPAL' || false;
  },
  paymentIsGpay(state, getters) {
    return getters.checkoutMethod?.method === 'GOOGLE_PAY' || false;
  },
  paymentIsMBWAY(_, getters) {
    return getters.checkoutMethod?.method === 'MBWAY' || false;
  },
  paymentShouldSkipValidation(_, getters) {
    return ['GOOGLE_PAY', 'MBWAY', 'DIRECT_CREDIT', 'APPLE_PAY'].includes(getters.checkoutMethod?.method);
  },
  paymentIsApplePay(_, getters) {
    return getters.checkoutMethod?.method === 'APPLE_PAY' || false;
  },
  preferredPM(state) {
    return state.activePayments.find(paymentMethod => paymentMethod.isPreferred);
  },
  paymentIsCreditCard(state, getters) {
    return getters.checkoutMethod?.method === 'DIRECT_CREDIT' || false;
  },
  latestCreatedPaymentMethod(state) {
    const sortedArray = state.activePaymentsSupportedByPoi.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    return sortedArray[0];
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
