import axios from './index';

// Docs: https://tt4.thinxcloud-dev.de/api-docs/#/POI/get_v4_merchants_best_match
export async function getMerchant(userCountry, poiCountry, paymentMethod) {
  const { data } = await axios.get(
    `/v4/merchants/best-match?poiCountry=${poiCountry}&userCountry=${userCountry}&providerMethod=${paymentMethod}`
  );

  return {
    countryCode: data.acquirer.countryCode,
    merchantId: data.merchantId
  };
}
