<template>
  <template v-if="isGuest">
    <Teleport to="#topBarIconLeft">
      <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" @click="guestClose">
        <RPIcon svg-fill="text" type="close" class="w-10" />
      </RPButton>
    </Teleport>
    <Teleport to="#topBarTitle">
      <span>{{ $t('menu.profile') }}</span>
    </Teleport>
    <Teleport to="#topBarIconRight">
      <RPButton
        theme="custom"
        class="bg-transparent w-10 py-0 px-0 min-w-min"
        data-cy="goToSettings"
        @click="$router.push('/settings')"
      >
        <RPIcon type="gear" svgFill="text" class="relative l-scape:w-20 l-scape:h-20" />
      </RPButton>
    </Teleport>
  </template>
  <template v-else>
    <Teleport to="#topBarIconLeft">
      <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" @click="userClose">
        <RPIcon svg-fill="text" type="arrowLeft" />
      </RPButton>
    </Teleport>
    <Teleport to="#topBarIconRight">
      <RPButton theme="custom" class="p-0 min-w-min rounded-none" data-cy="openLangSwitcher" @click="clickLanguage()">
        <RPIcon :type="`flags/${locale}`" no-fill class="l-scape:w-20 l-scape:h-20" />
      </RPButton>
    </Teleport>
    <div class="flex items-start w-full ml-4 mb-10">
      <RydLogo :show-logo-text="false" />
      <h1 class="pl-2 mt-0 mb-0">
        {{ $t('landing.headline') }}
      </h1>
    </div>
  </template>
</template>

<script lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RydLogo from '@/components/Atoms/RydLogo.vue';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

export default {
  components: { RPButton, RPIcon, RydLogo },
  props: {
    isGuest: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const { locale } = useI18n();

    const route = useRoute();
    const router = useRouter();

    const clickLanguage = () => {
      store.commit('ui/setSidebar', { type: 'language', visible: true });
    };

    const guestClose = () => {
      route.query.orderId
        ? router.push('/pay/postpay/summary')
        : route.query.orderType === 'PREPAY'
          ? router.push('/pay/prepay/summary')
          : router.push('/pay');
    };
    // we choose to not react to the path change (no ref()) in the store while visiting login/register page
    // so we can keep trace of what was the previous route when user enter here
    const previousPagePath = store.state.ui.previousRoute;
    const userClose = () => {
      previousPagePath === '/landing' ? router.push(previousPagePath) : router.push('/pay');
    };
    return { clickLanguage, locale, guestClose, userClose };
  }
};
</script>