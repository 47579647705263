<template>
  <Dialog v-if="dialogOpen" @close="dialogOpen = false">
    <h2>{{ $t('signIn.register.dialog.title') }}</h2>
    <p>{{ $t('signIn.register.dialog.content') }}</p>
    <RPButton class="mt-6" data-cy="loginNoAccountFoundClose" @click="cleanParamsAndRedirect('register')">
      {{ $t('account.register_cta') }}
    </RPButton>
  </Dialog>
  <LoadingOverlay v-else />
</template>

<script setup lang="ts">
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import Dialog from '@/components/Atoms/Dialog.vue';

import { useLoginRegister } from './composable/useLoginRegister';
import { onMounted, inject, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();
const matomo: any = inject('Matomo');
const { redirectToCheckout, cleanParamsAndRedirect } = useLoginRegister();


onMounted(async () => {
  if (route.query.amazonIsCancel) {
    localStorage.removeItem('register_optin');
    localStorage.removeItem('register_country');
    cleanParamsAndRedirect(route.query.actionType as string);
    return;
  }

  route.query.actionType === 'login'
    ? await loginSSO(route.query.buyerToken, 'AMAZON')
    : await ssoRegister(route.query.buyerToken, 'AMAZON');
});

const dialogOpen = ref(false);
const loginSSO = async (token, provider) => {
  try {
    await store.dispatch('auth/loginOpenId', { token, provider });
    if (route.query.orderType) {
      return await redirectToCheckout();
    }
    cleanParamsAndRedirect('pay');
  } catch (e) {
    console.log('login failed', e);
    if (e.response?.data?.message.includes('Incorrect email')) {
      dialogOpen.value = true;
    }
  }
};

const ssoRegister = async (token, provider, userData = {}) => {
  const registerOptin = await JSON.parse(localStorage.getItem('register_optin')) ?? [];
  const registerCountry = await localStorage.getItem('register_country');

  store.commit('auth/setMarketingPermissions', registerOptin);

  try {
    await store.dispatch('auth/register', {
      countryCode: registerCountry,
      registrationMethod: 'openid',
      idToken: token,
      idProvider: provider,
      ...userData
    });

    await store.dispatch('auth/loginOpenId', { token, provider });
    trackRegistration(provider);

    cleanParamsAndRedirect('address');

  } catch (e) {
    if (e.message === 'email_has_account') {
      await store.dispatch('auth/loginOpenId', { token, provider });
      if (route.query.orderType) {
        return await redirectToCheckout();
      }
      return cleanParamsAndRedirect('pay');
    }
    // TODO: show error when register and login fails
    console.log('register or login failed', e);
  } finally {
    localStorage.removeItem('register_optin');
    localStorage.removeItem('register_country');
  }
};

const trackRegistration = (provider) => {
  try {
    matomo.rememberConsentGiven();
    matomo.trackEvent('Registration', 'NewUser', localStorage.getItem('partner'));
    matomo.trackEvent('SSO', 'Registration', provider);
  } catch (e) {
    console.debug(e);
  }
};
</script>