import { useStore } from 'vuex';
import { computed, ComputedRef } from 'vue';

import { PSPTypes, PaymentType, MBWay, Paypal, Card } from '@/store/modules/psp/types';

export const PAYMENT_METHODS_MAP = {
  DIRECT_CREDIT: { svg: 'cards', text: 'Credit Card' },
  GOOGLE_PAY: { svg: 'gpay', text: 'Google Pay' },
  APPLE_PAY: { svg: 'apple-pay', text: 'Apple Pay' },
  AMAZON_PAY: { svg: 'amazon', text: 'Amazon Pay' },
  MBWAY: { svg: 'mbway', text: 'MB Way' },
  PAYPAL: { svg: 'paypal', text: 'Paypal' },
  POSTPAY_BY_USER: { svg: '', text: 'Post pay by User' },
  POSTPAY_BY_PARTNER: { svg: '', text: 'Post pay by Partner' }
};
export const PAYMENTS_WALLET = ['GOOGLE_PAY', 'AMAZON_PAY', 'APPLE_PAY'];
export const PAYMENTS_SKIP_VALIDATION = ['GOOGLE_PAY', 'MBWAY', 'DIRECT_CREDIT', 'APPLE_PAY', 'AMAZON_PAY'];
export const PAYMENTS_WITHOUT_DETAILS = ['GOOGLE_PAY', 'AMAZON_PAY', 'APPLE_PAY', 'POSTPAY_BY_USER', 'POSTPAY_BY_PARTNER'];

export function usePayments() {
  const store = useStore();

  const checkoutMethod = computed<PaymentType>(() => store.getters['psp/checkoutMethod']);

  const preferredPM = computed<PaymentType>(() => store.getters['psp/preferredPM']);

  const paymentIsPayPal = computed(() => checkoutMethod.value?.method === 'PAYPAL' || false);
  const paymentIsMBWAY = computed(() => checkoutMethod.value?.method === 'MBWAY' || false);
  const paymentIsGpay = computed(() => checkoutMethod.value?.method === 'GOOGLE_PAY' || false);
  const paymentIsApplePay = computed(() => checkoutMethod.value?.method === 'APPLE_PAY' || false);
  const paymentIsAmazonPay = computed(() => checkoutMethod.value?.method === 'AMAZON_PAY' || false);

  const getAmazonPayment = computed<PaymentType>(() =>
    store.state.psp.activePayments?.find(payment => payment.method === 'AMAZON_PAY'));


  const isWalletPayment = computed(() => PAYMENTS_WALLET.includes(checkoutMethod.value?.method));

  const paymentShouldSkipValidation = computed(() => PAYMENTS_SKIP_VALIDATION.includes(checkoutMethod.value?.method));



  return {
    PAYMENT_METHODS_MAP,
    PAYMENTS_WALLET,
    isWalletPayment,
    paymentShouldSkipValidation,
    paymentIsGpay,
    paymentIsApplePay,
    paymentIsAmazonPay,
    getAmazonPayment,
    getAmazonPaymentId: getAmazonPayment.value?.id,
    checkoutMethod,
    preferredPM
  };

}

export function useGetPaymentInfo(payment: ComputedRef<PaymentType>) {

  const paymentSvg = computed<string>(() => payment.value.method ? PAYMENT_METHODS_MAP[payment.value.method].svg : null);

  const paymentInfo = computed(() => {
    const paymentDetailMap = {
      PAYPAL: () => `Paypal ${(payment.value.details as Paypal).email}`,
      MBWAY: () => {
        const { phoneNumber } = payment.value.details as MBWay;
        return `MB Way +${phoneNumber.replace('#', '')}`;
      },
      GOOGLE_PAY: () => 'Google Pay',
      APPLE_PAY: () => 'Apple Pay',
      AMAZON_PAY: () => 'Amazon Pay',
      DIRECT_CREDIT: () =>
        `${(payment.value.details as Card).brand} ${(payment.value.details as Card).cardNumber}`,
      POSTPAY_BY_USER: () => '',
      POSTPAY_BY_PARTNER: () => ''
    };

    const paymentDetailMapHandler = paymentDetailMap[payment.value.method] || null;

    return payment.value.method ? paymentDetailMapHandler() : null;
  });

  return {
    paymentSvg,
    paymentInfo
  };
}