<template>
  <Teleport to="#topBarIconLeft">
    <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" href="/profile" data-cy="back-to-profile">
      <RPIcon svg-fill="text" type="arrowLeft" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span>{{ $t('profile.main.label_data') }}</span>
  </Teleport>
  <div class="w-full px-4" :class="{ landscape: isLandscape }" data-cy="profileContainer">
    <h2 class="text-fs-20 text-left mb-6">{{ $t('profile.main.title_manage_data') }}</h2>
    <ul class="p-0 m-0">
      <li class="list-none">
        <strong data-cy="profileEmail">{{ $t('profile.main.label_email') }}: {{ email }} </strong>
      </li>
      <Divider class="my-md" />
      <li v-for="item in listLinksToDisplay" :key="item.link" class="list-none">
        <router-link :to="item.link" class="flex justify-between text-primary-text">
          <strong>{{ item.label }}</strong>
          <div>
            <RPIcon svg-fill="text" type="arrowRight" />
          </div>
        </router-link>
        <Divider class="my-md" />
      </li>
      <li class="list-none">
        <RPButton data-cy="logout" link class="flex justify-between w-full max-w-full" @click="logout">
          <strong class="text-primary-text">{{ $t('profile.main.label_logout') }}</strong>
          <div>
            <RPIcon svg-fill="text" type="logout" />
          </div>
        </RPButton>
      </li>
    </ul>
  </div>
</template>

<script>
import Divider from '@/components/Atoms/Divider.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

import { mapState } from 'vuex';

export default {
  components: { Divider, RPButton, RPIcon },
  computed: {
    ...mapState({ isLandscape: state => Boolean(state.ui.query.landscape) }),
    email() {
      return this.$store.state.auth.email;
    },
    listLinks() {
      return [
        { label: this.$t('profile.main.label_passwort'), link: '/change-password', isVisible: true },
        { label: this.$t('profile.main.label_address'), link: '/address', isVisible: true }
      ];
    },
    listLinksToDisplay() {
      return this.listLinks.filter(item => item.isVisible);
    }
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch('auth/logout');
        this.$router.replace('/login');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
