import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { resetOrderURLparams } from '@/utils/utils.js';
import { isEmpty } from 'lodash';

export function useLoginRegister() {

  const store = useStore();
  const route = useRoute();

  const orderId = computed(() => store.state.pay.orderStatus.id);

  const redirectToCheckout = async () => {
    await store.dispatch('station/getOnce');

    // User was a guest and login from checkout prepay
    if (route.query.orderType == 'PREPAY') return cleanParamsAndRedirect('pay/prepay/summary');

    // User was a guest and login from checkout postpay
    if (route.query.orderId) {
      
      const paramsWithoutOrder = resetOrderURLparams(route.query);
      store.commit('station/saveURLParamsForTrackOrder', { pumpId: route.query.pumpId });

      try {

        await store.dispatch('pay/createOrder');
        await store.dispatch('pay/fuel');

        cleanParamsAndRedirect(
          'pay/postpay/summary',
          {
            ...paramsWithoutOrder,
            orderId: orderId.value,
            orderType: 'POSTPAY',
            pumpId: route.query.pumpId
          }
        );

      } catch (e) {
        console.log('login error order creation', e);
        cleanParamsAndRedirect('error', { ...paramsWithoutOrder, type: 'fueling_postpay' });
      }

      return;
    }
  };

  const cleanParamsAndRedirect = (url: string, additionalParams = {}) => {

    // Since we are using location.assign we lost the previous path on vue router
    // We need this in the address component
    // We cannot use Vuex because the address component need to know where the user came from (profile/register)
    // and we lost Vuex on page refresh
    localStorage.setItem('previousPath', route.path)

    const urlParams = isEmpty(additionalParams)
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams(additionalParams)
    
    urlParams.delete('buyerToken');
    urlParams.delete('actionType');
    urlParams.delete('amazonIsCancel');

    return urlParams.toString().length
      ? window.location.assign(`/${url}?${urlParams}`)
      : window.location.assign(`/${url}`);
  };

  return { redirectToCheckout, cleanParamsAndRedirect };
}
