<template>
  <Teleport to="#topBarIconLeft">
    <RPButton
      data-cy="goToProfile"
      theme="custom"
      class="bg-transparent w-10 py-0 px-0 min-w-min"
      @click="$router.push('/profile')"
    >
      <RPIcon type="arrowLeft" svgFill="text" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span data-cy="historyTitle">
      {{ $t('history.title-webview') }}
    </span>
  </Teleport>
  <Teleport to="#topBarIconRight">
    <RPButton theme="custom" class="p-0 min-w-min" @click="isModalExportOpen = true">
      <RPIcon type="export" svgFill="text" />
    </RPButton>
  </Teleport>
  <HistoryFilters @carFilterChange="setEventFilter" @eventFilterChange="setEventFilter" />

  <ModalBottom v-if="isModalExportOpen" @close="isModalExportOpen = false">
    <p class="text-left rp-fs-16-ln font-semibold">
      {{ $t('history.export.title') }}
    </p>
    <p class="text-left my-4 ">
      {{ $t('history.export.description') }} {{ userEmail }}
    </p>
    <RPButton class="mb-6" @click="exportCSV">
      {{ $t('history.export.button.primary') }}
    </RPButton>
  </ModalBottom>

  <template v-if="componentReady">
    <div v-if="allEvents?.length" ref="historyRef" class="w-full px-4 pb-10">
      <div v-for="event in allEvents" :key="event.id">
        <RPCard 
          hasClick
          hasTitle
          class="mb-4"
          :class="{ 'bg-primary-darker border-secondary-btn-bg': isEventUpcoming(event.sampleTime) }"
          @click="$router.push(`/history/${event.id}`)"
        >
          <template #title>
            {{ isEventUpcoming(event.sampleTime) ? $t('history.event_cost_upcoming') : $t(getCardName(event.type)) }}
          </template>
          <TripSummary 
            v-if="event.type === 'TRIP'"
            :event="(event as HistoryTripEvent)" 
            class="flex items-center pt-8 px-4 pb-2" 
          />
          <PaymentSummary 
            v-if="event.type === 'PAYMENT'"
            :event="(event as HistoryPaymentEvent)"
            class="pt-6 px-4 pb-2" 
          />
          <CostSummary 
            v-if="event.type === 'COST'"
            :event="(event as HistoryCostEvent)"
            :isUpcoming="isEventUpcoming(event.sampleTime)"
            class="pt-8 px-4 pb-2" 
          />
        </RPCard>
      </div>
      <LoadingSpinner v-if="loadMore" />
    </div>
    <span v-else class="pt-[15px] text-center px-4">
      {{ $t('history.empty') }}
    </span>
  </template>
  <LoadingOverlay v-else />
  <RPButton
    theme="custom"
    class="
      fixed bottom-8 right-4 
      bg-primary-btn-bg rounded-full 
      w-12 h-12 p-0
      text-4xl text-secondary-icon font-thin"
    @click="$router.push('/history/create')"
  >
    +
  </RPButton>
</template>

<script setup lang="ts">
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import ModalBottom from '@/components/ModalBottom.vue';
import TripSummary from '@/components/TripSummary/TripSummary.vue';
import HistoryFilters from '@/components/HistoryFilters.vue';
import LoadingSpinner from '@/components/Atoms/Loadingspinner.vue';
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';
import PaymentSummary from './components/PaymentSummary.vue';
import CostSummary from './components/CostSummary.vue';

import { HistoryTripEvent, HistoryPaymentEvent, HistoryCostEvent, EventFilters } from '@/store/modules/history/types';
import { useStore } from 'vuex';
import { computed, onMounted, ref, onBeforeMount } from 'vue';
import { debounce } from 'lodash';
import { exportEvents } from '@/api/user';

const store = useStore();

const CARD_NAME_MAP = {
  TRIP: 'history.event_trip',
  PAYMENT: 'history.event_payment',
  COST: 'history.event_cost',
};
const componentReady = ref(false);
const historyRef = ref<HTMLElement | null>(null);

onBeforeMount(async () => {
  try {
    !store.state.auth.userPreferences && await store.dispatch('auth/storeUserPreferences');
    !store.state.history.events?.length && await store.dispatch('history/getHistoryEvents', { page: 1 });
  } catch (error) {
    console.log(error);
  } finally {
    componentReady.value = true;
  }
});

const getCardName = (type: string) => CARD_NAME_MAP[type as keyof typeof CARD_NAME_MAP];
// infinite scroll
const heightToRemoveFromScroll = 44 + 88; // 44 header, 88 filters
const paginationCounter = ref(1);
const loadMore = ref(false);

onMounted(() => {
  window.addEventListener('scroll', debounce(() => {
    if (historyRef.value) {
      checkScroll();
    }
  }, 500));

  const checkScroll = async () => {
    const scrollPos = document.documentElement.scrollTop - heightToRemoveFromScroll;
    const docHeight = historyRef.value!.offsetHeight - window.innerHeight;
    const tolerance = 10;

    const bottomOfWindow = (scrollPos + tolerance) >= docHeight;

    if (bottomOfWindow && allEvents.value.length >= 20) {
      loadMore.value = true;
      paginationCounter.value++;
      try {
        await store.dispatch('history/getHistoryEvents', { page: paginationCounter.value });
      } catch (error) {
        console.log(error);
      } finally {
        loadMore.value = false;
      }
    }
  };
});

//Events to show in list
const allEvents = computed(() => store.state.history.events);
const setEventFilter = async (event: EventFilters) => {
  componentReady.value = false;
  // Reset the page counter
  paginationCounter.value = 1;
  //Set the filter selected on the store
  store.dispatch('history/setEventFilter', event);
  // Reset the store event array
  store.state.history.events = [];
  // Make a new API call with the filter selected
  try {
    await store.dispatch('history/getHistoryEvents', { page: paginationCounter.value });
  } catch (error) {
    console.log(error);
  } finally {
    componentReady.value = true;
  }
};

// check if event is in the future for cost events
const isEventUpcoming = (eventDate: string) => {
  const eventDateObject = new Date(eventDate);
  const now = new Date();
  return eventDateObject.getTime() > now.getTime();
};

// Export CSV
const userEmail = store.state.auth.email;
const isModalExportOpen = ref(false);
const exportCSV = async () => {
  try {
    await exportEvents(store.state.auth.selectedVehicle.thingId);

  } catch (error) {
    console.log(error);
  } finally {
    isModalExportOpen.value = false;
  }
};
</script>
