<template>
  <div class="flex items-center">
    <input :id="props.id" v-model="modelCheckbox" type="checkbox" :data-cy="id" class="checkbox__box" :rules="props.rules"
      @change="emits('update:modelValue', modelCheckbox)" />

    <label class="ml-4" v-html="props.label" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const emits = defineEmits(['update:modelValue'])

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  rules: {
    type: [String, Object],
    default: ''
  },
  id: {
    type: String,
    required: true
  }
});

const modelCheckbox = ref(false)
</script>

<style lang="scss" scoped>
.checkbox__box {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  font-size: var(--fontSizeBody);
  color: var(--colorPrimaryBg);
  width: 20px;
  height: 20px;
  border: 2px solid var(--colorPrimaryBtnBg);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  transition: background-color 0.3s;

  &::before {
    content: '';
    width: 15px;
    height: 15px;
    opacity: 0;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    box-shadow: inset 1em 1em var(--colorPrimaryBg);
  }

  &:checked {
    background-color: var(--colorPrimaryBtnBg);
  }

  &:checked::before {
    opacity: 1;
  }
}
</style>
