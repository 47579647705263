<template>
  <SummaryLandscapePayment v-if="props.isLandscape" @createOrder="$emit('startPay')" />
  <section v-else class="flex-col items-center mt-auto pt-4">
    <!-- Logged in User -->
    <div v-if="props.state.matches('user')" class="flex w-full flex-col items-center">
      <SummaryPaymentCard :state="props.state" />
      <RPButton
        v-if="!props.isDevicePayment"
        id="paybtn"
        :disabled="['user.poiRejectedPM', 'user.noPM'].some(props.state.matches) || voucherPreviewLoading"
        data-cy="payButton"
        @click="$emit('startPay')"
      >
        {{ props.buttonText }}
      </RPButton>
    </div>

    <!-- Guest User -->
    <div v-if="props.state.matches('guest')">
      <div v-if="guestRequireLoginMap[props.state.value.guest]">
        <p class="text-fs-16 font-bold pb-6">
          {{ $t(guestRequireLoginMap[props.state.value.guest].title) }}
        </p>
        <p class="text-fs-12 pb-4">
          {{ $t(guestRequireLoginMap[props.state.value.guest].text) }}
        </p>
        <RPButton class="mb-6" data-cy="checkoutLoginButton" @click="authRequireRedirect('/login')">
          {{ $t('fueling.summary.buttons.login') }}
        </RPButton>
        <div class="inline">
          {{ $t('fueling.summary.text.or') }}
          <RPButton link class="inline" data-cy="checkoutRegisterButton" @click="authRequireRedirect('/register')">
            {{ $t('fueling.summary.text.register') }}
          </RPButton>
        </div>
      </div>
      <div v-if="props.state.matches('guest.allowed')">
        <p
          class="text-fs-12 pb-4"
          v-html="$t('fueling.summary.text.tncs', {
            terms_and_conditions: 'https://rydapp.page.link/terms',
            data_protection: 'https://ryd.one/distribution-page/data-protection/'
          })"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import SummaryPaymentCard from './SummaryPaymentCard.vue';
import SummaryLandscapePayment from './SummaryLandscapePayment.vue';

const props = defineProps({
  state: {
    type: Object,
    required: true
  },
  isLandscape: {
    type: Boolean,
    default: false
  },
  isDevicePayment: {
    type: Boolean,
    required: true
  },
  buttonText: {
    type: String,
    required: true
  }
});
const store = useStore();
const router = useRouter();
const route = useRoute();

const voucherPreviewLoading = computed(() => store.state.vouchers.voucherPreviewLoading);

const savedParamsTrackOrder = computed(() => store.state.station.ui);

const guestRequireLoginMap = {
  guestRequireAuth: {
    title: 'fueling.summary.text.auth_required.title',
    text: 'fueling.summary.text.auth_required.text'
  },
  deviceRejectedPM: {
    title: 'fueling.summary.text.device_not_supported.title',
    text: 'fueling.summary.text.device_not_supported.text'
  },
  poiRejectedPM: {
    title: 'fueling.summary.text.poi_not_supported.title',
    text: 'fueling.summary.text.poi_not_supported.text'
  }
};
const authRequireRedirect = (path: string) => {
  const { amount, fuelTypeId, pumpId, orderType } = savedParamsTrackOrder.value;
  const paramsToTrackOrderPrepay = { amount, fuelTypeId, pumpId, orderType };

  router.push({
    path,
    query: {
      ...route.query,
      pumpId,
      ...(orderType === 'PREPAY' && paramsToTrackOrderPrepay)
    }
  });
};
</script>