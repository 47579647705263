import { useStore } from 'vuex';
import { computed, ref } from 'vue';

import { LoyaltyFeatureProvider, LoyaltySubscription } from '@/store/modules/loyalty/types';

const LOYALTY_TYPE_MAP = {
  REPSOL: 'manual',
  NOOP: 'manual'
};
export const LOYALTY_NAME_MAP = [
  'Repsol Move'
]
export function useLoyalty() {
  const store = useStore();

  const activeLoyalty = computed<LoyaltySubscription[]>(() => store.state.loyalty.activeLoyalty);
  const loyaltyPrograms = computed<LoyaltyFeatureProvider[]>(() => store.state.loyalty.loyaltyPrograms);
  const supportedLoyaltyIdByPoi = computed<string[]>(() => store.state.station.data.supportedLoyaltyPrograms);

  const loyaltyProgramsCanSubscribe = computed(() =>
    loyaltyPrograms.value.filter((item: LoyaltyFeatureProvider) => !item.blockNewSubscriptions)
  );

  const getUserLoyaltySupportedByPoi = computed<LoyaltySubscription[]>(() =>
    activeLoyalty.value.filter(userLoyalty => {
      const userLoyaltyName = userLoyalty.programName;
      return supportedLoyaltyIdByPoi.value.includes(userLoyaltyName);
    })
  );

  const getLoyaltyDetails = (program: LoyaltySubscription) => {
    if (LOYALTY_TYPE_MAP[program.provider] === 'manual') return program.details.cardNumber;
  };

  /**
   * @name supportedLoyaltyByPoi
   * @desc We take only the first supported Loyalty for now
   */
  const supportedLoyaltyByPoi = computed<LoyaltyFeatureProvider>(() =>
    loyaltyPrograms.value && loyaltyPrograms.value.find(program => program.name === supportedLoyaltyIdByPoi.value[0])
  );

  const loyaltyIcons = computed(() => store.state.loyalty.loyaltyIcons)
  return {
    activeLoyalty,
    loyaltyPrograms,
    supportedLoyaltyIdByPoi,
    loyaltyProgramsCanSubscribe,
    getUserLoyaltySupportedByPoi,
    getLoyaltyDetails,
    supportedLoyaltyByPoi,
    loyaltyIcons,
    LOYALTY_NAME_MAP,
  };
}
export const getLoyaltyIcon = (programName) => {
  const loyaltyIcons = useLoyalty().loyaltyIcons
  return  loyaltyIcons.value.find(item => item.name === programName)
}