import axios from './index';
import { EventsAPIResponse, PaginatedEvent, Event, CreateCostEventAPI } from './types';

export async function getAllHistory(page: number, filter: string | null, thingId: string): Promise<PaginatedEvent> {
  const limit = 20;
  const types = !filter ? '' : `&types=${filter}`;
  const vehicleId = thingId ? `&vehicleId=${thingId}` : '';

  const response = await axios({
    method: 'get',
    url: `v4/account-history?page=${page}&limit=${limit}${types}${vehicleId}`,
  });
  return response.data;
}

export async function getEvent(id: string): Promise<Event> {
  const response = await axios({
    method: 'get',
    url: `v4/account-history/${id}`
  });
  return response.data;
}
export const createEventCostAPI = async (payload: CreateCostEventAPI): Promise<Event> => {
  const response = await axios({
    method: 'post',
    url: `v4/account-history/costs`,
    data: {
      ...payload
    }
  });
  return response.data.data;
};
export const updateEventCostAPI = async (payload: CreateCostEventAPI, id: string) => {
   await axios({
    method: 'patch',
    url: `v4/account-history/costs/${id}`,
    data: {
      ...payload,
      reach: 'onlyThis'
    }
  });
};
export const deleteEventCostAPI = async (category: string, id: string, reach: string) => {
  await axios.delete(`v4/account-history/costs/${id}?category=${category}&reach=${reach}`);
};
export const uploadImageAPI = async (image: ArrayBuffer, type: string, eventId: string) => {
  await axios({
    method: 'POST',
    url: `v4/account-history/${eventId}/images`,
    headers: {
      'Content-Type': type
    },
    data: image
  });
};