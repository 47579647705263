// Doc: https://pay-gateway-dev.internal.thinxcloud.de/specs/openapi/#/loyalty-subscriptions

import axios from './index';


export const subscribeLoyalty = async (loyaltyId, cardNumber) => {
  await axios({
    method: 'post',
    url: '/v4/loyalty-subscriptions/',
    data: {
      loyaltyProgramId: loyaltyId,
      cardNumber
    }
  });

};

export const deactivateLoyalty = async subscriptionId => {
  await axios.delete(`/v4/loyalty-subscriptions/${subscriptionId}`);
};

export const getActiveLoyalty = async () => {
  const response = await axios.get(`/v4/loyalty-subscriptions`, {
    validateStatus: status => {
      return status;
    }
  });
  if (response.status >= 400 && response.status <= 502) {
    throw new Error('Retrieving active loyalty programs failed');
  }
  return response.data;
};
