import { currencyString } from '@/utils/utils';
import { VouchersState, CampaignParticipation, DiscountPreviewDto } from './types'

import { vouchersListAPI, activateCampaignAPI, previewVoucherAPI, addCustomVoucherAPI } from '@/api/vouchers'

export const VOUCHER_ERROR_MAP = [
  'ACTIVE_VOUCHER', 'VOUCHER_LIST'
]
const getDefaultState = (): VouchersState => {
  return {
    vouchers: [],
  error: null,
  voucherPreview: null,
  voucherPreviewLoading: true
  }
};
const state: VouchersState = getDefaultState();


const mutations = {
  setVouchers(state: VouchersState, payload: CampaignParticipation[]) {
    state.vouchers = payload;
  },
  setVouchersPreview(state: VouchersState, payload: DiscountPreviewDto) {
    state.voucherPreview = payload;
  },
  setVouchersPreviewLoading(state: VouchersState, payload: boolean) {
    state.voucherPreviewLoading = payload;
  },
  setError(state, payload) {
    state.error = payload
  },
  resetError(state, payload) {
    state.error = payload
  },
  clearVouchersState(state) {
    Object.assign(state, getDefaultState());
  }
};
const actions = {
  async loadVouchers({ commit }) {
    try {
      const vouchers = await vouchersListAPI()
      commit('setVouchers', vouchers);

    } catch(e) {
      console.log('retrieve vouchers error', e);
      commit('setError', 'VOUCHER_LIST')
      throw new Error(e);
      
    }
  },
  async activateCampaignAction({ commit, dispatch}, campaignId:string) {
    try {
      await activateCampaignAPI(campaignId)
      await dispatch('loadVouchers')
    } catch (e) {
      console.log('active voucher fail', e)
      commit('setError', 'ACTIVE_VOUCHER')
      throw new Error(e);
      
    }
  },
  async getVoucherPreview({ commit, dispatch }, payload) {
    try {
      commit('setVouchersPreviewLoading', true)
      const preview = await previewVoucherAPI({ ...payload })
      commit('setVouchersPreview', preview)

    }catch (e) {
      console.log('retrieve preview discount fail', e)
      commit('setError', 'PREVIEW_VOUCHER')
      commit('setVouchersPreview', null)
      throw new Error(e);
      
    } finally {
      commit('setVouchersPreviewLoading', false)
    }
  },
  async addCustomVoucher({ commit, dispatch, rootState }, code: string) {
    const accountId = rootState.auth.accountId
    try {
      await addCustomVoucherAPI(code)
      await dispatch('loadVouchers')
      await dispatch('psp/loadFeatures', accountId, { root: true });
    } catch (e) {
      console.log('custom voucher fail', e)
      commit('setError', 'ACTIVE_VOUCHER')
      throw new Error();
      
    }
  },
};

const getters = {
  totalAfterDiscountsFormatted(state) {
    if (!state.voucherPreview?.totalAfterDiscounts?.amount) {
      return null;
    }
    const amount = state.voucherPreview.totalAfterDiscounts.amount;
    return currencyString(amount, state.voucherPreview.totalAfterDiscounts.currency);
  },
}

export default {
  state,
  mutations,
  actions,
  getters
};