<template>
  <Teleport to="#topBarIconLeft">
    <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" @click="backButton">
      <RPIcon svg-fill="text" type="arrowLeft" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span>{{ showOdometer ? odometerScreenData.title : $t('menu.paySummary') }}</span>
  </Teleport>
  <template v-if="!showError">
    <!-- Pin -->
    <PaymentValidationMethod v-if="pinShow" :on-finish="startPay" />
    <Odometer
      v-else-if="showOdometer"
      :description="odometerScreenData.body"
      @close="additionalScreenClosed = true"
    />
    <Loadingspinner v-else-if="!componentReady" />

    <div v-else class="postpay-summary w-full px-6 view-block" :class="{ landscape: isLandscape }">
      <div>
        <OrderSummary status-for-timestamp="PAYMENT_PENDING" isCheckout :xState="props.state" />
        <Divider />
        <div class="flex justify-between items-center text-fs-title font-bold">
          <p class="text-tertiary-text">
            {{ $t('fueling.finish.table.total') }}
          </p>
          <p>{{ totalAfterDiscountsFormatted || total }}</p>
        </div>
      </div>

      <div>
        <!-- Loyalty card -->
        <SummaryLoyalty v-if="!props.state.matches('guest')" />
        <!-- Payment Methods -->
        <SummaryPayment
          :state="props.state"
          :isLandscape="isLandscape"
          :isDevicePayment="isWalletPayment"
          :buttonText="$t('fueling.summary.buttons.pay')"
          @startPay="checkValidation"
        />
        <div v-if="['user.allowed'].some(props.state.matches) && isWalletPayment" class="mb-4">
          <ApplePayButton v-if="paymentIsApplePay" @click="startPay" />
          <GooglePayButton v-if="paymentIsGpay" :on-click-event="startPay" />
          <AmazonPayButton v-if="paymentIsAmazonPay && !voucherPreviewLoading" :amount="Number(orderResult.amount)" :currency="orderResult.currency" />
        </div>
        <div v-if="['guest.allowed'].some(props.state.matches)" class="mb-4">
          <GooglePayButton v-if="paymentIsGpay" :on-click-event="startPay" />
          <ApplePayButton v-if="paymentIsApplePay" @click="startPay" />
          <div class="inline">
            {{ $t('fueling.summary.text.or') }}
            <RPButton link class="inline pt-4" data-cy="checkoutRegisterButton" @click="authRequireRedirect('/register')">
              {{ $t('fueling.summary.text.registerOrLogin') }}
            </RPButton>
          </div>
        </div>
      </div>
    </div>
  </template>
  <div v-else class="flex justify-center">
    <RPInfo
      type="error"
      :title="$t(errorText.title)"
      :subTitle="$t(errorText.subtitle)"
      @click="errorRedirect"
    />
  </div>
</template>
<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import Divider from '@/components/Atoms/Divider.vue';
import OrderSummary from '@/components/Layout/OrderSummary.vue';
import PaymentValidationMethod from '@/components/Layout/PaymentValidationMethod.vue';
import ApplePayButton from '@/components/Atoms/ApplePayButton.vue';
import GooglePayButton from '@/components/Atoms/GooglePayButton.vue';
import AmazonPayButton from '@/components/Atoms/AmazonPayButton.vue';
import SummaryPayment from '../components/SummaryPayment.vue';
import RPInfo from '@/components/Layout/RPInfo.vue';
import Loadingspinner from '@/components/Atoms/Loadingspinner.vue';
import SummaryLoyalty from '../components/SummaryLoyalty.vue';
import Odometer from '../components/Odometer.vue';

import { usePayments } from '@/composable/usePayments';
import { resetAllURLparams } from '@/utils/utils';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, onBeforeMount, onBeforeUnmount, ref, inject } from 'vue';
import { omit } from 'lodash';
import pWaitFor from 'p-wait-for';

const props = defineProps({
  state: {
    type: Object,
    required: true
  }
});
const store = useStore();
const route = useRoute();
const router = useRouter();
const matomo: any = inject('Matomo');

const pinShow = ref(false);
const showError = ref(false);
const errorText = ref(null);

const componentReady = ref(false);
const voucherPreviewLoading = computed(() => store.state.vouchers.voucherPreviewLoading);

// Additional screen
const odometerInStorage = localStorage.getItem('odometer');
const hasRefusedOdometerInput = odometerInStorage && odometerInStorage === 'false';
const odometerScreenData = computed(() => store.state.auth.odometerScreenData);
const additionalScreenClosed = ref(false);
const showOdometer = computed(() =>
  odometerScreenData.value
  && !additionalScreenClosed.value
  && !hasRefusedOdometerInput);

const {
  isWalletPayment,
  paymentShouldSkipValidation,
  paymentIsGpay,
  paymentIsApplePay,
  paymentIsAmazonPay,
  getAmazonPayment,
  getAmazonPaymentId,
  checkoutMethod
} = usePayments();

onBeforeMount(async () => {
  if (!route.query.orderId) return router.push({ path: '/pay' });

  try {
    store.dispatch('ui/saveURLParamsForUi');
    await store.dispatch('pay/getOrderDetail', route.query.orderId);

    const { productRequest } = store.state.pay.orderStatus;
    const pumpId = productRequest?.pump.id;
    if (pumpId) {
      store.commit('station/saveURLParamsForTrackOrder', { pumpId });
    }

  } catch (error) {
    console.log('ERROR', error);
    errorText.value = {
      title: 'errors.fueling_postpay_order.title',
      subtitle: 'errors.fueling_postpay_order.text'
    };
    showError.value = true;
  } finally {
    componentReady.value = true;
  }
  // if returning from amazon success we need to authorize
  if (route.query.amazonCheckoutSessionId) {
    // Avoid to see the odometer when returning from Amazon Pay
    additionalScreenClosed.value = true;

    store.dispatch('psp/setTemporaryPM', getAmazonPayment.value);
    store.commit('pay/setAmazonPayPayment', route.query.amazonCheckoutSessionId);
    startPay();
    return;
  }
  // if returning from amazon cancel
  if (route.query.amazonIsCancel) {
    // Avoid to see the odometer when returning from Amazon Pay
    additionalScreenClosed.value = true;
    store.dispatch('psp/setTemporaryPM', getAmazonPayment.value);
    router.replace({ path: route.path, query: { ...omit(route.query, ['amazonIsCancel']) } });
  }

});

// Store state
const isLandscape = computed(() => store.state.ui.query.landscape);
const totalAfterDiscountsVouchers = computed(() => store.state.vouchers.voucherPreview?.totalAfterDiscounts);

//  Store Getters
const payFlowPages = computed(() => store.getters['pay/payFlowPages']);
const total = computed(() => store.getters['pay/total']);
const totalAfterDiscountsFormatted = computed(() => store.getters['vouchers/totalAfterDiscountsFormatted']);
const originalParams = resetAllURLparams(route.query);

//back button manage
const backButton = () => {
  if (pinShow.value) return (pinShow.value = false);
  localStorage.removeItem('odometer');
  return router.push({ path: '/pay', query: originalParams });
};
const errorRedirect = () => {
  router.push({ path: '/pay', query: originalParams });
};

// Pay and redirect
const checkValidation = () => {
  paymentShouldSkipValidation.value ? startPay() : (pinShow.value = true);
};
const startPay = async () => {
  try {
    pinShow.value = false;
    store.commit('ui/setLoading', true);

    if (paymentIsApplePay.value) {
      await store.dispatch('pay/createApplePaySession');
    }

    if (paymentIsAmazonPay.value) {

      // We wait for the API /discount-preview to grab the right amount to authorize
      await pWaitFor(() => !voucherPreviewLoading.value);

      await store.dispatch('pay/authorizeAmazon', {
        paymentMethod: 'AMAZON_PAY',
        paymentId: getAmazonPaymentId
      });

      const cleanQuery = omit(route.query, ['amazonCheckoutSessionId', 'paymentId']);
      router.push({ path: '/pay/finish', query: cleanQuery });
      return;
    }

    const authorizeLink = await store.dispatch('pay/startPay');
    if (authorizeLink) {
      return window.location.assign(authorizeLink);
    }

    const nextPagePath = payFlowPages.value.nextPagePath;

    router.replace(nextPagePath);
    store.commit('ui/setLoading', false);
  } catch (e) {
    console.log(e);

    const userClosedGooglePaymentRequest =
      paymentIsGpay.value && e.statusCode === 'CANCELED';
    if (userClosedGooglePaymentRequest) {
      matomo.trackEvent('Order', 'Google Pay closed', store.state.pay.orderStatus.id);
      return;
    }

    const userClosedAppleRequest = paymentIsApplePay.value && e.type === 'cancel';
    if (userClosedAppleRequest) {
      matomo.trackEvent('Order', 'Apple pay closed', store.state.pay.orderStatus.id);
      return;
    }

    if (isWalletPayment) {
      matomo.trackEvent('Order', `${checkoutMethod?.value.method} session failed`, store.state.pay.orderStatus.id);
    } else {
      matomo.trackEvent('Order', 'Failed', store.state.pay.orderStatus.id);
      router.replace({ path: '/error', query: { ...route.query, type: 'payment' } });
    }

    router.replace({ path: '/error', query: { ...route.query, type: 'payment' } });
  } finally {
    store.commit('ui/setLoading', false);
  }
};

const savedParamsTrackOrder = computed(() => store.state.station.ui);
const authRequireRedirect = (path: string) => {
  const { amount, fuelTypeId, pumpId, orderType } = savedParamsTrackOrder.value;
  const paramsToTrackOrderPrepay = { amount, fuelTypeId, pumpId, orderType };

  router.push({
    path,
    query: {
      ...route.query,
      pumpId,
      ...(orderType === 'PREPAY' && paramsToTrackOrderPrepay)
    }
  });
};

// Amazon Pay
const orderResult = computed(() => totalAfterDiscountsVouchers.value || store.state.pay.orderStatus?.productFinished?.price);

</script>
<style lang="scss" scoped>
.postpay-summary.landscape {
  max-width: var(--appMaxWidthContainerLandscape);

  .postpay-summary {
    &__footer {
      position: absolute;
      left: 0;
      width: 100vw;
      background-color: var(--colorPrimaryBgDarker);
      padding: var(--space-md) 0;
    }

    &__footer-container {
      max-width: var(--appMaxWidth);
      margin: 0 auto;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
}
</style>
