<template>
  <Teleport to="#topBarIconLeft">
    <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" @click="backClick">
      <RPIcon svg-fill="text" type="arrowLeft" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span>{{ selectedProgramToDelete ? $t('loyalty.edit.header_title') : $t('loyalty.header_title') }}</span>
  </Teleport>
  <LoyaltyEdit v-if="selectedProgramToDelete" :program="selectedProgramToDelete" @emitRemoveLoyalty="removeLoyaltyProgram()" />

  <template v-else>
    <h2 class="text-fs-20 text-left w-full px-4 mb-6">{{ $t('loyalty.active_title') }}</h2>
    <div class="view-block w-full px-4">
      <p v-if="!activeLoyalty.length">{{ $t('loyalty.no_active') }}</p>
      <div v-else>
        <RPCard
          v-for="item in activeLoyalty"
          small
          hasClick
          @click="openEditProgram(item)"
          class="items-start mb-4">
          <div class="flex items-center font-medium w-full">
            <LoyaltyIcon :programName="item.programName" class="w-8"/>
            <div class="w-full text-left truncate">
              <strong>{{ item.programName }}</strong>
              <p
                class="m-0 mt-[2px] truncate">
                {{ getLoyaltyDetails(item) }}
              </p>
            </div>
          </div>
        </RPCard>
      </div>
      <RPButton
        data-cy="buttonAddPayment"
        :disabled="false"
        @click="goToAddLoyalty()">
        {{ $t('loyalty.add_cta') }}
      </RPButton>
    </div>
  </template>
</template>

<script lang="ts">
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import PaymentMethodCard from '@/components/Layout/PaymentMethodCard.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import LoyaltyIcon from '@/components/Layout/LoyaltyIcon.vue';
import LoyaltyEdit from './components/LoyaltyEdit.vue';

import { useLoyalty } from '@/composable/useLoyalty';

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { createOptin } from '@/api/optin';
import { LoyaltySubscription } from '@/store/modules/loyalty/types';

export default {
  components: {
    PaymentMethodCard,
    RPIcon,
    RPButton,
    RPCard,
    LoyaltyEdit,
    LoyaltyIcon
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const { activeLoyalty, getLoyaltyDetails } = useLoyalty();

    const goToAddLoyalty = () => {
      router.push('/profile/loyalty/add');
    };

    const selectedProgramToDelete = ref<LoyaltySubscription>(null);
    const openEditProgram = (program: LoyaltySubscription) => {
      selectedProgramToDelete.value = program;
    };

    const backClick = () => {
      selectedProgramToDelete.value ? (selectedProgramToDelete.value = null) : router.push('/profile');
    };

    // Deactivate Program
    const accountId = computed(() => store.state.auth.accountId);
    const userId = computed(() => store.state.auth.id);

    const optinData = computed(() => ({
      name: `${selectedProgramToDelete.value.programName}_${selectedProgramToDelete.value.provider}`,
      action: 'reset',
      accountId: accountId.value,
      userId: userId.value
    }));

    const removeLoyaltyProgram = async () => {
      try {
        await createOptin(optinData.value);
        //payments request
        await store.dispatch('loyalty/deactivateLoyalty', {
          loyaltyId: selectedProgramToDelete.value.id
        });
        selectedProgramToDelete.value = null;
      } catch (e) {
        console.log(e);
      }
    };
    return {
      activeLoyalty,
      goToAddLoyalty,
      openEditProgram,
      selectedProgramToDelete,
      removeLoyaltyProgram,
      getLoyaltyDetails,
      backClick
    };
  }
};
</script>
