<template>
  <div class="view-block items-center font-medium px-6">
    <div class="flex-center-col">
      <RPIcon :type="type" no-fill class="w-40" />
      <h2 class="mt-6 mb-4">{{ type === 'error' ? errorDefaultText.title : title }}</h2>
      <p class="w-80 text-center">
        {{ type === 'error' ? errorDefaultText.subTitle : subTitle }}
      </p>
    </div>
    <RPButton @click="$emit('click')">
      {{ type === 'error' ? errorDefaultText.buttonText : buttonText }}
    </RPButton>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import RPButton from '../Atoms/RPButton/RPButton.vue';
import RPIcon from '../Atoms/RPIcon/RPIcon.vue';

const INFO_TYPES = ['error', 'success'];
export default {
  components: {
    RPButton,
    RPIcon
  },
  emits: ['click'],
  props: {
    type: {
      type: String,
      required: true,
      validator: value => INFO_TYPES.includes(value)
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  computed: {
    errorDefaultText() {
      return {
        title: isEmpty(this.title) ? this.$i18n.t('errors.generic.title') : this.title,
        subTitle: isEmpty(this.subTitle) ? this.$i18n.t('errors.generic.text') : this.subTitle,
        buttonText: isEmpty(this.buttonText) ? this.$i18n.t('errors.button_ok') : this.buttonText
      };
    }
  }
};
</script>