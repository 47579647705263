<template>
  <div>
    <button
      theme="custom"
      class="bg-black/50 w-full h-full fixed top-0 left-0 outline-none"
      @click="closeSideBar"
    >
      <aside class="bg-primary-background w-[200px] absolute right-0 top-0 h-full p-md pt-base">
        <LanguageItem :lang="selected" :current="true" />
        <Divider />
        <LanguageItem
          v-for="(lang, index) in availableLocales.filter(i => i !== selected)"
          :key="index"
          :lang="lang"
          @click="saveLanguage(lang)"
        />
      </aside>
    </button>
  </div>
</template>

<script setup lang="ts">
import { setLanguage, supportedLanguages } from '@/i18n.js';
import Divider from '@/components/Atoms/Divider.vue';
import LanguageItem from '@/components/Atoms/LanguageItem.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const { locale } = useI18n();

const selected = computed(() => locale.value);

const availableLocales = computed(() => Object.keys(supportedLanguages));

const saveLanguage = (newLanguage) => {
  setLanguage(newLanguage);
  closeSideBar();
};

const closeSideBar = () => {
  store.commit('ui/setSidebar', { type: 'language', visible: false });
};
</script>
