<template>
  <section class="flex-1 flex-center-col">
    <h3 class="text-left w-full text-fs-20 mb-4">
      {{ !pin1 ? $t('paymentPin.create.title1') : $t('paymentPin.create.title2') }}
    </h3>
    <p v-if="!pin1" class="text-fs-12">
      {{ $t('paymentPin.create.text') }}
    </p>
    <Pin :key="'triggerRerender' + pin1" :error-msg="error" @pin="pin" />
  </section>
</template>

<script>
import Pin from '@/components/Atoms/Pin.vue';
import { savePin } from '@/api/pin';

export default {
  components: { Pin },
  data() {
    return {
      error: null,
      pin1: null
    };
  },
  emits: ['success', 'error'],
  methods: {
    async pin(pin) {
      this.error = null;
      if (!this.pin1) {
        this.pin1 = pin;
        return;
      }
      if (pin != this.pin1) {
        this.error = this.$t('paymentPin.validate.error');
        return;
      }

      try {
        this.$store.commit('ui/setLoading', true);
        await savePin({ pin });
        this.$emit('success');
      } catch (e) {
        console.error(e);
        this.$emit('error');
      } finally {
        this.$store.commit('ui/setLoading', false);
      }
    }
  }
};
</script>
