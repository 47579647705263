<template>
  <footer class="
    absolute left-0 
    w-screen 
    bg-primary-darker
    py-md px-0
    sm:bottom-[var(--footerMinHeight)] bottom-[var(--footerMaxHeight)]">
    <div class="
      max-w-appMaxWidth w-full 
      mx-auto px-8
      flex flex-row justify-between 
      box-border">
      <div class="flex-row items-center flex pr-3">
        <RPButton id="paybtn" data-cy="payButton" @click="$emit('createOrder')">
          {{ $t('fueling.summary.buttons.start') }}
        </RPButton>
        <RPButton class="ml-xl" theme="secondary" @click="$router.push('/pay')">
          {{ $t('fueling.summary.buttons.cancel') }}
        </RPButton>
      </div>
      <!-- <SummaryPaymentCard /> -->
    </div>
  </footer>
</template>
<script lang="ts">
// TODO: This is an MVP of the landscape.
// This component doesn't have all the logic applied to the mobile one because of lack of design
// f.e. APay/GPay button, logic for guest user...
import { defineComponent } from 'vue';

import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import SummaryPaymentCard from '../components/SummaryPaymentCard.vue';

export default defineComponent({
  components: {
    SummaryPaymentCard,
    RPButton
  }
});
</script>
