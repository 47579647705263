<template>
  <Teleport to="#topBarIconLeft">
    <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" href="/pay">
      <RPIcon svg-fill="text" type="arrowLeft" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span>{{ $t('menu.profile') }}</span>
  </Teleport>
  <Teleport to="#topBarIconRight">
    <RPButton theme="custom" class="p-0 min-w-min" data-cy="goToSettings" @click="$router.push('/settings')">
      <RPIcon type="gear" svgFill="text" class="relative l-scape:w-20 l-scape:h-20" />
    </RPButton>
  </Teleport>
  <div class="w-full px-4" data-cy="profileContainer">
    <h2 class="text-fs-20 text-left mb-6">{{ $t('profile.main.title') }}</h2>
    <ul class="p-0 m-0">
      <li v-for="item in listLinksToDisplay" :key="item.link" class="list-none">
        <router-link :to="item.link" class="flex justify-between items-center text-primary-text" :data-cy="item.dataCy">
          <div>
            <strong>{{ item.label }}</strong>
            <p v-if="item.subtitle" class="text-fs-12 text-tertiary-text">{{ item.subtitle }}</p>
          </div>
          <div>
            <RPIcon svg-fill="text" type="arrowRight" />
          </div>
        </router-link>
        <Divider class="my-md" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useGetPaymentInfo } from '@/composable/usePayments';

import Divider from '@/components/Atoms/Divider.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

const store = useStore();
const { t } = useI18n();

const loyalty = computed(() => store.state.loyalty.loyaltyPrograms);

const preferredPM = computed(() => store.getters['psp/preferredPM']);

const { paymentInfo } = useGetPaymentInfo(preferredPM);

// Link list
const listLinks = computed(() => [
  {
    label: t('profile.main.label_payment'),
    link: '/profile/payments',
    isVisible: true,
    subtitle: preferredPM.value ? paymentInfo.value : t('profile.main.label_no_payment'),
    dataCy: 'profile-payments'
  },
  {
    label: t('profile.main.label_loyalty'),
    link: '/profile/loyalty',
    isVisible: loyalty.value && loyalty.value.length > 0,
    subtitle: t('profile.main.label_loyalty_sub'),
    dataCy: 'profile-loyalty'
  },
  {
    label: t('profile.main.label_vouchers'),
    link: '/profile/vouchers',
    isVisible: true,
    subtitle: t('profile.main.label_vouchers_sub'),
    dataCy: 'profile-loyalty'
  },
  {
    label: t('profile.main.label_transaction'),
    link: '/history',
    isVisible: true,
    dataCy: 'profile-transaction'
  },
  {
    label: t('profile.main.label_manage'),
    link: '/profile/manage',
    isVisible: true,
    dataCy: 'profile-manage'
  }
]);
const listLinksToDisplay = computed(() => listLinks.value.filter(item => item.isVisible));
</script>