<template>
  <div class="postpay-fueling px-6" :class="{ landscape: isLandscape }">
    <div class="postpay-fueling__wrapper">
      <Drop :pump="chosenPump" class="postpay-fueling__drop" />
      <div class="postpay-fueling__hint">
        <h2 class="subtitle my-5 text-tertiary-text">{{ $t('fueling.fueling.subtitle') }}</h2>
        <span class="font-medium" v-html="$t('fueling.fueling.text_postpax')" />
      </div>
    </div>
    <footer
      v-if="isLandscape"
      class="postpay-fueling__footer mt-auto sm:bottom-[var(--footerMinHeight)] bottom-[var(--footerMaxHeight)]">
      <div class="postpay-fueling__footer-container">
        <RPButton link @click="cancel()" class="ml-0">
          {{ $t('fueling.fueling.button_cancel') }}
        </RPButton>
      </div>
    </footer>
    <footer v-else class="mt-auto">
      <RPButton link @click="cancel()" class="mt-6">
        {{ $t('fueling.fueling.button_cancel') }}
      </RPButton>
    </footer>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import Drop from '@/components/Atoms/Drop.vue';

export default {
  components: { RPButton, Drop },
  computed: {
    ...mapState({
      isLandscape: state => state.ui.query.landscape,
      oderId: state => state.pay.orderStatus.id
    }),
    ...mapGetters({
      payFlowPages: 'pay/payFlowPages'
    }),
    chosenPump() {
      return this.$store.getters['station/chosenPump']?.name;
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('pay/createOrder');
      await this.$store.dispatch('pay/fuel');
      const nextPagePath = this.payFlowPages.nextPagePath;
      this.$router.replace({
        path: nextPagePath,
        query: { ...this.$route.query, orderId: this.oderId, orderType: 'POSTPAY' }
      });
    } catch (e) {
      if (e === 'ORDER_CANCELED') return this.$router.replace({ path: '/pay' });
      this.$router.replace({ path: '/error', query: { ...this.$route.query, type: 'fueling_postpay' } });
      console.error(e);
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventPageReload)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventPageReload)
  },
  methods: {
    async cancel() {
      await this.$store.dispatch('pay/cancel');
    },
    preventPageReload(event) {
      event.preventDefault()
      event.returnValue = ''
    }
  }
};
</script>
<style lang="scss" scoped>
.postpay-fueling.landscape {
  .postpay-fueling {
    &__wrapper {
      display: flex;
      align-items: center;
      max-width: var(--appMaxWidthContainerLandscape);
      margin: 0 auto;
    }

    &__hint,
    &__hint h2 {
      flex: 3;
      text-align: left;
    }

    &__drop {
      flex: 1;
      height: 220px;
      margin-right: 50px;
      min-width: 140px;
      font-size: 140px;
    }

    &__footer {
      position: absolute;
      left: 0;
      width: 100vw;
      background-color: var(--colorPrimaryBgDarker);
      padding: var(--space-md) 0;
    }

    &__footer-container {
      max-width: var(--appMaxWidth);
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
