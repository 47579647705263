<template>
  <div class="view-block px-6">
    <VeeForm ref="newpw" class="flex flex-col items-center flex-1" @submit="savepw">
      <input type="hidden" name="username" :value="email" autocomplete="username" />
      <div class="flex-1 w-full">
        <h3>{{ t('profile.password.title') }}</h3>
        <TextField
          id="password"
          v-model="newPassword"
          type="password"
          autocomplete="new-password"
          :label="t('profile.password.label_password')"
          rules="required|min:8|max:40"
        />
        <TextField
          v-model="newPasswordAgain"
          type="password"
          autocomplete="new-password"
          :label="t('profile.password.label_password_confirm')"
          rules="required|min:8|max:40|confirmed:@password"
        />
        <p>{{ t('profile.password.text') }}</p>
        <GenericError v-if="error" />
      </div>
      <RPButton
        theme="custom"
        class="
          text-primary-btn-text
          bg-primary-btn-bg"
        type="submit"
      >
        {{ t('profile.password.button') }}
      </RPButton>
    </VeeForm>
    <Dialog v-if="dialog" @close="dialog = false">
      <h2>{{ t('profile.password.success.title') }}</h2>
      <p>{{ t('profile.password.success.text') }}</p>
      <RPButton class="mt-6" @click="$router.replace('/profile')">
        {{ t('profile.password.success.button_ok') }}
      </RPButton>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import Dialog from '@/components/Atoms/Dialog.vue';
import TextField from '@/components/Atoms/TextField.vue';
import GenericError from '@/components/Atoms/GenericError.vue';
import { Form as VeeForm } from 'vee-validate';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const newPassword = ref(null);
const newPasswordAgain = ref(null);
const error = ref(false);
const dialog = ref(false);
const newpw = ref(null);

const store = useStore();

const { t } = useI18n();

const email = computed(() => store.state.auth.email);

const savepw = async () => {
  const { valid } = await newpw.value.validate();
  if (!valid) {
    return;
  }
  error.value = false;
  try {
    await store.dispatch('auth/changePwd', newPassword.value);
    dialog.value = true;
  } catch (e) {
    console.error(e);
    error.value = true;
  }
};
</script>

<style lang="scss" scoped>
</style>
