<template>
  <div class="view-block text-center mt-2 px-4">
    <div>
      <RPIcon svg-fill="text" type="error" class="mb-2 mx-auto" noFill />
      <template v-if="!isErrorExtended">
        <h2>{{ $t(`errors.${type}.title`) }}</h2>
        <p class="font-medium my-2" v-html="$t(`errors.${type}.text`)" />
      </template>
      <template v-else>
        <h2>{{ storedApiErrors.title }}</h2>
        <p class="font-medium my-2" v-for="error in storedApiErrors.details" :key="error">
          {{ error }}
        </p>
        <RPButton link class="grow items-start my-4" href="https://support.ryd.one/hc/de">FAQ Link</RPButton>
      </template>
    </div>
    <div>
      <RPButton link class="items-start my-4" href="https://support.ryd.one/hc/de">FAQ Link</RPButton>
      <RPButton @click="buttonUrl(type)">
        {{ $t('errors.button_ok') }}
      </RPButton>
    </div>
  </div>
</template>
<script>
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

import { resetOrderURLparams, resetPaymentsURLparams, redirectToCallbackUrl, resetAllURLparams } from '@/utils/utils';
import { errors } from '@/rydPaySDK/src/StationData';
import { omit } from 'lodash';
import { mapState } from 'vuex';

export default {
  components: { RPIcon, RPButton },
  computed: {
    ...mapState({
      apiErrors: state => state.ui.apiErrors
    }),
    type() {
      const queryType = this.$route.query.type;
      const customExemptions = Object.values(errors);
      const custom = [...customExemptions, 'fueling_prepay', 'fueling_postpay', 'payment_register', 'payment'];

      return custom.includes(queryType) ? queryType : 'generic';
    },
    storedApiErrors() {
      const url = new URL(window.location.href);
      const errorDescription = JSON.parse(url.searchParams.get('errorDescription'));

      if (errorDescription?.title) {
        return {
          ...errorDescription
        };
      }

      return this.apiErrors;
    },
    isErrorExtended() {
      return !!this.storedApiErrors?.title;
    }
  },
  methods: {
    redirectToCallback(paramsForMap) {
      const redirectToPartner = this.$route.query.callback && this.$route.query.hideRydMapLink;
      if (redirectToPartner) {
        try {
          redirectToCallbackUrl(this.$route.query.callback);
        } catch (e) {
          window.location.href = `${import.meta.env.VUE_APP_MAP}/?${paramsForMap}`;
        }
        return;
      }
      window.location.href = `${import.meta.env.VUE_APP_MAP}/?${paramsForMap}`;
    },
    buttonUrl(type) {
      const paramsWithoutErrorType = omit(this.$route.query, 'type');
      const paramsWithoutOrder = resetOrderURLparams(paramsWithoutErrorType);
      const onlyOriginalParams = resetAllURLparams(paramsWithoutErrorType);
      const paramsWithoutPayment = resetPaymentsURLparams(paramsWithoutErrorType);
      const paramsWithoutLocation = omit(paramsWithoutErrorType, ['latitude', 'longitude', 'pid']);

      const buildParams = new URLSearchParams(paramsWithoutLocation);

      const buttonRedirectMap = {
        payment: () =>
          this.$router.push({
            path: '/pay',
            query: onlyOriginalParams
          }),
        fueling_prepay: () =>
          this.$router.push({
            path: '/pay',
            query: onlyOriginalParams
          }),
        fueling_postpay: () =>
          this.$router.push({
            path: '/pay',
            query: paramsWithoutOrder
          }),
        payment_register: () => {
          //if the payment was created on the checkout return to summary else return to payment page
          this.$route.query.orderType
            ? this.$router.push({
                path: this.$route.query.orderType === 'POSTPAY' ? '/pay/postpay/summary' : '/pay/prepay/summary',
                query: paramsWithoutPayment
              })
            : this.$router.push({
                path: '/payment-method',
                query: paramsWithoutOrder
              });
        },
        [errors.STATION_OFFLINE]: () => {
          this.redirectToCallback(buildParams.toString());
        },
        [errors.NO_STATION_FOUND_LAT_LON]: () => {
          this.redirectToCallback(buildParams.toString());
        },
        default: () => {
          this.redirectToCallback(buildParams.toString());
        }
      };
      const buttonRedirect = buttonRedirectMap[type] || buttonRedirectMap['default'];
      buttonRedirect();
    }
  }
};
</script>
