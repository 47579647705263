<template>
  <!-- Back button -->
  <Teleport v-if="isQuickCheckout || backBtnVisible" to="#topBarIconLeft" data-cy="addressBack">
    <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" href="/profile/manage">
      <RPIcon svg-fill="text" type="arrowLeft" />
    </RPButton>
  </Teleport>
  <LoadingOverlay v-if="!componentIsReady" />
  <VeeForm v-else ref="userDataForm" class="view-block w-full px-6" @submit="updateUserData">
    <div>
      <TextField 
        id="email" 
        v-model="formData.email" 
        :label="$t('account.email_label')" 
        readonly 
        type="email" 
      />
      <TextField 
        id="firstName" 
        v-model="formData.firstName" 
        :label="t('account.firstname_label')"
        :rules="getRequireRule" 
        data-cy="address-firstName" 
        autocomplete="given-name"
      />
      <TextField 
        v-model="formData.lastName" 
        :label="t('account.lastname_label')" 
        :rules="getRequireRule"
        autocomplete="family-name"
      />
      <div class="flex items-end justify-between">
        <div class="w-full mr-4">
          <TextField 
            id="street" 
            v-model="formData.address.street" 
            :label="t('account.street_label')"         
            :rules="getRequireRule" data-cy="address-street" 
            autocomplete="street-address"
          />
        </div>
        <div class="w-28">
          <TextField 
            v-model="formData.address.houseNumber" 
            :label="t('account.house_number_label')"
            :rules="getRequireRule"
          />
        </div>
      </div>
      <TextField 
        id="additional_street" 
        v-model="formData.address.street2" 
        data-cy="address-additional_street"
        :label="t('account.additional_address_info_label')"
      />
      <div class="flex items-end justify-between">
        <div class="w-44 mr-4">
          <TextField 
            id="zip" 
            v-model="formData.address.zip" 
            :label="t('account.zip_code_label')" 
            :rules="getZipCodeRule"
            data-cy="address-zip" 
            autocomplete="postal-code"
          />
        </div>
        <div class="w-full">
          <TextField 
            id="city" 
            v-model="formData.address.city" 
            data-cy="address-city" 
            :label="t('account.city_label')"
            :rules="getRequireRule"
          />
        </div>
      </div>
      <CountrySelect v-model="formData.address.countryCode" />
      <TextField 
        v-if="isIberia"
        id="taxInfo" 
        v-model="formData.taxInfo.taxId" 
        data-cy="address-taxInfo"
        :label="t('account.tax.label')" 
        :custom-error="errorTax"
      />
      <GenericError v-if="error" />
    </div>
    <RPButton 
      theme="custom" 
      type="submit" 
      data-cy="address-update" 
      class="text-primary-btn-text bg-primary-btn-bg mt-10"
    >
      {{ t('account.save') }}
    </RPButton>
  </VeeForm>
  <Dialog v-if="dialog" data-cy="address-close-dialog" @close="dialog = false">
    <h2>{{ t('account.save-success.title') }}</h2>
    <p>{{ t('account.save-success.text') }}</p>
    <RPButton class="w-full mt-6" @click="redirectUser">
      {{ t('account.save-success.button') }}
    </RPButton>
  </Dialog>
</template>

<script setup lang="ts">
import CountrySelect from '@/components/Layout/CountrySelect.vue';
import TextField from '@/components/Atoms/TextField.vue';
import GenericError from '@/components/Atoms/GenericError.vue';
import Dialog from '@/components/Atoms/Dialog.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

import { useLoginRegister } from '../Auth/composable/useLoginRegister';

import { Form as VeeForm } from 'vee-validate';
import { useStore } from 'vuex';
import { omit, pick, cloneDeep } from 'lodash';
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { redirectToCheckout } = useLoginRegister();

const dialog = ref(false);
const error = ref(false);
const errorTax = ref(null);
const backBtnVisible = ref(false);
let formData = reactive({
  email: '',
  firstName: '',
  lastName: '',
  taxInfo: {
    country: '',
    taxId: ''
  },
  address: {
    city: '',
    houseNumber: '',
    street: '',
    street2: '',
    zip: '',
    countryCode: ''
  }
});
const componentIsReady = ref(false);
const userDataForm = ref(null);

const router = useRouter();
const route = useRoute();
const store = useStore();

const { t } = useI18n();

const previousRoute = computed(() => store.state.ui.previousRoute);
const authDataStored = computed(() => store.state.auth);
const isQuickCheckout = computed(() => store.state.auth.quickCheckout);

const getRequireRule = computed(() => {
  return !isQuickCheckout.value ? 'required' : '';
});

const getZipCodeRule = computed(() => {
  if (isQuickCheckout.value && !formData.address.zip) {
    return '';
  }
  return formData.address.countryCode == 'PT' ? 'required|regex:\\d{4}-\\d{3}$' : 'required';
});

const isIberia = computed(() => {
  return ['PT', 'ES'].includes(formData.address?.countryCode);
});

const checkFieldValid = async () => {
  await nextTick();
  const { valid } = await userDataForm.value.validate();
  return valid;
};

const setUserData = async () => {
  const userDataStored = pick(authDataStored.value, Object.keys(formData));
  Object.assign(formData, cloneDeep(userDataStored));
};

const redirectUser = async () => {
  if (route.query.orderType) {
    return await redirectToCheckout();
  }
  router.push({
    path: '/pay'
  });
};

onMounted(async () => {
  componentIsReady.value = false;
  // TODO find a better place for this logic (duplicate code in ConnectedLoginRegister/Login)
  // if user was a guest with an order and wants to register
  // and user refresh the page we need to retrieve the station and the pump chosen
  // so we can create a new order

  if (route.query.orderType && route.query.pumpId) {
    await store.dispatch('station/getOnce');
    store.commit('station/saveURLParamsForTrackOrder', { pumpId: route.query.pumpId });
  }

  if (isQuickCheckout.value && userJustRegister()) {
    redirectUser();
    return;
  }
  setUserData();

  componentIsReady.value = true;

  if (!isQuickCheckout.value && previousRoute.value !== '/change-tmp-password') {
    backBtnVisible.value = await checkFieldValid();
  }
});

const userJustRegister = () => {
  const registerRoutes = ['/change-tmp-password', '/amazonSSORedirect', '/register'];
  return registerRoutes.includes(localStorage.getItem('previousPath'));
};

const updateUserData = async () => {
  const { valid } = await userDataForm.value.validate();
  if (!valid) {
    return;
  }
  error.value = false;
  if (formData.taxInfo) {
    formData.taxInfo.country = formData.address.countryCode;
  }
  const checkedUserData =
    formData.address.countryCode.toUpperCase() !== 'PT' ? omit(formData, 'taxInfo') : formData;

  try {
    await store.dispatch('auth/updateUser', checkedUserData);
    dialog.value = true;
  } catch (error) {
    error.message.includes('taxId') ? (errorTax.value = t('account.tax.error')) : (error = true);
  }
};
</script>
