<template>
  <div class="flex items-center justify-between w-full">
    <div class="py-4 px-2 max-w-[80%]">
      <p class="font-medium truncate">{{ props.voucher.campaign.name }}</p>
      <p v-if="props.voucher.expirationTime" class="text-fs-12">
        {{ getDaysLeft(props.voucher.expirationTime) }} {{ $t('vouchers.daysLeft') }}
      </p>
    </div>
    <div v-if="props.voucher.activated" class="mr-2 font-medium text-fs-12">{{ $t('vouchers.activated') }}</div>
    <RPButton
      v-else
      data-cy="vouchers-active-btn"
      @click="$emit('activate', props.voucher.campaign.id)"
      link 
      class="mr-2 py-4 z-10 text-fs-12"
    >
    {{ $t('vouchers.active') }}
  </RPButton>
  </div>
</template>
<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';

import { CampaignParticipation } from '@/store/modules/vouchers/types';
import { PropType } from 'vue';

const props = defineProps({
  voucher: {
    type: Object as PropType<CampaignParticipation>,
    required: true
  }
});
const emit = defineEmits(['activate']);
const getDaysLeft = (date: string) => {
  const targetDate = new Date(date);
  const today = new Date();

  const timeDiff = targetDate.getTime() - today.getTime();
  const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return Math.abs(daysLeft);
};
</script>