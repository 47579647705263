<template>
  <div class="w-full h-36 relative">
    <VoucherOverlay
      v-if="props.activateIsLoading || props.activateError"
      :activateIsLoading="props.activateIsLoading"
      :activateError="props.activateError"
    />
    <img :src="props.voucher.campaign.imageUrl" alt="voucher image" class="object-cover w-full h-full"/>
  </div>
  <VoucherShortInfo :voucher="props.voucher" @activate="emits('activate', props.voucher.campaign.id)" class="px-2"/>
  <div class="px-4 text-left w-full">
    <p class="pt-4" v-html="props.voucher.campaign.description" />
    <hr class="
      block h-[1px]
      border-0 border-t border-solid border-tertiary-text
      opacity-50 w-full my-4 mx-auto p-0"
    >
    <RPButton 
      @click="tncVisible = !tncVisible" 
      theme="custom" 
      class="flex items-center justify-between px-0 rounded-none"
    >
      {{ $t('vouchers.tnc_accordion') }}
      <RPIcon type="accordion-arrow" noFill :class="{ 'rotate-180': tncVisible }" class="transition-all"/>
    </RPButton>
  </div>
  <div v-if="tncVisible" class="px-4 text-left w-full">
    <p v-if="props.voucher.campaign.tnc.text" class="pt-4" v-html="props.voucher.campaign.tnc.text" />
    <RPButton v-if="props.voucher.campaign.tnc.url" link :href="props.voucher.campaign.tnc.url" class="py-3 mt-4">
      {{ $t('vouchers.tnc_link') }}
    </RPButton>
  </div>
</template>
<script setup lang="ts">
import VoucherOverlay from './components/VoucherOverlay.vue';
import VoucherShortInfo from './components/VoucherShortInfo.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';

import { CampaignParticipation } from '@/store/modules/vouchers/types';
import { onMounted, computed, ref, PropType } from 'vue';

const emits = defineEmits(['activate']);
const props = defineProps({
  voucher: {
    type: Object as PropType<CampaignParticipation>,
    required: true
  },
  activateIsLoading: {
    type: Boolean,
    required: true
  },
  activateError: {
    type: Boolean,
    required: true
  }
});
onMounted(() => window.scrollTo(0, 0));
const tncVisible = ref(false);
</script>