<template>
  <button class="font-medium flex justify-end py-2.5 w-full" @click="!props.current && emits('click')">
    <div v-if="props.current" class="flex-1 text-primary-btn-bg">
      <RPIcon type="check" class="l-scape:w-20 l-scape:h-20" />
    </div>
    <span class="pr-lg">{{ supportedLanguagesFunc[props.lang] }}</span>
    <RPIcon :type="`flags/${props.lang}`" noFill class="l-scape:w-20 l-scape:h-20" />
  </button>
</template>

<script setup lang="ts">
import { supportedLanguages } from '@/i18n.js';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import { computed } from 'vue';

const emits = defineEmits(['click'])
const props = defineProps({
  lang: {
    type: String,
    required: true
  },
  current: {
    type: Boolean,
    default: false
  }
})

const supportedLanguagesFunc = computed(() => supportedLanguages)
</script>