import { AxiosInstance, AxiosResponse } from 'axios';
import globalAxiosInstance from '../../api/index.js';
import pWaitFor from 'p-wait-for';
import {
  Product,
  API_Order,
  PaymentResult,
  Voucher,
  StatusHistoryItem,
  paymentToken,
  createOrderPayload,
  API_Price,
  APIAuthorizePayload
} from './types/Order.js';
import { orderType } from './index.js';
import { UserDetails } from '../../store/modules/pay/types';


const refreshInterval = 2500;

interface StorageSaveObject {
  expiry: number;
  productRequest: Product;
}

interface PostPayload {
  thingId: string;
  poiId: string;
  products: [{ productId: string; fuelTypeId?: string; }];
}

export default class Order {
  id: string | null = null;
  status: API_Order['state'] | null = null;
  productRequest: Product | null = null;
  productFinished: Product | null = null;
  paymentResult: PaymentResult | null = null;
  #axiosInstance: AxiosInstance;
  #thingId: string;
  type: orderType = 'PREPAY';
  statusHistory: StatusHistoryItem[] = [];
  poi: string;
  cancel: boolean;

  constructor(thingId: string, poi: string) {
    this.cancel = false;
    this.#axiosInstance = globalAxiosInstance;
    this.#thingId = thingId;
    this.poi = poi;

    this.#axiosInstance.interceptors.response.use(response => {
      if (response.data?.stateHistory) {
        const stateHistory: API_Order['stateHistory'] = response.data.stateHistory;
        this.statusHistory = stateHistory.map(state => ({
          status: state.state,
          timestamp: new Date(state.createdAt)
        }));
        this.status = response.data.state;
      }
      return response;
    });
  }
  private saveProductRequestToStorage(product: Product | null): void {
    const fifteenMinutes = 15 * 60 * 1000;
    const expiry = new Date().getTime() + fifteenMinutes;

    const saveObj = {
      expiry,
      productRequest: product,
      poi: this.poi
    };

    localStorage.setItem(`rydPaySDK-${this.id}`, JSON.stringify(saveObj));
  }

  private loadProductRequestFromStorage(): void {
    const storageItem = localStorage.getItem(`rydPaySDK-${this.id}`);

    if (!storageItem) {
      throw new Error('No Order found in LocalStorage');
    }

    const saveObj: StorageSaveObject = JSON.parse(storageItem);
    if (saveObj.expiry < new Date().getTime()) {
      localStorage.removeItem(`rydPaySDK-${this.id}`);
      throw new Error('Order in LocalStorage is expired');
    }

    this.productRequest = saveObj.productRequest;
  }

  async create({ price, pumpId, poiId, fuelTypeId, type, initiatedByPartner }: createOrderPayload): Promise<void> {
    if (type) {
      this.type = type;
    }

    if (this.type === 'PREPAY' && !price) {
      throw new Error('Missing price for Prepaid-Flow');
    }

    const payload: PostPayload = {
      thingId: this.#thingId,
      poiId,
      products: [{ productId: pumpId }]
    };
    if (fuelTypeId) {
      payload.products[0].fuelTypeId = fuelTypeId;
    }

    const { data }: AxiosResponse<API_Order> = await this.#axiosInstance.post('v4/order', payload, {
      headers: {
        ...(initiatedByPartner && { 'X-Txn-Initiated-By-Partner': initiatedByPartner })
      }
    });

    this.id = data._id;

    this.productRequest = {
      pump: {
        id: pumpId,
        name: data.products[0].name
      }
    };

    if (fuelTypeId) {
      this.productRequest.fuel = {
        id: fuelTypeId
      };
    }

    if (price) {
      this.productRequest.price = price;
    }
  }

  private getOrderPrice(): API_Price {
    if (this.type === 'PREPAY') {
      if (!this.productRequest?.price) {
        throw new Error('no requested product for prepay-flow');
      }
      return this.productRequest.price;
    }

    if (!this.productFinished?.price) {
      throw new Error('no product data for postpay-flow');
    }
    
    return this.productFinished.price;
  }

  async authorize({
    successUrl,
    errorUrl,
    paypalCorrelationId,
    paymentToken,
    paymentMethodId,
    userDetails,
    initiatedByPartner,
    priceAfterDiscounts
  }: {
    successUrl?: string;
    errorUrl?: string;
    paypalCorrelationId?: string;
    paymentToken?: paymentToken;
    paymentMethodId?: string;
    userDetails?: UserDetails;
    initiatedByPartner: string;
    priceAfterDiscounts: {
      amount: string;
      precision: number;
      currency: string;
    };
  }): Promise<string | null> {

    const price = this.getOrderPrice();
    const amountFromOrder = {
      amount: Number(price.amount).toFixed(price.precision),
      precision: price.precision,
      currency: price.currency
    }
    const amountToAuthorize = this.type === 'PREPAY'
      ? amountFromOrder
      : priceAfterDiscounts || amountFromOrder
    
    if (!this.id || !price) {
      throw new Error('Missing order create data before authorize');
    }
    const payload: APIAuthorizePayload = {
      authorizePrice: amountToAuthorize,
      paymentToken: paymentToken,
      deviceData: paypalCorrelationId,
      userDetails
    };
    if (successUrl && errorUrl) {
      payload.userInteraction = {
        urls: {
          success: successUrl.replace('{ORDER_ID}', this.id),
          error: errorUrl.replace('{ORDER_ID}', this.id)
        }

      };
    }
    if (paymentMethodId) {
      payload.paymentMethodId = paymentMethodId;
    }

    const { data }: AxiosResponse<API_Order> = await this.#axiosInstance.post(`v4/order/${this.id}/authorize`, payload, {
      headers: {
        ...(initiatedByPartner && { 'X-Txn-Initiated-By-Partner': initiatedByPartner })
      }
    });

    if (data.userInteraction?.isRequired && !paymentToken && !paypalCorrelationId) {
      this.saveProductRequestToStorage(this.productRequest);
      return data.userInteraction.urls.start;
    }

    return null;
  }

  async acquire(): Promise<void> {
    await this.#axiosInstance.put(`v4/order/${this.id}/acquire`);
    if (this.type === 'POSTPAY') {
      await pWaitFor(() => this.isPostpayFinished(), {
        interval: refreshInterval
      });
    }
  }

  private stateAfterFuelling() {
    if (this.type === 'POSTPAY') {
      return this.status === 'PAYMENT_PENDING';
    }

    return this.isPossibleToDriveAway();
  }
  /**
    * @name isPossibleToDriveAway
    * @desc All orders have to reach the success screen in case the order has PRODUCT_ACQUIRE state.
    * We need to wait for READY_FOR_INVOICE state anyway to get the loyalty and vouchers
    * And in case the order has problem but the product is acquired we need to wait for the PROBLEM state
    * @returns {boolean}
    */
  private isPossibleToDriveAway() {
    const stateOrderProcessed = ['PRODUCT_ACQUIRED', 'READY_FOR_INVOICE', 'COMPLETED'];
    const stateOrderProcessedWithProblem = ['PRODUCT_ACQUIRED', 'PROBLEM'];

    const orderStatesArray = this.statusHistory.map(state => state['status']);
    
    const orderHasValidStates = (arr: string[], values: string[]) => {
      return values.every(value => {
        return arr.includes(value);
      });
    };
    return orderHasValidStates(orderStatesArray, stateOrderProcessed)
      || orderHasValidStates(orderStatesArray, stateOrderProcessedWithProblem);
  }
  private stateFuellingPending(state: API_Order['state']) {
    if (this.type === 'POSTPAY') {
      return state === 'CREATED';
    }
    // TODO: when refactor, the fuelling is NOT pending when reach PRODUCT_ACQUIRED
    // for now we redirect the user to the success page only if the order is in a final state (COMPLETED/PROBLEM)
    // This because of the send odometer feat. we need to keep polling until the COMPLETED state
    // With the current implementation we cannot keep polling until COMPLETED and redirect the user after PRODUCT_ACQUIRED
    // Everything will be better after the refactor. Cheers
    return ['PRODUCT_ACQUIRED', 'PRODUCT_PENDING', 'READY_FOR_INVOICE'].includes(state);
  }

  private generateVoucher(apiResponse: API_Order) {
    if (!apiResponse.vouchers?.length) {
      return null;
    }
    
    return apiResponse.vouchers.map(voucher => {
      
      const price = voucher.paymentAttributes?.totalPrice;

      return {
        id: voucher._id,
        name: voucher.paymentAttributes?.description,
        total: price && {
          amount: parseFloat(price.amount),
          precision: price.precision,
          currency: price.currency
        }
      };
    });
  }

  private setFinished(data: API_Order, orderIsCompleted: boolean) {

    const product = data.products.find(i => i.type === 'GAS');

    if (!product) {
      throw new Error('No Gas Item im Order-Products');
    }
    const paymentAttributes = product.attributes.paymentAttributes;

    this.productFinished = {
      pump: {
        id: product._id,
        name: product.name
      },
      fuel: {
        name: paymentAttributes.description
      },
      price: {
        amount: parseFloat(paymentAttributes.totalPrice.amount),
        precision: paymentAttributes.totalPrice.precision,
        currency: paymentAttributes.totalPrice.currency
      }
    };

    this.paymentResult = {
      unit: {
        amount: parseFloat(paymentAttributes.unitPrice.amount),
        precision: paymentAttributes.unitPrice.precision,
        currency: paymentAttributes.unitPrice.currency
      },
      total: {
        amount: parseFloat(paymentAttributes.totalPrice.amount),
        net: parseFloat(paymentAttributes.netPrice.amount),
        precision: paymentAttributes.totalPrice.precision,
        currency: paymentAttributes.totalPrice.currency
      },
      quantity: {
        amount: paymentAttributes.quantity,
        unit: paymentAttributes.unit
      },
      vouchers: orderIsCompleted ? this.generateVoucher(data) : null,
      loyaltyItem: data.loyaltyItems ? data.loyaltyItems[0] : null,
      totalAfterDiscounts: data.totalAfterDiscounts ?? null,
      totalBeforeDiscounts: data.totalBeforeDiscounts ?? null,
      totalDiscounts: data.totalDiscounts ?? null,
    };
  }

  private async isFuellingCompleted(): Promise<boolean> {
    if (this.cancel) throw (`ORDER_CANCELED`);
    const { data }: AxiosResponse<API_Order> = await this.#axiosInstance.get(`v4/order/${this.id}`);

    const product = data.products.find(i => i.type === 'GAS') || data.products[0];
    const paymentAttributes = product.attributes.paymentAttributes;
    const productStorage: Product = {
      pump: {
        id: product?._id,
        name: product?.name
      },
      fuel: {
        id: product?.attributes.selectedPumpFuelType?._id,
        name: paymentAttributes?.description
      },
      price: {
        amount: Number(paymentAttributes?.totalPrice.amount).toFixed(paymentAttributes?.totalPrice.precision),
        precision: paymentAttributes?.totalPrice.precision,
        currency: paymentAttributes?.totalPrice.currency
      }
    };

    if (this.stateAfterFuelling()) {
      
      this.saveProductRequestToStorage(productStorage);
      this.setFinished(data, true);
      return true;
    }
    if (this.stateFuellingPending(data.state)) {
      return false;
    }

    throw new Error(`State of the order changed unexpected to: ${data.state}`);
  }

  private async isPostpayFinished(): Promise<boolean> {
    const { data }: AxiosResponse<API_Order> = await this.#axiosInstance.get(`v4/order/${this.id}`);

    if (this.isPossibleToDriveAway()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.paymentResult!.vouchers = this.generateVoucher(data);
      this.paymentResult!.totalAfterDiscounts= data.totalAfterDiscounts ?? null
      this.paymentResult!.totalBeforeDiscounts= data.totalBeforeDiscounts ?? null
      this.paymentResult!.totalDiscounts= data.totalDiscounts ?? null
      this.paymentResult!.loyaltyItem = data.loyaltyItems ? data.loyaltyItems[0] : null;
      this.status = data.state;
      return true;
    }

    return false;
    // throw new Error(`State of the order changed unexpected to: ${data.state}`);
  }

  async fuel(): Promise<void> {
    await pWaitFor(() => this.isFuellingCompleted(), {
      interval: refreshInterval
    });
  }
  /**
   * @name resume
   * @param orderId 
   * @desc We are getting the order detail.
   * If is PREPAY we invoke this function in fueling page because maybe we got redirected to external site (mpgs authorize action) 
   * and we need to resume the order with the data stored in the localStorage
   * 
   * If it's POSTPAY we invoke this in finish page so the order should be already completed
   */
  async resume({ orderId }: { orderId: string; }): Promise<void> {
    const { data }: AxiosResponse<API_Order> = await this.#axiosInstance.get(`v4/order/${orderId}`);

    this.type = data.type === 'MANUAL' ? 'POSTPAY' : 'PREPAY';
    this.id = data._id;

    // Type of the order is postpay than it's already finished
    const validHistoryStates = data.stateHistory.some(item =>
      ['COMPLETED', 'READY_FOR_INVOICE', 'PRODUCT_ACQUIRED'].includes(item.state)
    );
    if (this.type === 'POSTPAY' || validHistoryStates) {
      this.setFinished(data, validHistoryStates);
      return;
    }
    this.loadProductRequestFromStorage();
  }
}