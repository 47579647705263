<template>
  <div class="loading-spinner flex justify-center">
    <img class="loading-spinner__image" src="@/assets/loading.png" width="60" height="60" alt="" />
  </div>
</template>

<style lang="scss" scoped>
.loading-spinner {
  &__image {
    animation: rotation 0.8s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
