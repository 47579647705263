<template>
  <div class="flex items-center w-full">
    <div class="w-11  flex flex-col items-center pr-3 pt-1">
      <RPIcon :type="`history/${categoryIcon}`" noFill />
    </div>
    <div 
      class="pl-3 flex flex-col w-full border-l"
      :class="isUpcoming ? 'border-secondary-btn-bg' : 'border-card-outline'"
    >
      <div
        class="border-b pb-2 w-full"
        :class="isUpcoming ? 'border-secondary-btn-bg' : 'border-card-outline'"
      >
        <p class="font-bold text-fs-14">
          {{ event.information?.name || $t(`history.details.cost.category.${categoryText}`) }}
        </p>
      </div>
      <div class="flex items-center  justify-between pt-2 w-full">
        <span class="rp-fs-16-ln font-bold text-tertiary-text">
          {{ formatDate.weekday }} {{ formatDate.date }} - {{ formatDate.time }}
        </span>
        <span class="rp-fs-16-ln font-bold">
          {{ currencyStringHistory(event.information?.totalPrice.amount, event.information?.totalPrice.currency) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

import { dateStringHistory, currencyStringHistory } from '@/utils//utils';
import { getCategoryIconUtils, getCategoryTextUtils } from '@/store/modules/history/historyUtils';
import { HistoryCostEvent } from '@/store/modules/history/types';
import { PropType, computed } from 'vue';
const props = defineProps({
  event: {
    type: Object as PropType<HistoryCostEvent>,
    required: true
  },
  isUpcoming: {
    type: Boolean,
    required: true
  }
});
const categoryIcon = computed(() =>
  getCategoryIconUtils(props.event.information.category));
const categoryText = computed(() =>
  getCategoryTextUtils(props.event.information.category));
const formatDate = computed(() => {
  const startDate = new Date(props.event.sampleTime);
  return dateStringHistory(startDate);
});
</script>