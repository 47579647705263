<template>
  <div class="finish px-6 view-block" :class="{ landscape: isLandscape }">
    <div v-if="userCanDriveAway && componentIsReady">
      <OrderSummary status-for-timestamp="PRODUCT_ACQUIRED" />
      <Divider class="mb-2" />
      <div class="flex justify-between items-center text-fs-title font-bold">
        <p class="text-tertiary-text">
          {{ $t('fueling.finish.table.total') }}
        </p>
        <p>{{ total }}</p>
      </div>
    </div>
    <Loadingspinner v-else class="" />

    <footer
      class="finish__footer mt-auto"
      :class="{ 'sm:bottom-[var(--footerMinHeight)] bottom-[var(--footerMaxHeight)]': isLandscape }"
    >
      <div class="finish__footer-container">
        <div>
          <h3
            class="
              mb-4
              l-scape:text-left
              l-scape:my-0
              l-scape:mr-0
              l-scape:ml-[var(--space-md)]
              l-scape:text-[var(--fontSizeCaption)]
            "
            data-cy="paymentCompleted"
          >
            {{ $t('fueling.finish.title') }}
          </h3>
          <small
            class="block mb-6 l-scape:ml-[var(--space-md)] l-scape:font-normal l-scape:mb-0"
            v-html="$t('fueling.finish.text')"
          />
        </div>
        <div v-if="!callback && hideMap && isGuest" class="inline">
          {{ $t('fueling.summary.text.or') }}
          <RPButton link class="inline" href="/register" data-cy="checkoutRegisterButton">
            {{ $t('fueling.summary.text.register') }}
          </RPButton>
        </div>
        <div v-else-if="!callback && hideMap" class="inline">
          {{ $t('fueling.summary.text.or') }}
          <RPButton link class="inline" href="/register" data-cy="checkoutRegisterButton">
            {{ $t('fueling.summary.text.profile') }}
          </RPButton>
        </div>
        <RPButton v-else primary @click="callFinish()">
          {{ $t('fueling.finish.button_ok') }}
        </RPButton>
      </div>
    </footer>
  </div>
</template>
<script setup lang="ts">
import Divider from '@/components/Atoms/Divider.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import OrderSummary from '@/components/Layout/OrderSummary.vue';
import Loadingspinner from '@/components/Atoms/Loadingspinner.vue';

import { redirectToCallbackUrl, resetOrderURLparams } from '@/utils/utils.js';

import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed, onBeforeMount, ref, inject, watch, onMounted } from 'vue';
import { isEmpty, omit } from 'lodash';
import { patchOdometer } from '@/api/order.js';

const matomo: any = inject('Matomo');

const store = useStore();
const route = useRoute();
const router = useRouter();

// vars for template only
const isLandscape = computed(() => store.state.ui.query.landscape);
const hideMap = computed(() => store.state.ui.query.hideRydMapLink);
const isGuest = computed(() => store.state.auth.isGuest);
const userCanDriveAway = computed(() =>
  ['READY_FOR_INVOICE', 'COMPLETED', 'PROBLEM'].includes(orderStatus.value));


const orderStatus = computed(() => store.state.pay.orderStatus.status);
const odometerScreenData = computed(() => store.state.auth.odometerScreenData);
const paymentResult = computed(() => store.state.pay.orderStatus.paymentResult);
const paramsToKeep = computed(() => resetOrderURLparams(route.query));
const componentIsReady = ref(false);

onMounted(async () => {
  if (!route.query.orderId) return router.replace({ path: '/pay' });

  if (localStorage.getItem('odometer') === 'false') {
    localStorage.removeItem('odometer');
  } else {
    sendOdometer();
  }

  try {
    // Resume the order if is POSTPAY only, because we already resume the order in PREPAY flow on 5_fueling page
    if (route.query.orderId && route.query.orderType === 'POSTPAY') {
      await Promise.all([store.dispatch('pay/resume', route.query.orderId)]);

      const pumpId = store.state.pay.orderStatus.productRequest?.pump.id;
      if (pumpId) {
        store.commit('station/saveURLParamsForTrackOrder', { pumpId });
      }
      const fuelTypeId = store.state.pay.orderStatus.productRequest?.fuel.id;
      if (fuelTypeId) {
        store.commit('station/saveURLParamsForTrackOrder', { fuelTypeId });
      }
    }

    await store.dispatch('station/getOnce');

    // If there's an orderId in URL and the vuex is empty, the user refreshed the page
    // so we need to retrieve the order details from API
    if (route.query.orderId && isEmpty(paymentResult.value)) {
      await store.dispatch('pay/getOrderDetail', route.query.orderId);
      componentIsReady.value = true;
    }

    trackOrder();

    // we remove the orderType from URL so we do not step into the other cases above
    // without the orderType in the URL we know that user refreshes the page and is not coming from previous steps
    componentIsReady.value = true;
    route.query.orderType &&
      router.replace({
        path: route.path,
        query: {
          ...omit(route.query, ['orderType'])
        }
      });
  } catch (error) {
    console.log('resume order error', error);
    router.push({
      path: '/error',
      query: { ...paramsToKeep.value, type: 'payment' }
    });
  }
});

// send the odometer
const orderHasFinalState = computed(() =>
  ['COMPLETED', 'PROBLEM'].includes(orderStatus.value));
const sendOdometer = () => {
  if (route.query.orderType && odometerScreenData.value && orderHasFinalState.value) {
    const odometerStored = Number(localStorage.getItem('odometer'));
    // We just trigger the API, we don't wait for the resolve or reject because we do not want to break the flow
    // plus we don't have any visual errors to show to the user
    patchOdometer(route.query.orderId, odometerStored);
    localStorage.removeItem('odometer');
  }
};
watch(orderStatus, (newVal) => {
  // we need to watch the order state because in POSTPAY this is the page where the polling happen
  // PREPAY land here with already a final state and we trigger it in the mounted
  const orderFinalStates = ['COMPLETED', 'PROBLEM'];
  if (orderFinalStates.includes(newVal)) {
    sendOdometer();
  }

});

onBeforeMount(() => {
  // We reset the temporary PM selected by user in the checkout
  store.dispatch('psp/resetTemporaryPM');
  // reset of order track params
  store.commit('psp/setClearRegisterQuery');
  store.commit('station/resetUIState');
});
const trackOrder = () => {
  try {
    const orderId = store.state.pay.orderStatus.id;
    const orderResult = store.state.pay.orderStatus;

    const revenue = orderResult.paymentResult.total.amount;
    const tax = revenue - orderResult.paymentResult.total.net;
    const checkoutMethod = store.getters['psp/checkoutMethod'];
    const paymentMethod = `${checkoutMethod.provider}/${checkoutMethod.method.toLowerCase()}`;
    const fuelName = orderResult.productFinished.fuel.name;
    matomo.addEcommerceItem(
      localStorage.getItem('partner') || 'no_partner',
      paymentMethod,
      fuelName,
      revenue
    );

    matomo.trackEcommerceOrder(orderId, revenue, undefined, tax);
  } catch (e) {
    console.debug(e);
  }
};

const total = computed(() => store.getters['pay/total']);
const callback = computed(() => store.state.ui.query.callback);
const redirectCallBack = () => {
  const callbackParam = callback.value;
  const callbackPayloadToAppend = {
    amount: paymentResult.value.quantity.amount,
    price: paymentResult.value.unit.amount,
    total: paymentResult.value.total.amount,
    stationId: store.state.ui.query.pid
  };
  redirectToCallbackUrl(callbackParam, callbackPayloadToAppend);
};
const callFinish = () => {
  if (callback.value) {
    return redirectCallBack();
  }

  router.push({
    path: '/pay',
    query: paramsToKeep.value
  });
};
</script>
<style lang="scss" scoped>
.finish.landscape {
  max-width: var(--appMaxWidthContainerLandscape);
  .finish {
    &__footer {
      position: absolute;
      left: 0;
      width: 100vw;
      background-color: var(--colorPrimaryBgDarker);
      padding: 16px 0 10px;
    }

    &__footer-container {
      max-width: var(--appMaxWidth);
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      text-align: left;
    }
  }
}
</style>
