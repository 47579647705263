<template>
  <div class="mt-4">
    <RPButton 
      v-if="amazonReady"
      theme="custom" 
      type="button"
      class="relative text-black bg-white"
      @click="triggerAmazonButton()"
    >
      {{ $t('signIn.sign_in_amazon') }}  
      <RPIcon type="amazon/amazon-sso" noFill class="h-8 mt-1" />
    </RPButton>
    <div id="AmazonSSOButton" class="hidden"></div>
  </div>
</template>
  
<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

import { stringMapper } from '@/i18n';
import { loadScript } from '@/utils/externalScriptLoader';
import { computed, nextTick, onMounted, ref } from 'vue';
import { amazonSSOSignature } from '@/api/payments';
import { useI18n } from 'vue-i18n';
import { omit } from 'lodash';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const { locale } = useI18n();
const store = useStore();
const route = useRoute();
const orderStepsStored = computed(() => omit(store.state.station.ui, ['orderId']));

interface AmazonPay {
  Pay: any;
}

interface WindowWithAmazon extends Window {
  amazon?: AmazonPay;
}

const amazon: WindowWithAmazon = window;

const props = defineProps({
  actionType: {
    type: String,
    required: true
  }
});
const amazonReady = ref(false);
const triggerAmazonButton = () => {
  document.getElementById('AmazonSSOButton').click();
};
onMounted(async () => {
  await loadScript('https://static-eu.payments-amazon.com/checkout.js');
  await nextTick();
  if (amazon.amazon) {
    setTimeout(initAmazonSSO, 100);
  }
});

const localeNormalized = () => {
  const findLocale = stringMapper[locale.value];
  const replaceUnderscore = findLocale.replace('-', '_');
  return replaceUnderscore === 'en_US' ? 'en_GB' : replaceUnderscore;
};

const buildUrls = (query) => {

  const paramsToString = new URLSearchParams(query).toString();
  const paramsToAppend = paramsToString ? `&${paramsToString}&actionType=${props.actionType}` : `&actionType=${props.actionType}`;

  const successRoute = 'amazonSSORedirect';
  const cancelRoute = 'amazonSSORedirect';

  const successUrl = `${import.meta.env.VUE_APP_PSP_REDIRECT_URL}?sca_feedback=${encodeURIComponent(
    successRoute
  )}${paramsToAppend}`;


  const cancelUrl = `${import.meta.env.VUE_APP_PSP_REDIRECT_URL}?sca_feedback=${encodeURIComponent(
    cancelRoute
  )}&amazonIsCancel=true${paramsToAppend}`;

  return {
    success: successUrl,
    cancel: cancelUrl
  };
};

const getSignature = async (query) => {
  const { success, cancel } = buildUrls(query);
  return await amazonSSOSignature(success, cancel);
};

const initAmazonSSO = async () => {
  // https://developer.amazon.com/docs/amazon-pay-checkout/amazon-sign-in.html
  const paramsToKeep =
    orderStepsStored.value.orderType === 'PREPAY'
      ? { ...route.query, ...orderStepsStored.value }
      : route.query;

  // Need a try catch for Cypress "unhandled exception"
  // since Cypress switch too fast on register page and the Amazon button couldn't be loaded
  try {
    const { signature, payload } = await getSignature(paramsToKeep);
    amazonReady.value = true;
    return amazon.amazon.Pay.renderButton('#AmazonSSOButton', {
      merchantId: 'A236O3KCLVYNU4',
      publicKeyId: import.meta.env.VUE_APP_AMAZON_PUBLIC,
      ledgerCurrency: 'EUR',
      checkoutLanguage: localeNormalized(),
      placement: 'Checkout',
      buttonColor: 'LightGray',
      productType: 'SignIn',
      signInConfig: {
        payloadJSON: payload,
        signature
      }
    });
  } catch (error) {
    console.log(error);
  }
}

</script>
<style lang="scss">
#AmazonSSOButton{
  border-radius: 50px;
  overflow: hidden;
  margin: 0 auto;
  height: 40px !important;
  width: 100% !important;
}
</style>