<template>
  <div class="
    min-w-[135px] h-8
    rounded-full bg-primary-background
    rp-fs-14-ln font-medium
    absolute top-[52px] left-1/2 z-20 -translate-x-1/2
    flex justify-center items-center py-2"
  >
    <RPCarIcon
      :avatar="authState.selectedVehicle.avatarImageName"
      :color="authState.selectedVehicle.avatarColor"
      class="w-6 mr-2 h-fit"
    />
    {{ authState.selectedVehicle.nickName }}
  </div>
  <RPMap 
    v-if="event.timeliness.timeline.length" 
    :timeline="event.timeliness.timeline" :filter="activeEventFilter"
  />
  <TripSummary 
    :event="event"
    class="flex items-center py-6 px-4" 
  />
  <div class="pt-6 px-4 bg-primary-darker flex-1 w-full -mb-12">
    <div class="flex justify-between pb-4 border-b border-card-outline">
      <span class="font-bold rp-fs-16-ln">{{ $t('history.details.events') }}</span>
      <button class="text-primary-accent" @click="activeEventFilter = 'all'">
        {{ $t('history.details.show_all') }}
      </button>
    </div>
    <div class="flex items-center border-b border-card-outline">
      <RPButton 
        :class="{ 'bg-primary-background': activeEventFilter === TRIP_EVENTS_MAP.ACCELERATION }" 
        theme="custom" 
        class="basis-1/2 h-16 px-2 justify-start rounded-none"
        @click="activeEventFilter = TRIP_EVENTS_MAP.ACCELERATION"
      >
        <RPIcon 
          type="history/acc" 
          svgFill="text" 
          :class="{ 'active': activeEventFilter === TRIP_EVENTS_MAP.ACCELERATION }"
        />
        <div class="pl-2 flex flex-col text-left">
          <span class="rp-fs-16-ln">{{ $t('history.details.acceleration') }}</span>
          <span :class="{ 'text-tertiary-base': drivingEvents?.acceleration.count }" class="font-normal">
            {{ drivingEvents?.acceleration.count ?? 0 }} {{ $t('history.details.noted_event') }}
          </span>
        </div>
      </RPButton>
      <RPButton 
        theme="custom" 
        class="basis-1/2 h-16 px-2 justify-start rounded-none"
        :class="{ 'bg-primary-background': activeEventFilter === TRIP_EVENTS_MAP.BRAKING }"
        @click="activeEventFilter = TRIP_EVENTS_MAP.BRAKING"
      >
        <RPIcon
          type="history/braking" 
          svgFill="text" 
          :class="{ 'rotate-180 transition-all duration-700': activeEventFilter === TRIP_EVENTS_MAP.BRAKING }"
        />
        <div class="pl-2 flex flex-col text-left">
          <span class=" font-medium rp-fs-16-ln">{{ $t('history.details.braking') }}</span>
          <span :class="{ 'text-tertiary-base': drivingEvents?.braking.count }" class="font-normal">
            {{ drivingEvents?.braking.count ?? 0 }} {{ $t('history.details.noted_event') }}
          </span>
        </div>
      </RPButton>
    </div>
    <div class="flex items-center justify-start border-b border-card-outline">
      <RPButton 
        theme="custom" 
        class="h-16 px-2 ml-0 justify-start w-1/2 rounded-none"
        :class="{ 'bg-primary-background': activeEventFilter === TRIP_EVENTS_MAP.CORNERING }"
        @click="activeEventFilter = TRIP_EVENTS_MAP.CORNERING"
      >
        <RPIcon 
          type="history/steering" 
          svgFill="text" 
          class="corner-icon-anim"
          :class="{ 'active': activeEventFilter === TRIP_EVENTS_MAP.CORNERING }"
        />
        <div class="pl-2 flex flex-col text-left">
          <span class=" font-medium rp-fs-16-ln">{{ $t('history.details.steering') }}</span>
          <span :class="{ 'text-tertiary-base': drivingEvents?.cornering?.hardCornersCount }" class="font-normal">
            {{ drivingEvents?.cornering?.hardCornersCount ?? 0 }} {{ $t('history.details.noted_event') }}
          </span>
        </div>
      </RPButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import TripSummary from '@/components/TripSummary/TripSummary.vue';
import RPCarIcon from '@/components/CarsSVGComponents/RPCarIcon.vue';
import RPMap from './RPMap.vue';

import { HistoryTripEventDetail } from '@/store/modules/history/types';
import { useStore } from 'vuex';
import { TRIP_EVENTS_MAP } from '@/store/modules/history/historyUtils';
import { computed, ref, PropType } from 'vue';

const props = defineProps({
  event: {
    type: Object as PropType<HistoryTripEventDetail>,
    required: true
  }
});
const activeEventFilter = ref('all');
const drivingEvents = computed(() => props.event!.drivingScore);

const store = useStore();
const authState = store.state.auth;

</script>

<style lang="scss">
.acc-icon-anim {
  transform-box: fill-box;
  transform-origin: bottom left;
}

.active .acc-icon-anim {
  animation:
   accAnim ease-in-out 2s 1 normal forwards;
}

@keyframes accAnim {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-95deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.corner-icon-anim {
  transform-box: fill-box;
  transform-origin: center;
}

.corner-icon-anim.active {
  animation:
   cornerAnim ease-in-out 2s 1 normal forwards;
}

@keyframes cornerAnim {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-45deg);
  }
  60% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
</style>