import axios from './index';

/**
 * @name createThirdPartyForm
 * @description Creates a link to the payment form which is hosted by a payment provider.
 * @param {String} provider needed to build the endpoint [mpgs, concardis, sibis..]
 * @param {String} method has to be included in the URL params [DIRECT_CARD, GOOGLE, MBWAY..]
 * @param {String} successURL redirect endpoint in case of success
 * @param {String} errorURL redirect endpoint in case of error
 * @param {String} query additional query params
 * @returns {Object} {data:
 *                      error: '',
 *                      subscription: '' this will contain the external URL of the provider (actually, we use this only for credit cards),
 *                      success: ''
 *                    }
 * @see Doc https://tt4.thinxcloud-dev.de/doc/#api-Payments-paymentsProviderUrl
 */
export async function createThirdPartyForm(provider, method, successURL, errorURL, query = {}) {
  const paramsObject = new URLSearchParams('');

  paramsObject.set('method', method);
  paramsObject.set('provider', provider);
  paramsObject.set('redirect[success]', successURL);
  paramsObject.set('redirect[error]', errorURL);

  //add query params
  Object.keys(query).forEach(key => paramsObject.set(key, query[key]));
  //convert params object to query string (example: "param1=value1&param2=value2")
  const queryString = paramsObject.toString();

  const { data } = await axios.get(`${import.meta.env.VUE_APP_API_URL}/payments/${provider}/urls?${queryString}`);
  return data.data;
}

/**
 * @name handleThirdPartyGateway
 * @description Handles the incoming data from the foreign payment provider. This method returns 204 in case of success.
 * @param {String} provider needed to build the endpoint [mpgs, concardis, sibis..]
 * @param {String} query additional query params.
 *                       We always pass replacePreferred=false for MPM
 *                       In case is the first PM added the Backend will manage to set it as preferred
 * @see Doc https://tt4.thinxcloud-dev.de/doc/#api-Payments-paymentsProviderUrl
 */
export async function handleThirdPartyGateway(provider, query) {
  const paramsObject = new URLSearchParams('');
  Object.keys(query).forEach(key => paramsObject.set(key, query[key]));
  paramsObject.set('replacePreferred', false);

  const queryString = paramsObject.toString();

  const paymentUrl = `${import.meta.env.VUE_APP_API_URL}/payments/${provider}/gateway?${queryString}`;

  await axios.head(paymentUrl);
}

export async function deletePayment() {
  await axios.put(`${import.meta.env.VUE_APP_API_URL}/payments/cancel`);
}

export async function isPaymentMethodsLimitExceeded() {
  const { data } = await axios.get('v4/payment-methods/is-payment-methods-limit-exceeded');

  return data.result;
}

export const amazonCreateSignature = async (currency, amount, success, cancel) => {
  const { data } = await axios({
    method: 'post',
    url: 'v4/amazon-pay/session-config',
    data: {
      authorizeAmount: {
        currencyCode: currency,
        amount: String(amount)
      },
      webCheckoutDetails: {
        checkoutResultReturnUrl: success,
        checkoutCancelUrl: cancel
      }
    }
  });
  return data;
};

export const amazonSSOSignature = async (success, cancel) => {
  const { data } = await axios({
    method: 'post',
    url: 'auth/amazon-pay/button-config',
    data: {
      "signInReturnUrl": success,
      "signInCancelUrl": cancel,
    }
  });
  return data;
};
