<template>
  <div class="flex items-center w-full">
    <div class="w-11  flex flex-col items-center pr-3 pt-1">
      <DistanceIcon class="h-14" :drivingScoreColor="isDrivingScoreGood ? 'DEFAULT' : 'YELLOW'" />
      <span class="pt-2">
        {{ (event.distanceM / 1000).toFixed(1) }}km
      </span>
    </div>
    <div class="pl-3 flex flex-col w-full border-l border-card-outline">
      <div class="border-b border-card-outline pb-2">
        <span class="font-medium">
          {{ event.information?.start.city }}
        </span>
        <span class="rp-fs-12-ln pl-2">
          {{ event.information?.start.street }} {{ event.information?.start.houseNumber }}
        </span>
      </div>
      <div class="border-b border-card-outline py-2">
        <span class="font-medium">
          {{ event.information?.end.city }}
        </span>
        <span class="rp-fs-12-ln pl-2">
          {{ event.information?.end.street }} {{ event.information?.end.houseNumber }}
        </span>
      </div>
      <div class="flex items-center justify-between pt-2">
        <span class="rp-fs-16-ln font-bold text-tertiary-text">
          {{ formatDate.weekday }} {{ formatDate.date }} - {{ formatDate.time }}
        </span>
        <div>
          <span v-if="totalTime.hours">{{ totalTime.hours }}h </span>
          <span v-if="totalTime.minutes" class="ml-auto mr-0">
            {{ totalTime.minutes }}{{ $t('history.minutes_short') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DistanceIcon from './DistanceIcon.vue';

import { dateStringHistory } from '@/utils//utils';

import { HistoryTripEvent } from '@/store/modules/history/types';
import { PropType, computed } from 'vue';
const props = defineProps({
  event: {
    type: Object as PropType<HistoryTripEvent>,
    required: true
  },
});
const formatDate = computed(() => {
  const startDate = new Date(props.event.information.start.timestamp);
  return dateStringHistory(startDate);
});

const totalTime = computed(() => {
  const startDate = new Date(props.event.information.start.timestamp).getTime();
  const endDate = new Date(props.event.information.end.timestamp).getTime();
  const difference = endDate - startDate;
  const hours = Math.floor(difference / 3600000);
  const minutes = Math.floor((difference % 3600000) / 60000);

  return { hours, minutes };
});

const isDrivingScoreGood = computed(() =>
  !props.event.drivingScore?.acceleration.count &&
  !props.event.drivingScore?.braking.count &&
  (!props.event.drivingScore?.cornering?.hardCornersCount ?? false)
);
</script>