<template>
  <Teleport to="#topBarIconLeft">
    <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" @click="backButton">
      <RPIcon svg-fill="text" type="arrowLeft" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span>{{ showOdometer ? odometerScreenData.title : $t('menu.paySummary') }}</span>
  </Teleport>
  <Odometer
    v-if="showOdometer"
    :description="odometerScreenData.body"
    @close="additionalScreenClosed = true"
  />
  <template v-else-if="componentReady">
    <PaymentValidationMethod v-if="pinShow" :on-finish="startFueling" />
    <div
      v-else
      class="view-block px-6 prepay-summary l-scape:max-w-[var(--appMaxWidthContainerLandscape)] l-scape:mx-auto"
    >
      <div>
        <div class="font-medium">
          <div v-if="fuelName" class="flex justify-between items-center mb-2">
            <span class="text-tertiary-text">{{ $t('fueling.summary.table.fuelName') }}</span>
            <span>{{ fuelName }}</span>
          </div>
          <div class="flex justify-between items-center mb-2">
            <span class="text-tertiary-text">{{ $t('fueling.summary.table.pump') }}</span>
            <span>{{ pump.name }}</span>
          </div>
          <template v-if="voucherPreview && voucherPreviewReady">
            <Divider v-if="voucherPreview.discounts.length > 0" class="mt-2 mb-2" />
            <div v-for="voucher in voucherPreview.discounts" :key="voucher.name" class="flex justify-between items-center mb-2">
              <span class="text-tertiary-text text-left truncate min-w-[50%] pr-2">{{ voucher.name }}</span>
              <span class="text-tertiary-base text-right truncate">{{ voucher.label }}</span>
            </div>
          </template>
          <Loadingspinner v-else-if="!voucherPreviewReady" class="h-5 w-5 p-1" />

          <Divider class="mt-4 mb-2" />
          <div class="flex justify-between items-center mb-2 text-fs-18 l-scape:text-fs-32 font-bold">
            <span class="text-tertiary-text">
              {{ $t('fueling.summary.table.amount') }}
            </span>
            <span>{{ amountWithSymbol }}</span>
          </div>
        </div>
        <Divider class="mt-2 mb-4" />
      </div>
      <div>
        <!-- Loyalty card -->
        <SummaryLoyalty v-if="!props.state.matches('guest')" />

        <!-- Payment Methods -->
        <SummaryPayment
          :state="props.state"
          :isDevicePayment="isWalletPayment"
          :buttonText="$t('fueling.summary.buttons.start')"
          @startPay="createOrder"
        />

        <div v-if="['user.allowed'].some(props.state.matches)" class="mb-4">
          <AmazonPayButton v-if="paymentIsAmazonPay" :amount="Number(amount)" :currency="pumpCurrency" />
          <ApplePayButton v-if="paymentIsApplePay" @click="startFuelingApplePay" />
          <GooglePayButton v-else-if="paymentIsGpay" :on-click-event="startFuelingGpay" />
        </div>
        <div v-if="['guest.allowed'].some(props.state.matches)" class="mb-4">
          <ApplePayButton v-if="paymentIsApplePay" @click="startFuelingApplePay" />
          <GooglePayButton v-else-if="paymentIsGpay" :on-click-event="startFuelingGpay" />
          <div class="inline pt-4">
            {{ $t('fueling.summary.text.or') }}
            <RPButton link class="inline" data-cy="checkoutRegisterButton" @click="authRequireRedirect('/register')">
              {{ $t('fueling.summary.text.registerOrLogin') }}
            </RPButton>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import Divider from '@/components/Atoms/Divider.vue';
import PaymentValidationMethod from '@/components/Layout/PaymentValidationMethod.vue';
import ApplePayButton from '@/components/Atoms/ApplePayButton.vue';
import GooglePayButton from '@/components/Atoms/GooglePayButton.vue';
import AmazonPayButton from '@/components/Atoms/AmazonPayButton.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import SummaryLoyalty from '../components/SummaryLoyalty.vue';
import SummaryPayment from '../components/SummaryPayment.vue';
import Loadingspinner from '@/components/Atoms/Loadingspinner.vue';
import Odometer from '../components/Odometer.vue';

import { usePayments } from '@/composable/usePayments';
import { currencyString, resetAllURLparams } from '@/utils/utils.js';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, onBeforeMount, onBeforeUnmount, ref, inject, watch, onMounted } from 'vue';
import { omit } from 'lodash';
const props = defineProps({
  state: {
    type: Object,
    required: true
  }
});

const store = useStore();
const route = useRoute();
const router = useRouter();
const matomo: any = inject('Matomo');

const componentReady = ref(false);

const {
  isWalletPayment,
  paymentIsGpay,
  paymentIsApplePay,
  paymentIsAmazonPay,
  paymentShouldSkipValidation,
  getAmazonPaymentId,
  getAmazonPayment
} = usePayments();

onBeforeMount(async () => {
  if (route.query.orderType) {
    store.commit('station/saveURLParamsForTrackOrder', route.query);
    store.dispatch('ui/saveURLParamsForUi');
  }
  const isPumpChosen = store.getters['station/isPumpChoosen'];
  if (!isPumpChosen) return router.push({ path: '/pay' });

  componentReady.value = true;

  // if returning from amazon success
  if (route.query.amazonCheckoutSessionId) {
    // Avoid to see the odometer when returning from Amazon Pay
    additionalScreenClosed.value = true;

    store.dispatch('psp/setTemporaryPM', getAmazonPayment.value);
    store.commit('pay/setAmazonPayPayment', route.query.amazonCheckoutSessionId);
    createOrder();
  }
  // if returning from amazon cancel
  if (route.query.amazonIsCancel) {
    // Avoid to see the odometer when returning from Amazon Pay
    additionalScreenClosed.value = true;

    store.dispatch('psp/setTemporaryPM', getAmazonPayment.value);
    router.replace({ path: route.path, query: { ...omit(route.query, ['amazonIsCancel']) } });
  }
});

const pinShow = ref(false);

// Store state
const savedParamsTrackOrder = computed(() => store.state.station.ui);
const amount = computed(() => store.state.station.ui.amount);
const voucherPreview = computed(() => store.state.vouchers.voucherPreview);

// Store Getters
const pump = computed(() => store.getters['station/chosenPump']);
const fuel = computed(() => store.getters['station/chosenFuel']);
const payFlowPages = computed(() => store.getters['pay/payFlowPages']);
const paymentMethod = computed(() => store.getters['psp/checkoutMethod']);

const pumpCurrency = computed(() => pump.value.priceType.currency);

// Additional screen
const odometerInStorage = localStorage.getItem('odometer');
const hasRefusedOdometerInput = odometerInStorage && odometerInStorage === 'false';
const odometerScreenData = computed(() => store.state.auth.odometerScreenData);
const additionalScreenClosed = ref(false);
const showOdometer = computed(() =>
  odometerScreenData.value
  && !additionalScreenClosed.value
  && !hasRefusedOdometerInput);

// vouchers preview
const voucherPreviewReady = ref(true);
const getVoucherPreview = async () => {

  const payload = {
    total: {
      amount: `${amount.value}`,
      currency: pumpCurrency.value,
      precision: pump.value.priceType.precision
    },
    poiId: route.query.pid,
    paymentMethodId: paymentMethod.value?.id,
    products: [{
      id: pump.value.id,
      ...(fuel.value?.isRealTimePrice && {
        unitPrice: {
          amount: `${fuel.value.amount}`,
          currency: `${fuel.value.currency}`,
          precision: fuel.value.precision
        }
      })
    }]
  };
  try {
    voucherPreviewReady.value = false;
    await store.dispatch('vouchers/getVoucherPreview', { ...payload });
  } catch (e) {
    console.log(e);
  } finally {
    voucherPreviewReady.value = true;
  }
};
onMounted(async () => {
  !props.state.matches('guest') && await getVoucherPreview();
});
watch(
  () => paymentMethod.value?.id,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      getVoucherPreview();
    }
  },
  { deep: true }
);

const fuelName = computed(() => {
  const fuelTypeId = savedParamsTrackOrder.value.fuelTypeId;
  if (!fuelTypeId) {
    return null;
  }

  return pump.value && pump.value.fuelTypes.find(i => i.id === fuelTypeId).name;
});

const amountWithSymbol = computed(() => {
  return pumpCurrency.value && currencyString(amount.value, pumpCurrency.value);
});

//back button manage
const backButton = () => {
  if (pinShow.value) return (pinShow.value = false);
  const originalParams = resetAllURLparams(route.query);
  localStorage.removeItem('odometer');
  return router.push({ path: payFlowPages.value.previousPagePath, query: originalParams });
};

// STart fueling
const createOrder = async () => {
  try {
    await store.dispatch('pay/createOrder');
    if (!paymentShouldSkipValidation.value) {
      pinShow.value = true;
    } else {
      startFueling();
    }
  } catch (e) {
    console.error(e);
    router.replace('/error');
  }
};

const startFueling = async () => {
  pinShow.value = false;
  try {
    let authorizeLink = null;

    if (route.query.amazonCheckoutSessionId) {
      await store.dispatch('pay/authorizeAmazon', {
        paymentMethod: 'AMAZON_PAY',
        paymentId: getAmazonPaymentId
      });
    } else {
      authorizeLink = await store.dispatch('pay/startPay');
    }

    if (authorizeLink) {
      return window.location.assign(authorizeLink);
    }

    await store.dispatch('pay/acquire');

    const nextPagePath = payFlowPages.value.nextPagePath;
    const paramToKeep = resetAllURLparams(route.query);
    router.replace({ path: nextPagePath, query: paramToKeep });
  } catch (e) {
    console.error(e);

    const userClosedGooglePaymentRequest =
      paymentIsGpay.value && e.statusCode === 'CANCELED';
    if (userClosedGooglePaymentRequest) {
      matomo.trackEvent('Order', 'Google Pay closed', store.state.pay.orderStatus.id);
      return;
    }

    const userClosedAppleRequest = paymentIsApplePay.value && e.type === 'cancel';
    if (userClosedAppleRequest) {
      matomo.trackEvent('Order', 'Apple pay closed', store.state.pay.orderStatus.id);
      return;
    }

    matomo.trackEvent('Order', 'Failed', store.state.pay.orderStatus.id);
    router.replace({ path: '/error', query: { ...route.query, type: 'payment' } });
  }
};
const startFuelingApplePay = async () => {
  try {
    await store.dispatch('pay/createApplePaySession');
    await store.dispatch('pay/createOrder');
    await startFueling();
  } catch (e) {
    console.error(e);
    matomo.trackEvent('Order', 'Apple pay session failed', store.state.pay.orderStatus.id);
  }
};
const startFuelingGpay = async () => {
  try {
    await store.dispatch('pay/createOrder');
    await startFueling();
  } catch (e) {
    console.error(e);
    matomo.trackEvent('Order', 'Google pay session failed', store.state.pay.orderStatus.id);
  }
};
const authRequireRedirect = (path: string) => {
  const { amount, fuelTypeId, pumpId, orderType } = savedParamsTrackOrder.value;
  const paramsToTrackOrderPrepay = { amount, fuelTypeId, pumpId, orderType };

  router.push({
    path,
    query: {
      ...route.query,
      pumpId,
      ...(orderType === 'PREPAY' && paramsToTrackOrderPrepay)
    }
  });
};


</script>

