<template>
  <Field v-slot="{ errors, field }" :name="(id as string)" :rules="rules" :label="label" :model-value="modelValue"
    as="div" class="font-medium" :style="[type === 'password' ? { position: 'relative' } : '']"
    @input="emits('update:modelValue', $event.target.value)">
    <button type="button" v-if="type === 'password'" @click.stop.prevent="changeType" class="absolute right-0 top-5">
      <RPIcon svg-fill="text" type="eye" />
    </button>
    <label :for="(id as string)" :class="{
      'text-error': errors.length > 0 || customError,
      'text-tertiary-text': errors.length === 0 && !customError
    }">
      {{ labelString }}
    </label>
    <input v-bind="{ ...(attrs as any), ...field }" :id="id" ref="input" :type="type" :value="modelValue"
      class="py-1 border-b-2 mb-4 read-only:border-b-0" />
    <ErrorMessage :name="(id as string)" as="span" class="block py-[5px] px-0 text-error text-fs-caption"
      data-cy="error-message" />
    <span v-if="customError && !errors.length" class="block py-[5px] px-0 text-error text-fs-caption"
      data-cy="error-message">
      {{ customError }}
    </span>
  </Field>
</template>

<script lang="ts">
// use normal <script> to declare options
export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
import { Field, ErrorMessage } from 'vee-validate';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import { computed, ref, useAttrs } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  },
  label: {
    type: String,
    required: true
  },
  rules: {
    type: String,
    default: ''
  },
  customError: {
    type: String,
    default: null
  }
})

const emits = defineEmits(['update:modelValue'])

const attrs = useAttrs()

const { t } = useI18n()

const id = computed(() => {
  if (attrs.id) {
    return attrs.id;
  }
  return props.label.toLowerCase();
})

const type = computed(() => {
  if (attrs.type) {
    return attrs.type;
  }
  return 'text';
})

const labelString = computed(() => {
  if (!props.rules.includes('required') && typeof attrs.readonly === 'undefined') {
    return `${props.label} (${t('forms.field_not_required')})`;
  }
  return props.label;
})

const input = ref(null)

const changeType = () => {
  const inputRef = input.value;
  if (inputRef.type === 'password') {
    inputRef.type = 'text';
  } else {
    inputRef.type = 'password';
  }
}
</script>

<style lang="scss" scoped></style>
