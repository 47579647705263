<template>
  <Teleport to="#topBarIconLeft">
    <RPButton
      data-cy="goToProfile"
      theme="custom"
      class="bg-transparent w-10 py-0 px-0 min-w-min"
      @click="backArrowClick()"
    >
      <RPIcon type="arrowLeft" svgFill="text" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span data-cy="vouchersTitle">
      {{ detailIsOpen ? $t('vouchers.title_header_details') : $t('vouchers.title_header_list') }}
    </span>
  </Teleport>
  <LoadingOverlay v-if="!componentReady" />
  <div v-else class="relative w-full" :class="{ 'view-block': !detailIsOpen }">
    <div v-if="!detailIsOpen" class="px-4 w-full flex items-center flex-col pb-4">
      <h3 class="text-fs-20 font-bold self-start mb-4">
        {{ $t('vouchers.title') }}
      </h3>
      <!-- Error API get vouchers -->
      <div v-if="VOUCHER_ERROR_MAP.includes(errorAPI)">
        {{ $t('vouchers.error.load_vouchers') }}
      </div>
      <div v-else-if="!campaigns.length">
        {{ $t('vouchers.no_vouchers') }}
      </div>
      <RPCard 
        v-for="item in campaigns" 
        :key="item.campaign.id"
        hasClick class="p-0 mb-4 w-full"
        @click.self.stop="openDetails(item)"
      >
        <!-- overlay loading/error -->
        <VoucherOverlay
          v-if="showOverlay(item.campaign.id)"
          :activateIsLoading="activateLoadingId === item.campaign.id"
          :activateError="activateErrorId === item.campaign.id"
        />
        <img :src="item.campaign.imageUrl" alt="voucher image" class="object-cover w-full h-full max-h-[120px]" @error="imageLoadError" />
        <VoucherShortInfo :voucher="item" @activate="activateCampaign(item.campaign.id)" />
      </RPCard>
    </div>
    <VoucherDetails 
      v-else
      :voucher="selectedVoucher"
      :activateIsLoading="!!activateLoadingId ?? false" 
      :activateError="!!activateErrorId ?? false"
      @activate="activateCampaign"
    />
    <div class="px-4 sticky bottom-12 z-20 w-full">
      <RPButton v-if="!detailIsOpen" @click="$router.push('/profile/vouchers/add')">
        {{ $t('vouchers.add_new') }}
      </RPButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import VoucherOverlay from './components/VoucherOverlay.vue';
import VoucherShortInfo from './components/VoucherShortInfo.vue';
import VoucherDetails from './VoucherDetails.vue';
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';

import { VOUCHER_ERROR_MAP } from '@/store/modules/vouchers/vouchers';
import { CampaignParticipation } from '@/store/modules/vouchers/types';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { onMounted, computed, ref } from 'vue';
import voucherFallbackImg from '@/assets/voucher-fallback.jpg';

const store = useStore();
const router = useRouter();

const componentReady = ref(false);
const errorAPI = ref(null);

onMounted(async () => {
  try {
    await store.dispatch('vouchers/loadVouchers');
  } catch (error) {
    errorAPI.value = store.state.vouchers.error;
  } finally {
    componentReady.value = true;
  }
});

const campaigns = computed<CampaignParticipation[]>(() => store.state.vouchers.vouchers);

// open details page
const detailIsOpen = ref(false);
const selectedVoucher = ref(null);
const openDetails = (payload: CampaignParticipation) => {
  selectedVoucher.value = payload;
  detailIsOpen.value = true;
};

// activate a voucher
const activateLoadingId = ref();
const activateErrorId = ref();
const activateCampaign = async (id: string) => {
  activateLoadingId.value = id;

  try {
    await store.dispatch('vouchers/activateCampaignAction', id);
    if (detailIsOpen.value) {
      selectedVoucher.value = campaigns.value.find(item => item.campaign.id === id);
    }
  } catch (e) {
    activateErrorId.value = id;
    setTimeout(() => {
      activateErrorId.value = null;

    }, 3000);
  } finally {
    activateLoadingId.value = null;
  }
};
const showOverlay = (id) => activateLoadingId.value === id || activateErrorId.value === id;



const backArrowClick = () => {
  detailIsOpen.value ? detailIsOpen.value = false : router.push('/profile');
};

const imageLoadError = (e: Event) => {
  const target = e.target as HTMLImageElement;
  target.src = voucherFallbackImg;
};
</script>