import axios from './index';

export const vouchersListAPI = async() => {
  const response = await axios.get(`v4/vouchers/campaign-participations`);
  return response.data
};

export const activateCampaignAPI = async (campaignId) => {
  await axios({
    method: 'post',
    url: 'v4/vouchers/campaign-participation-activations',
    data: {
      campaignId
    }
  });

};
export const previewVoucherAPI = async (payload) => {
  const response = await axios({
    method: 'post',
    url: 'v4/vouchers/discount-preview',
    data: payload
  });
  return response.data

};
export const addCustomVoucherAPI = async (voucherCode) => {
  await axios({
    method: 'post',
    url: 'v4/vouchers/vouchers',
    data: {
      code: voucherCode
    }
  });
};