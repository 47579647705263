<template>
  <div class="w-full px-6 view-block">
    <div class="flex items-center mb-1 l-scape:text-fs-32 w-full justify-center px-4 pt-lg">
      <h4 class="m-0 mr-4 leading-normal text-center">
        <span class="leading-normal font-normal" v-html="props.description">
        </span>
      </h4>
    </div>
    <div class="flex w-full flex-col items-center">
      <label 
        class="text-tertiary-text text-center mt-4 block w-full rp-fs-12-ln font-medium" 
        for="kms"
      >
        {{ $t('topup.mileage.input.label') }}
        <div class="relative">
          <input 
            id="kms" 
            v-model.number="odometerValue" 
            type="text" 
            pattern="[0-9]*" 
            min="0" 
            inputmode="numeric"
            class="odometer__input opacity-0 w-0 absolute bottom-0 right-0" 
            @input="onlyNumbers($event)" 
            @keydown="inputKeyDown"
          />
          <div class="odometer__input flex items-center justify-center
                border border-card-outline border-solid rounded-md
                mt-2 pr-2 h-10
                text-right rp-fs-18-ln text-white font-medium"
          >
            {{ formattedKms }}
            <span class="text-tertiary-text font-medium ml-1">km</span>
          </div>
        </div>
        <p v-if="errorMessage" class="text-error">
          {{ $t(errorMessage) }}
        </p>
      </label>
      <RPButton class="w-full mt-6" @click="validateForm">
        {{ $t('topup.mileage.button.primary') }}
      </RPButton>
      <RPButton link class="w-full mt-2 h-10" @click="refuseOdometer">
        {{ $t('topup.mileage.button.secondary') }}
      </RPButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import { computed, ref } from 'vue';

const props = defineProps({
  description: {
    type: String,
    default: ''
  }
});
const odometerValue = ref(0);

const errorMessage = ref(null);

const emit = defineEmits(['close']);

const formattedKms = computed(() => odometerValue.value ?? 0);

const onlyNumbers = (event: InputEvent | Event) => {
  errorMessage.value = null;

  const inputTypedValue = (event as InputEvent).data;

  if (inputTypedValue === null) return;

  const refString = String(odometerValue.value);
  if (!/[0-9]/.test(inputTypedValue)) {
    const deleteLetters = refString.replace(inputTypedValue, '');

    (event.target as HTMLInputElement).value = deleteLetters;
    odometerValue.value = Number(deleteLetters);
  }
};

const inputKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault();
  } else if (e.key === 'Enter') {
    validateForm();
  }
};

const validateKms = async () => {
  errorMessage.value = null;
  if (!odometerValue.value) {
    errorMessage.value = 'history.details.cost.error_required';
    return false;
  }
};

const validateForm = async () => {
  await validateKms();
  if (errorMessage.value) return;
  // we need to store the odometer for send it later (order complete)
  // we reset this key in the success page and in the pump selection
  // we use localStorage because we lost Vuex if payment goes in external site (MPGS)
  localStorage.setItem('odometer', (odometerValue.value * 1000).toFixed(0));
  emit('close');
};

const refuseOdometer = () => {
  localStorage.setItem('odometer', "false");
  emit('close');
}

</script>

<style>
.odometer__input:first-of-type:focus + .odometer__input {
  @apply border-white
}
</style>