<template>
  <RPCard
    small
    hasClick
    :class="{ 'border-card-disabled': notSupported }"
    class="justify-center items-start"
    @click="$emit('click')">
    <div class="flex items-center justify-center font-medium w-full">
      <slot name="logo" />
      <div class="w-full text-left ml-3 truncate">
        <strong>{{ paymentMethodName }}</strong>
        <span
          v-if="preferred"
          class="inline-block 
            ml-[6px] py-[2px] px-2.5 
            text-primary-btn-text text-xs font-medium
            bg-tertiary-text rounded-full">
          {{ $t('profile.main.payment-method.preferred') }}
        </span>
        <p
          v-if="paymentMethodDetails"
          class="m-0 mt-[2px] truncate">
          {{ paymentMethodDetails }}
        </p>
        <p
          v-if="notSupported"
          class="m-0 mt-[2px] truncate">
          {{ $t("profile.main.payment-method.not_supported_pm") }}
        </p>
      </div>
    </div>

  </RPCard>
</template>

<script>
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
export default {
  components: { RPCard },
  props: {
    preferred: {
      type: Boolean,
      default: false
    },
    paymentMethodName: {
      type: String,
      required: true
    },
    paymentMethodDetails: {
      type: String,
      default: null
    },
    notSupported: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  data() {
    return {};
  }
};
</script>