import { createI18n } from 'vue-i18n';
import { setLocale } from '@vee-validate/i18n';
import store from './store';

import en from './lang/en-US.json';

// TODO remove this after a while when migration is complete
// if localStoage lang includes "-"" remove 2nd part (WB-607)
if (localStorage.getItem('lang')?.includes('-')) {
  localStorage.setItem('lang', localStorage.getItem('lang').split('-')[0]);
  console.debug('migration from old lang format to new');
}

export function userLanguage() {
  if (localStorage.getItem('lang')) {
    return localStorage.getItem('lang');
  }

  const browserLanguage = navigator.language.split('-')[0].toLowerCase();
  if (supportedLanguages[browserLanguage]) {
    return browserLanguage;
  }

  return 'en';
}

export const supportedLanguages = {
  de: 'Deutsch',
  en: 'English',
  nl: 'Nederlands',
  fr: 'Français',
  it: 'Italiano',
  es: 'Español',
  pt: 'Português',
  da: 'Dansk',
  pl: 'Polskie'
};

// TODO: refactor ranme locales in phrase to get rid of this mapper
export const stringMapper = {
  de: 'de-DE',
  en: 'en-US',
  nl: 'nl-NL',
  fr: 'fr-FR',
  it: 'it-IT',
  es: 'es-ES',
  pt: 'pt-PT',
  da: 'da-DK',
  pl: 'pl-PL'
};

const i18n = createI18n({
  allowComposition: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en },
  silentTranslationWarn: import.meta.env.VUE_APP_ENV != 'dev'
});

export async function setLanguage(lang, saveToLocalStorage = true) {

  store.commit('ui/setLoading', true);

  const messages = await import(`./lang/${stringMapper[lang]}.json`);
  i18n.global.setLocaleMessage(lang, messages.default);
  setLocale(lang); // set lang for veeValidate
  i18n.global.locale = lang;
  if (saveToLocalStorage) {
    localStorage.setItem('lang', lang);
  }

  store.commit('ui/setLoading', false);
}

export default i18n;
