<template>
  <div v-if="registerQuery.provider === 'braintree'" class="px-6 view-block">
    <!-- Back arrow for the component loaded after choosing the 2FA method -->
    <Teleport v-if="!showButtons" to="#topBarIconLeft">
      <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" @click="backButton()">
        <RPIcon type="arrowLeft" svgFill="text" />
      </RPButton>
    </Teleport>
    <div v-if="showButtons">
      <h2 class="text-left mb-4">{{ $t('profile.payment_register.title') }}</h2>
      <p class="m-0 mb-16">
        {{ $t('profile.payment_register.subtitle') }}
      </p>
      <RPCard small hasClick @click="authButtonClick('pin')">
        <div class="flex justify-between items-center py-2">
          <div>
            <strong>{{ $t('profile.payment_register.pin.title') }}</strong>
            <div class="mt-1 text-fs-12 max-w-[190px]">
              {{ $t('profile.payment_register.pin.description') }}
            </div>
          </div>
          <RPIcon type="pin" svgFill="text" class="w-14" />
        </div>
      </RPCard>
      <RPCard small hasClick :disabled="true" @click="authButtonClick('bio')" class="mt-4">
        <div class="flex justify-between items-center py-2">
          <div>
            <strong>{{ $t('profile.payment_register.bio.title') }}</strong>
            <div class="mt-1 text-fs-12 max-w-[190px]">
              {{ $t('profile.payment_register.bio.description') }}
            </div>
          </div>
          <RPIcon type="fingerprint" svgFill="text" class="w-14" />
        </div>
      </RPCard>
    </div>
    <!-- 2FA method component -->
    <component
      v-if="authComponent"
      :is="authComponent"
      :key="authComponent"
      @success="registerAndRedirect()"
      @error="authError()" />
  </div>
  <RPInfo
    type="success"
    v-if="showModal"
    :title="textModal.title"
    :subTitle="textModal.subTitle"
    :buttonText="textModal.buttonText"
    @click="redirect()" />
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';

import { supportsWebauthn } from '@/utils/utils';
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPInfo from '@/components/Layout/RPInfo.vue';

import WebAuthnCreate from './components/WebAuthnCreate.vue';
import PinCreate from './components/PinCreate.vue';

import { resetPaymentsURLparams } from '@/utils/utils';
import { isEmpty } from 'lodash';

export default {
  components: { RPCard, RPIcon, RPButton, WebAuthnCreate, PinCreate, RPInfo },
  data() {
    return {
      showButtons: true,
      authComponent: false,
      showModal: false,
      method: ''
    };
  },

  async mounted() {
    this.saveURLParamsForUi();
    this.saveURLParamsForTrackOrder(this.$route.query);
    this.urlParamsForRegisterPM();
    await this.getOnce(this.$route.query.pid);

    const no2FARequiredProvider = ['mpgs', 'sibs', 'amazon'].includes(this.registerQuery.provider);
    if (no2FARequiredProvider) {
      this.registerAndRedirect();
    }
  },
  computed: {
    ...mapState({
      registerQuery: state => state.psp.registerQuery,
      activePayments: state => state.psp.activePayments,
      pid: state => state.station.data.id,
      savedParamsTrackOrder: state => state.station.ui
    }),
    ...mapGetters({
      latestCreatedPaymentMethod: 'psp/latestCreatedPaymentMethod'
    }),
    supportsWebauthn,
    orderType() {
      return this.savedParamsTrackOrder.orderType;
    },
    textModal() {
      const textMap = {
        APPLE_PAY: 'applepay_success',
        GOOGLE_PAY: 'gpay_success',
        AMAZON_PAY: 'amazonpay_success'
      };
      return {
        title: this.$i18n.t(`profile.main.payment-method.${textMap[this.method]}.title`),
        subTitle: this.$i18n.t(`profile.main.payment-method.${textMap[this.method]}.text`),
        buttonText: this.$i18n.t(`profile.main.payment-method.${textMap[this.method]}.button`)
      };
    },
    paramsToPreserve() {
      return resetPaymentsURLparams(this.$route.query);
    }
  },
  methods: {
    ...mapMutations({
      setClearRegisterQuery: 'psp/setClearRegisterQuery',
      saveURLParamsForTrackOrder: 'station/saveURLParamsForTrackOrder'
    }),
    ...mapActions({
      setTemporaryPM: 'psp/setTemporaryPM',
      saveURLParamsForUi: 'ui/saveURLParamsForUi',
      urlParamsForRegisterPM: 'psp/urlParamsForRegisterPM',
      getOnce: 'station/getOnce'
    }),

    authButtonClick(chosen2FA) {
      this.showButtons = false;
      this.authComponent = chosen2FA === 'pin' ? 'PinCreate' : 'WebAuthnCreate';
    },
    backButton() {
      this.authComponent = false;
      this.showButtons = true;
    },
    authError() {
      this.$router.replace('/error');
    },
    getAndSetLastPMAdded() {
      const lastPMAdded = this.latestCreatedPaymentMethod;
      this.setTemporaryPM(lastPMAdded);
    },

    async registerAndRedirect() {
      try {
        await this.$store.dispatch('psp/registerPayment', this.registerQuery);

        if (['APPLE_PAY', 'GOOGLE_PAY', 'AMAZON_PAY'].includes(this.$route.query.method)) {
          this.showModal = true;
          this.method = this.$route.query.method;
          return;
        }
        this.redirect();
      } catch (e) {
        console.log(e);
        this.$router.push({
          path: '/error',
          query: { ...this.paramsToPreserve, type: 'payment_register' }
        });
      }
    },
    redirect() {
      // if payment was added on checkout
      if (this.$route.query.orderType) {
        this.getAndSetLastPMAdded();
        const { orderId, amount, fuelTypeId, pumpId } = this.savedParamsTrackOrder;
        //We clear the stored info about the PM added to avoid wrong overrides for future add PMs
        this.setClearRegisterQuery();

        this.orderType === 'POSTPAY'
          ? this.$router.push({
            path: '/pay/postpay/summary',
            query: { ...this.paramsToPreserve, orderId, orderType: this.orderType, pid: this.pid }
          })
          : this.$router.push({
            path: '/pay/prepay/summary',
            query: { ...this.paramsToPreserve, amount, fuelTypeId, pumpId, pid: this.pid, orderType: this.orderType }
          });
      } else {
        this.$router.push({
          path: '/profile/payments',
          query: isEmpty(this.paramsToPreserve) ? { reset: true } : this.paramsToPreserve
        });
      }
    }
  }
};
</script>