import axios from '../api/index';

export function generateApplePayPayment({ amount, countryCode, currencyCode, isGuest }) {
  const roundedAmount = parseFloat(
    amount.toLocaleString('en', {
      maximumFractionDigits: 2
    })
  );

  const total = {
    label: 'ryd',
    type: 'final',
    amount: roundedAmount
  };

  return new Promise((resolve, reject) => {
    if (!window.ApplePaySession) {
      reject('Apple Pay is not available in this browser.');
    }
    // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest
    const request = {
      countryCode,
      currencyCode,
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['visa', 'masterCard'],
      total,
      ...(isGuest && {
        requiredShippingContactFields: ['email'],
        requiredBillingContactFields: ['postalAddress']
      })
    };

    const session = new window.ApplePaySession(4, request);

    session.onvalidatemerchant = async () => {
      // Open a session on the backend
      const { data } = await axios.post('/v4/apple-pay/session', {
        displayName: 'ryd pay',
        initiative: 'web',
        initiativeContext: window.location.host
      });
      session.completeMerchantValidation(data);
    };

    session.onpaymentmethodselected = () => {
      // Confirm total stays the same
      session.completePaymentMethodSelection({
        newTotal: total
      });
    };

    session.onpaymentauthorized = ({ payment }) => {
      session.completePayment({
        status: window.ApplePaySession.STATUS_SUCCESS
      });

      resolve(payment);
    };

    session.oncancel = event => {
      console.error(JSON.stringify(event));
      reject(event);
    };

    session.begin();
  });
}

export const isApplePaySupported = () => {
  if (!window.ApplePaySession) {
    return false;
  }

  return window.ApplePaySession.canMakePayments();
};
