<template>
  <main class="text-center view-block px-4">
    <RPIcon svg-fill="text" type="error" class="mt-0 mb-2 mx-auto" />
    <h2>{{ $t('404.title') }}</h2>
    <p v-html="$t('404.message')" />
    <RPButton href="/pay">
      {{ $t('hints.btn_cta') }}
    </RPButton>
  </main>
</template>
<script>
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';

export default {
  components: { RPIcon, RPButton }
};
</script>

