<template>
  <div class="view-block px-6">
    <div>
      <div class="mb-8 flex justify-center items-center">
        <LoyaltyIcon :programName="program.programName" class="w-12"/>
      </div>
      <div class="font-medium mb-6">
        <span class=" text-tertiary-text text-fs-12">{{ $t('loyalty.edit.label_name') }}</span>
        <p>{{ program.programName }}</p>
      </div>
      <div class="font-medium mb-6">
        <span class=" text-tertiary-text text-fs-12">{{ $t('loyalty.edit.label_details') }}</span>
        <p>{{ program.details.cardNumber }}</p>
      </div>
    </div>
    <RPButton
      theme="error"
      class="mt-2"
      @click="openRemoveDialog()">
      {{ $t('loyalty.delete') }}
    </RPButton>
    <Dialog v-if="removeDialogOpen" @close="removeDialogOpen = false">
      <h2>{{ $t('loyalty.edit.delete_dialog_title') }}</h2>
      <p>{{ $t('loyalty.edit.delete_confirm_text') }}</p>
      <RPButton class="block w-full mt-6" @click="emitRemoveLoyalty()">
        {{ $t('loyalty.edit.delete_confirm_btn_confirm') }}
      </RPButton>
      <RPButton theme="error" class="block w-full mt-2" @click="removeDialogOpen = false">
        {{ $t('loyalty.edit.delete_confirm_btn_cancel') }}
      </RPButton>
    </Dialog>
  </div>
</template>

<script>
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import Dialog from '@/components/Atoms/Dialog.vue';
import LoyaltyIcon from '@/components/Layout/LoyaltyIcon.vue';

import { ref } from 'vue';

export default {
  components: {
    RPButton,
    Dialog,
    RPIcon,
    LoyaltyIcon
  },
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const removeDialogOpen = ref(false);

    const openRemoveDialog = () => {
      removeDialogOpen.value = true;
      // this.$matomo.trackEvent('LoyaltyProgram', `Delete:${this.paymentMethod.name}`, 'ClickOnDeleteButton');
    };
    const emitRemoveLoyalty = async () => {
      removeDialogOpen.value = false;
      emit('emitRemoveLoyalty', props.program.id);
    };
    return {
      removeDialogOpen,
      openRemoveDialog,
      emitRemoveLoyalty
    };
  }
};
</script>
