<template>
  <div class="pagination flex items-center justify-between px-6">
    <button
      :class="hasPreviousPages ? 'cursor-pointer' : 'cursor-default opacity-20'"
      class="bg-transparent outline-none border-0"
      @click="currentPage !== 1 && pageChanged(currentPage - 1)"
    >
      <Chevron class="nofill" />
    </button>
    <p class="text-fs-16 font-semibold">
      {{ $t('fueling.pumpSelection.show_more_pumps') }}
    </p>
    <button
      :class="hasNextPages ? 'cursor-pointer' : 'cursor-default opacity-20'"
      class="bg-transparent outline-none border-0"
      @click="currentPage !== totalPages && pageChanged(currentPage + 1)"
    >
      <Chevron class="nofill transform rotate-180" />
    </button>
  </div>
</template>

<script>
import Chevron from '@/assets/icons/chevron_left.svg';

export default {
  components: { Chevron },
  props: {
    totalItems: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  emits: ['pageChanged'],
  data() {
    return {
      currentPage: 1
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    hasPreviousPages() {
      return this.currentPage > 1;
    },
    hasNextPages() {
      return this.currentPage < this.totalPages;
    }
  },
  methods: {
    pageChanged(page) {
      const pageIndex = page - 1;
      const startChunk = pageIndex * this.itemsPerPage;
      const endChunk = startChunk + this.itemsPerPage;

      this.currentPage = page;

      this.$emit('pageChanged', startChunk, endChunk);
    }
  }
};
</script>
<style lang="scss">
</style>
