<template>
  <div class="partner-logo-placeholder" :class="{ 'partner-logo-placeholder--visible': visible }" ref="placeholderRef">
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const getImage = (path: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.src = path;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
};

const props = defineProps({
  partner: {
    type: String,
    default: null
  }
})

const visible = ref<boolean>(false)
const placeholderRef = ref<HTMLElement | null>(null);

const replaceLogoPlaceholder = async () => {
  try {
    // Because we don't know if there is a logo for a given partner-string we preload it before adding to the dom
    const img = await getImage(`/img/partnerLogos/${props.partner}.png`);
    const placeholder = placeholderRef.value;
    if (placeholder) {
      placeholder.appendChild(img);
    }
    visible.value = true;
  } catch (e) {
    console.debug('partnerlogo not found', e);
  }
}

onMounted(async () => {
  if (props.partner) {
    await replaceLogoPlaceholder();
  }
});
</script>

<style lang="scss">
.partner-logo-placeholder {
  &--visible {
    background: #fff;
    display: flex;
    height: 100%;
    width: max-content;
    align-items: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-left: var(--space-lg);
    padding-right: var(--space-lg);
  }
}

.partner-logo-placeholder img {
  height: 32px;
  max-width: 100%;
}
</style>
