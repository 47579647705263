import { createMachine, assign } from "xstate";

export interface CheckoutContext {
  guestCheckout: boolean;
  supportDevice: boolean;
  supportPOI: boolean;
  isGuest: boolean;
  userHasPMs: boolean;
}

const isGuestGreenLight = (context) => {
  return context.guestCheckout && context.supportPOI && context.supportDevice;
};
const isAuthGreenLight = (context) => {
  return context.userHasPMs && context.supportPOI && context.supportDevice;
};
const noPoiSupport = (context) => {
  return !context.supportPOI;
};

const noDeviceSupport = (context) => {
  return !context.supportDevice;
};

const noGuestCheckout = (context) => {
  return !context.guestCheckout;
};
const isGuest = (context) => {
  return context.isGuest;
};
const noUserPMs = (context) => {
  return !context.userHasPMs;
};

export const checkoutMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGMAWZkGsD2BXALgHQCWEANmAMQDaADALqKgAO2sx+x2AdkyAB6IAtADYArAE5CAJgAsEkQA4JAdmkBGFYtoiANCACew9QGZ1hE7XUSJJpYpHrZmgL4v9aDDgIlyVauqMSCCs7Jw8fIIIoiIqFkqy0iK0tA4qIhL6RghytIRiGYrStFoOYjpibh7oWHhEULhwRGTYAIYQxNxQNAx8oRxcvMFRJrKyhOpJieJiRdISJVnCebJFJhKSKWJaJut2VSCetT4NTYQt7Z3dAUEsbAMRw4ij45Mi02Kz0vOLhsKrhFW0nWm1o20UowOR289UasGabQ6XRo0luIXu4SGoBGYwmUySnzmCxUS2iCkBaw2CzBO1kUJqMMIp3h50RVxoJjR-UxkWeuLeHy+PxJf2iTnGQJB1PBJkq7kODLqhFaBFQrMuyLoXIxg15CGcIgmilktEsJiKImSelFYm+hAk6mU6m2InWiRM9K8SpV+DVFyR10CfR1j2xiANRpNZotVtJshU4wdTpdbuBnuORB9frZmtRwbCuqe+vUhsdUdNMZ0caS9sdDpTEnd6cZWfVAY52oLoYE4ZLkdNFaSsdFJi0teT6VTHoO3GwEDgfGhdXzDyxPeiKjE41HC2sGTMsgKpKEzji4iczoUloU6mbStIFBXPKLQhUOgsKl3ClsTiPorkhAJhIaRjOotDFNsd4nHC+BPoWYbRIk4zfO8YjWGIZjWOox4mNIiiAhsmhbKOYGKFBsJnP6Vxwd2USmIayRONI5S4bKVjWtkQjAlIuzKHITglMxkHyku0FnAACgAggAmgAsgAogAcgAKgA+pJAAyGkAPIAOryQAIjRa5REIo6Gihh7oZhDpxnEaHpLQJo2IkJYiORTIwYQ4naQAkqpUlyUpakKcpAAS2kGapABK8kAFLyQAwsphnGXqPyAqsMp1hIch2fkmjJM5jYaJaHnMkQBnyQAar5iXyQFMkKSpqmhRFUWxQlyWpcE3LweuGRiBYqRqKati7Gh+UOUVtAuaVm7lV5mmydpADKakGdpinyWlRZ5CIGjSG+sSpHh8jOKS2z2rKtquqso6zNO1Rej4Wa7QhJ6NoBSSKOUJbjQopLfNI9rfLM8jAlGjoea2VFdO967FPh4gLHhv2xIomikmYIMlIoxrvEOwow6q3lNcF6laXpPV3F2Jn-LIhpqEof37usHGILMeTfEUv0LAkpgk763l+Y1QUtW1kUxfFSUpUZvUhvTCB2HEeFyGx7xqMoJiXW+471pOJVPQqL2ZqTVW1fVYvNSF8nhVLnWyzT6J03qQj-YQfGyBNh5aFYl3aDIatCeKKiCyJiqvaTkkGVF4myQjdEODINjnqorrbJkorxnkhIqLKNjAdrbhuEAA */
  createMachine<CheckoutContext>(
    {
      predictableActionArguments: true,
      context: { userHasPMs: null, guestCheckout: null, supportDevice: null, supportPOI: null, isGuest: null },
      id: 'checkout',
      initial: 'idle',
      states: {
        idle: {
          on: {
            FETCH_POI: {
              target: 'loaded',
              actions: ['contextAssign']
            }
          }
        },
        loaded: {
          always: [
            {
              target: 'guest',
              cond: isGuest
            },
            {
              target: 'user'
            }
          ]
        },
        guest: {
          initial: 'idle',
          states: {
            idle: {
              always: [
                {
                  target: 'allowed',
                  cond: isGuestGreenLight
                },
                {
                  target: 'guestRequireAuth',
                  cond: noGuestCheckout,
                },
                {
                  target: 'deviceRejectedPM',
                  cond: noUserPMs,
                },
                {
                  target: 'poiRejectedPM',
                  cond: noPoiSupport
                },
                {
                  target: 'deviceRejectedPM'
                }
              ]
            },
            allowed: {
              type: 'final'
            },
            poiRejectedPM: {
              type: 'final'
            },
            deviceRejectedPM: {
              type: 'final'
            },
            guestRequireAuth: {
              type: 'final'
            }
          }
        },
        user: {
          initial: 'idle',

          states: {
            idle: {
              always: [
                {
                  target: 'allowed',
                  cond: isAuthGreenLight
                },
                {
                  target: 'noPM',
                  cond: noUserPMs
                },
                {
                  target: 'poiRejectedPM',
                  cond: noPoiSupport
                },
                {
                  target: 'deviceRejectedPM'
                }
              ]
            },
            allowed: {
              type: 'final',
              on: {
                PAYMENT_CHANGE: {
                  target: 'idle',
                  actions: ['contextAssignPayment']
                }
              }
            },
            poiRejectedPM: {
              type: 'final',
              on: {
                PAYMENT_CHANGE: {
                  target: 'idle',
                  actions: ['contextAssignPayment']
                }
              },
            },
            deviceRejectedPM: {
              type: 'final',
              on: {
                PAYMENT_CHANGE: {
                  target: 'idle',
                  actions: ['contextAssignPayment']
                }
              },
            },
            noPM: {
              type: 'final',
              on: {
                PAYMENT_CHANGE: {
                  target: 'idle',
                  actions: ['contextAssignPayment']
                }
              },
            }
          }
        }
      }
    },
    {
      guards: {
        isGuestGreenLight,
        isAuthGreenLight,
        noPoiSupport,
        noDeviceSupport,
        noGuestCheckout,
        isGuest,
      },
      actions: {
        contextAssign: assign((context, event) => event.checkoutContext),
        contextAssignPayment: assign({
          supportDevice: (context, event) => event.supportDevice,
          supportPOI: (context, event) => event.supportPOI
        })
      }
    }
  );
