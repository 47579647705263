import axios from './index';
import { setCredentials } from '../utils/utils';

// Docs see: https://tt4.thinxcloud-dev.de/doc/#api-Auth
export async function register(data) {
  if (!data.referrer) {
    data.referrer = 'ryd-pay-web';
  }

  await axios.post('/auth/register', { ...data, referralPlatform: 'rydpaywebapp' });
}

export async function login(email, password, onlycheck = false) {
  const { data } = await axios.post('/auth/login/local', { email, password });
  if (!onlycheck) {
    setCredentials(data.auth_token, data._id);
    return data;
  }
}

export async function loginGuest() {
  const { data } = await axios.post('/auth/register/guest');
    setCredentials(data.auth_token, data._id);
    return data;
}

export async function loginOpenId(provider, token) {
  const { data } = await axios.post('/auth/login/openId', { idProvider: provider, idToken: token });
  setCredentials(data.auth_token, data._id);
  return data;
}

export async function changePwd(newPassword) {
  await axios.post('/auth/change-password', { newPassword });
}

export async function resendPwd(email) {
  await axios.post('/auth/resend-password', { email });
}

export async function logout() {
  await axios.post('/auth/logout');
  localStorage.removeItem('auth');
}
export async function getUserPreferences() {
  const { data } = await axios.get(`/v4/users/preferences`);
  return data.thingAvatars;
}
