import Order from '@/rydPaySDK/src/Order';
import { currencyString } from '@/utils/utils.js';
import { getMerchant } from '@/api/merchants';
import { isPaymentMethodsLimitExceeded } from '@/api/payments';
import pWaitFor from 'p-wait-for';
import { pay } from '@/utils/gpay.js';
import router from '@/router.js';
import axiosInstance from '@/api/index';
import { generateApplePayPayment } from '@/utils/applePay.js';

import { PayTypes, NewOrderTypes, AuthorizePayloadType, ApplePayPayment, UserDetails } from './types';

let order;

const paymentFlowPages = {
  prepayWithFuelSelection: [
    { ordinal: 1, path: '/pay/pump' },
    { ordinal: 2, path: '/pay/prepay/fuelselection' },
    { ordinal: 3, path: '/pay/prepay/amount' },
    { ordinal: 4, path: '/pay/prepay/summary' },
    { ordinal: 5, path: '/pay/prepay/fueling' },
    { ordinal: 6, path: '/pay/finish' }
  ],
  prepayWithoutFuelSelection: [
    { ordinal: 1, path: '/pay/pump' },
    { ordinal: 2, path: '/pay/prepay/amount' },
    { ordinal: 3, path: '/pay/prepay/summary' },
    { ordinal: 4, path: '/pay/prepay/fueling' },
    { ordinal: 5, path: '/pay/finish' }
  ],
  postPay: [
    { ordinal: 1, path: '/pay/pump' },
    { ordinal: 2, path: '/pay/postpay/fueling' },
    { ordinal: 3, path: '/pay/postpay/summary' },
    { ordinal: 4, path: '/pay/finish' }
  ]
};

function getPaymentFlow(rootGetters) {
  const chosenPump = rootGetters['station/chosenPump'];
  if (!chosenPump) {
    return null;
  }
  const isPumpWithFuelSelection = Boolean(chosenPump.fuelTypes.length);
  if (chosenPump.type === 'POSTPAY') {
    return paymentFlowPages.postPay;
  }
  if (chosenPump.type === 'PREPAY') {
    return isPumpWithFuelSelection
      ? paymentFlowPages.prepayWithFuelSelection
      : paymentFlowPages.prepayWithoutFuelSelection;
  }
  console.error('Pump type is unknown');
  return null;
}

const getDefaultState = (): PayTypes => {
  return {
    orderStatus: {},
    PayPalCorrelationId: null,
    merchantConfig: {
      gatewayMerchantId: null,
      countryCode: null
    },
    applePayPayment: null,
    amazonPayPayment: null,
    paymentMethodIdSelected: null
  };
};

const state: PayTypes = getDefaultState();

const mutations = {
  orderFeedback(state, data) {
    state.orderStatus = { ...state.orderStatus, ...data };
  },
  setPayPalCorrelationId(state, id) {
    state.PayPalCorrelationId = id;
  },
  setMerchantConfig(state, { merchantId, countryCode }) {
    state.merchantConfig.gatewayMerchantId = merchantId;
    state.merchantConfig.countryCode = countryCode;
  },
  resetMerchantConfig(state) {
    state.merchantConfig = {
      gatewayMerchantId: null,
      countryCode: null
    };
  },
  setApplePayPayment(state, payment: ApplePayPayment) {
    state.applePayPayment = payment;
  },
  setAmazonPayPayment(state, token: string) {
    state.amazonPayPayment = token;
  },
  setPaymentMethodId(state, paymentId) {
    state.paymentMethodIdSelected = paymentId;
  },
  resetPayState(state: PayTypes) {
    Object.assign(state, getDefaultState());
  }
};

const actions = {
  createOrderInstance({ rootState }, orderId) {
    const orderFromStorage = JSON.parse(localStorage.getItem(`rydPaySDK-${orderId}`));
    const poi = rootState.ui.query.pid || orderFromStorage.poi;
    order = new Order(rootState.auth.thingId, poi);
  },
  async createOrder({ commit, rootState, dispatch, rootGetters }) {
    commit('ui/setLoading', true, { root: true });
    try {
      dispatch('createOrderInstance');

      const chosenPump = rootGetters['station/chosenPump'];

      const orderObj: NewOrderTypes = {
        pumpId: rootState.station.ui.pumpId,
        poiId: rootState.ui.query.pid,
        fuelTypeId: null,
        type: null,
        price: {},
        initiatedByPartner: null
      };

      if (rootState.station.ui.fuelTypeId) {
        orderObj.fuelTypeId = rootState.station.ui.fuelTypeId;
      }

      if (chosenPump.type === 'PREPAY') {
        orderObj.price = {
          amount: rootState.station.ui.amount,
          ...chosenPump.priceType
        };
      } else {
        orderObj.type = 'POSTPAY';
      }
      orderObj.initiatedByPartner = rootState.ui.query.partner;
      await order.create(orderObj);
      commit('orderFeedback', order);

    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async createApplePaySession({ commit, state, rootState, rootGetters }) {
    if (rootGetters['psp/paymentIsApplePay']) {
      let amount: number;
      let currencyCode: string;
      const countryCode: string = rootState.station.data?.name.country.toUpperCase();

      const chosenPump = rootGetters['station/chosenPump'];
      const orderPrice = rootState.vouchers.voucherPreview?.totalAfterDiscounts || state.orderStatus.paymentResult.total
      if (state.orderStatus.type === 'POSTPAY') {
        amount = orderPrice.amount;
        currencyCode = orderPrice.currency;
      } else {
        amount = rootState.station.ui.amount;
        currencyCode = chosenPump.priceType.currency;
      }

      try {
        const applePayPayment: ApplePayPayment = await generateApplePayPayment({
          amount,
          currencyCode,
          countryCode,
          isGuest: rootState.auth?.isGuest
        });
        commit('setApplePayPayment', applePayPayment);
      } catch (e) {
        commit('setApplePayPayment', null);
        throw e;
      }
    }
  },
  async authorizeAmazon({ commit, rootGetters, state, getters, rootState }, { paymentMethod, paymentId }) {
    commit('ui/setLoading', true, { root: true });
    try {
      let authorizePayload: AuthorizePayloadType;
      if (paymentMethod === 'AMAZON_PAY') {
        authorizePayload = {
          paymentToken: {
            amazonPayToken: state.amazonPayPayment,
          },
          paymentMethodId: paymentId,
          initiatedByPartner: rootState.ui.query.partner,
          priceAfterDiscounts: rootState.vouchers.voucherPreview?.totalAfterDiscounts ?? null,
        };
      }

      await order.authorize(authorizePayload);
      commit('orderFeedback', order);

    } catch (e) {
      commit('setAmazonPayPayment', null);
      throw new Error(e);
    }
    finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async startPay({ commit, rootGetters, state, getters, rootState }) {
    commit('ui/setLoading', true, { root: true });

    let paymentToken;
    let userDetails: UserDetails | undefined;

    if (rootGetters['psp/paymentIsGpay']) {
      await pWaitFor(() => state.merchantConfig.merchantId !== null, {
        timeout: 10000
      });
    }

    try {
      const query = rootGetters['ui/queryString'];
      const successRoute = order?.type === 'PREPAY' ? '/pay/prepay/fueling' : '/pay/finish';

      const successUrl = `${import.meta.env.VUE_APP_PSP_REDIRECT_URL}?sca_feedback=${encodeURIComponent(
        successRoute
      )}&orderId=${order.id}&orderType=${order.type}${query}`;

      const errorUrl = `${import.meta.env.VUE_APP_PSP_REDIRECT_URL}?sca_feedback=${encodeURIComponent(
        '/error'
      )}${query}`;

      if (rootGetters['psp/paymentIsPayPal']) {
        const { getCorrelationId } = await import('../../../utils/paypal');
        commit('setPayPalCorrelationId', await getCorrelationId());
      }

      if (rootGetters['psp/paymentIsGpay']) {
        const googlePaymentData = await pay(getters.gpayPayload);

        if (rootState.auth.isGuest) {
          const splitName = googlePaymentData?.paymentMethodData?.info?.billingAddress?.name.split(' ');
          const firstName = splitName[0];
          const lastName = splitName[1];

          userDetails = {
            invoiceEmail: googlePaymentData?.email,
            address: {
              street: googlePaymentData?.paymentMethodData?.info?.billingAddress?.address1,
              street2: googlePaymentData?.paymentMethodData?.info?.billingAddress?.address2 + ' ' + googlePaymentData?.paymentMethodData?.info?.billingAddress?.address3,
              countryCode: googlePaymentData?.paymentMethodData?.info?.billingAddress?.countryCode,
              zip: googlePaymentData?.paymentMethodData?.info?.billingAddress?.postalCode,
              city: googlePaymentData?.paymentMethodData?.info?.billingAddress?.locality
            },
            firstName,
            lastName
          };
        }
        const { token } = googlePaymentData.paymentMethodData.tokenizationData;

        paymentToken = {
          googlePayToken: token
        };
      }

      if (rootGetters['psp/paymentIsApplePay']) {
        const applePayPayment: ApplePayPayment = state.applePayPayment;

        paymentToken = {
          applePayToken: JSON.stringify(applePayPayment.token.paymentData)
        };

        userDetails = rootState.auth.isGuest
          ? {
            invoiceEmail: applePayPayment.shippingContact.emailAddress,
            address: {
              zip: applePayPayment.billingContact.postalCode,
              countryCode: applePayPayment.billingContact.countryCode,
              city: applePayPayment.billingContact.locality,
              street: applePayPayment.billingContact.addressLines[0],
              ...(applePayPayment.billingContact.addressLines[1] && {
                street2: applePayPayment.billingContact.addressLines[1]
              }),
            },
            firstName: applePayPayment.billingContact.givenName,
            lastName: applePayPayment.billingContact.familyName
          }
          : undefined;
      }
      const orderType = rootGetters['station/chosenPump']?.type || rootState.pay.orderStatus?.type
      
      let authorizePayload: AuthorizePayloadType = {
        successUrl,
        errorUrl,
        paypalCorrelationId: state.PayPalCorrelationId || undefined,
        paymentToken,
        initiatedByPartner: rootState.ui.query.partner,
        priceAfterDiscounts: orderType === 'PREPAY' ? null : rootState.vouchers.voucherPreview?.totalAfterDiscounts ?? null,
        ...(userDetails && { userDetails })
      };

      if (state.paymentMethodIdSelected !== null) {
        authorizePayload = {
          ...authorizePayload,
          paymentMethodId: state.paymentMethodIdSelected,
        };
      }

      // https://pay-gateway-dev.internal.thinxcloud.de/specs/openapi/#/order/OrdersController_authorize
      const authorizeLink = await order.authorize(authorizePayload);
      commit('orderFeedback', order);

      return authorizeLink;
    } catch (e) {
      console.error('authorize fail', e);
      // Just throw the error so in summary we can check if GPay was closed or was an actual error
      throw e;
    }
    finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async fuel({ commit }) {
    await order.fuel();
    commit('orderFeedback', order);
  },
  cancel() {
    order.cancel = true;
  },
  async acquire({ commit }) {
    commit('ui/setLoading', true, { root: true });
    try {
      await order.acquire();
      commit('orderFeedback', order);
    } catch (e) {
      throw new Error(e);
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async getOrderDetail({ commit, dispatch }, orderId) {
    commit('ui/setLoading', true, { root: true });
    try {
      dispatch('createOrderInstance', orderId);
      await order.resume({ orderId });
      commit('orderFeedback', order);
    } catch (e) {
      throw new Error(e);
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async resume({ commit, dispatch }, orderId) {
    commit('ui/setLoading', true, { root: true });
    try {
      dispatch('createOrderInstance');
      await order.resume({ orderId });
      commit('orderFeedback', order);
      await order.acquire();
      commit('orderFeedback', order);
    } catch (e) {
      throw new Error('resume order error', e);
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  },
  async getMerchantConfig({ commit, rootState, rootGetters }) {
    commit('resetMerchantConfig');
    const merchantConfig = await getMerchant(
      rootState.auth.localisationCountry,
      rootState.station.data.name.country,
      rootGetters['psp/checkoutMethod'].method
    );
    commit('setMerchantConfig', merchantConfig);
  },
  async isPaymentMethodsLimitExceeded({ commit }) {
    commit('ui/setLoading', true, { root: true });
    try {
      return await isPaymentMethodsLimitExceeded();
    } catch (e) {
      console.error(e);
    } finally {
      commit('ui/setLoading', false, { root: true });
    }
  }
};

const getters = {
  total(state) {
    if (!state.orderStatus?.paymentResult?.totalAfterDiscounts || !state.orderStatus?.paymentResult?.total) {
      return null;
    }
    const finalTotal = state.orderStatus?.paymentResult?.totalAfterDiscounts || state.orderStatus?.paymentResult?.total;

    return currencyString(finalTotal.amount, finalTotal.currency);
  },
  gpayPayload(state, getters, rootState, rootGetters) {
    const orderId = state.orderStatus.id;
    let amount, currencyCode;
    const totalToAuthorize = rootState.vouchers.voucherPreview?.totalAfterDiscounts || state.orderStatus?.paymentResult?.total;

    const chosenPump = rootGetters['station/chosenPump'];
    if (state.orderStatus.type === 'POSTPAY') {
      amount = Number(totalToAuthorize.amount);
      currencyCode = totalToAuthorize.currency;
    } else {
      amount = rootState.station.ui.amount;
      currencyCode = chosenPump.priceType.currency;
    }

    const { gatewayMerchantId, countryCode } = state.merchantConfig;

    return { orderId, amount, currencyCode, countryCode, gatewayMerchantId, isGuestUser: rootState?.auth?.isGuest };
  },
  payFlowPages(state, getters, rootState, rootGetters) {
    const pages = getPaymentFlow(rootGetters);
    const flowState = {
      pagesCount: undefined,
      currentPageOrder: undefined,
      nextPagePath: undefined,
      previousPagePath: undefined,
      isLastPageInFlow: router.currentRoute.value.path === '/pay/finish'
    };
    const currentRoutePath = router.currentRoute.value.path;

    if (pages) {
      flowState.pagesCount = Object.entries(pages).length || undefined;

      flowState.currentPageOrder = pages.find(page => page.path === currentRoutePath).ordinal || undefined;

      flowState.nextPagePath = pages.find(page => page.ordinal === flowState.currentPageOrder + 1)?.path || undefined;

      const orderType = rootGetters['station/chosenPump'].type;
      flowState.previousPagePath =
        flowState.currentPageOrder <= 1
          ? '/pay'
          : orderType === 'POSTPAY'
            ? '/pay/pump'
            : pages.find(page => page.ordinal === flowState.currentPageOrder - 1)?.path || undefined;
    }

    return {
      ...flowState
    };
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
