<template>
  <section class="flex-1  view-block">
    <div class="flex-center-col">
      <h2 class="text-left w-full mb-10">{{ $t('webauthn.register.title') }}</h2>
      <small>{{ $t('webauthn.register.text') }}</small>
      <RPIcon type="fingerprint" svgFill="text" class="w-40 opacity-30" />
      <span v-if="error" class="error"> {{ $t('errors.generic.text') }}</span>
    </div>
    <div class="flex-center-col">
      <RPButton
        theme="custom"
        class="flex-col w-16 h-16 min-w-fit p-0 bg-primary-light border border-solid rounded-full border-card-outline"
        @click="webauthn()">
        <RPIcon type="fingerprint" svgFill="text" />
      </RPButton>
      <div class="text-primary-text text-fs-12 text-center w-28 mt-2">{{ $t('webauthn.btn_cta') }}</div>
    </div>
  </section>
</template>

<script>
import { startRegistration } from '@simplewebauthn/browser';
import { generateRegisterOptions, registerWebauthn } from '@/api/pin';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

export default {
  components: { RPIcon, RPButton },
  data() {
    return {
      error: false
    };
  },
  emits: ['success', 'error'],
  computed: {
    userId() {
      return this.$store.state.auth.id;
    }
  },
  mounted() {
    this.webauthn();
  },
  methods: {
    async webauthn() {
      this.$store.commit('ui/setLoading', true);
      this.error = false;
      try {
        await this.register();
        this.$emit('success');
      } catch (e) {
        this.error = true;
        console.log(e);
      } finally {
        this.$store.commit('ui/setLoading', false);
      }
    },
    async register() {
      const options = await generateRegisterOptions({
        userId: this.userId,
        userName: this.$store.state.auth.email
      });
      const attResp = await startRegistration(options);

      await registerWebauthn({ options: attResp });
    }
  }
};
</script>
