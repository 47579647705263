import { QueryTypes, StateUITypes } from './types';

const defaultQuery: QueryTypes = {
  pid: null,
  partner: null,
  callback: null,
  email: null,
  longitude: null,
  latitude: null,
  mtskid: null,
  landscape: null,
  hideRydMapLink: null,
};

const state: StateUITypes = {
  loading: false,
  sidebars: {
    language: false,
    transactionHistoryFilter: false
  },
  query: { ...defaultQuery },
  transactionHistoryFilterActive: false,
  pinPageIsShowing: false,
  previousRoute: '', // Needed to apply the correct path to the back arrow in TopBar.vue
  apiErrors: {},
  showHintScreen: true
};

export default {
  state,
  mutations: {
    setPreviousRoute(state: StateUITypes, previousRoute: string) {
      state.previousRoute = previousRoute;
    },
    setLoading(state: StateUITypes, newLoadingState: boolean) {
      state.loading = newLoadingState;
    },
    clearLonLat(state: StateUITypes) {
      state.query.longitude = null;
      state.query.latitude = null;
    },
    saveUrlQuery(state: StateUITypes, parsedQuery) {
      state.query = parsedQuery;
    },
    setSidebar(state: StateUITypes, { type, visible }: { type: string; visible: boolean; }) {
      state.sidebars[type] = visible;
    },
    setTransactionHistoryFilterActive(state: StateUITypes, value: boolean) {
      state.transactionHistoryFilterActive = value;
    },
    setPinPageIsShowing(state: StateUITypes, isShowing = true) {
      state.pinPageIsShowing = isShowing;
    },
    setApiErrors(state: StateUITypes, data) {
      state.apiErrors = data;
    },
    setShowHintScreen(state: StateUITypes, data) {
      state.showHintScreen = data;
    }
  },
  actions: {
    /**
     * @name saveURLParamsForUi
     * @description store URL params
     */
    saveURLParamsForUi({ commit, state }) {
      const URLParameters = Object.fromEntries(new URLSearchParams(window.location.search));
      const getStateQuery = state.query;

      // Since the URL params are string and we use those params for logic inside the code
      // We need to transform strings in booleans
      const normalizedParam = Object.keys(URLParameters).reduce((acc, param) => {
        // if URL param is not present in the state.ui, we return
        if (!defaultQuery.hasOwnProperty(param)) return acc;
        const booleanParamTransform = URLParameters[param] === 'true'
          ? true
          : URLParameters[param] === 'false'
            ? false
            : URLParameters[param];

        acc = {
          ...acc,
          [param]: booleanParamTransform
        };
        return acc;
      }, {});
      // Here we merge the old stored values with the new ones
      // so we can keep params like partner or landscape if were present
      const newQuery = { ...getStateQuery, ...normalizedParam };
      if (newQuery.partner) {
        localStorage.setItem('partner', newQuery.partner);
      }
      commit('saveUrlQuery', newQuery);
    }
  },
  getters: {
    /**
     * @name queryString
     * @description Build a URL query string from this store state.query
     * @returns {String}
     */
    queryString({ query }: { query: QueryTypes; }) {
      const string = Object.keys(query)
        .filter(key => query[key] !== null && key != 'email')
        .map(key => key + '=' + query[key])
        .join('&');

      if (string) {
        return '&' + string;
      }
      return '';
    },
    hideRydMapLink(state) {
      return state.query.hideRydMapLink;
    }
  }
};
