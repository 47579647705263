<template>
  <Teleport to="#topBarIconLeft">
    <RPButton
      data-cy="goToHistory"
      theme="custom"
      class="bg-transparent w-10 py-0 px-0 min-w-min"
      @click="$router.push('/history')"
    >
      <RPIcon type="arrowLeft" svgFill="text" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span data-cy="historyAddCategoryTitle">
      {{ $t('history.details.title_cost') }}
    </span>
  </Teleport>
  <LoadingOverlay v-if="isLoading" />
  <div v-if="!categoryInit" class="px-6 pt-4 w-full">
    <p class="font-medium text-left text-tertiary-text">
      {{ $t('history.details.cost.filter_category_title') }}
    </p>
    <ul class="pt-6 text-left font-bold">
      <li 
        v-for="category in Object.keys(COST_EVENTS_TEXT_MAP)" 
        :key="category" 
        class="mb-6" 
          
        @click="categoryInit = category"
      >
        <RPIcon :type="`history/${getCategoryIconUtils(category)}`" noFill class="mr-3" />
        {{ $t(`history.details.cost.category.${getCategoryTextUtils(category)}`) }}
      </li>
    </ul>
  </div>
  <CostDetails 
    v-else
    :category="categoryInit"
    isCreateComponent
    :error="APIError"
    @create="createEvent"
  />
</template>

<script setup lang="ts">
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import CostDetails from './components/CostDetails.vue';
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';

import { HistoryCreateCostPayload } from '@/store/modules/history/types';
import { useStore } from 'vuex';

import {
  getCategoryIconUtils,
  getCategoryTextUtils,
  COST_EVENTS_TEXT_MAP
} from '@/store/modules/history/historyUtils';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const store = useStore();

const categoryInit = ref(null);
const router = useRouter();

const isLoading = ref(false);
interface PayloadCreate {
  form: HistoryCreateCostPayload;
  images: {
    imageBuffer: ArrayBuffer;
    type: string;
  }[];
}
const APIError = ref();
const createEvent = async (payload: PayloadCreate) => {
  try {
    isLoading.value = true;
    APIError.value = null;
    await store.dispatch('history/createEventCost', { payload: payload.form, images: payload.images });
    router.push('/history');
  } catch (error: any) {
    console.log(error);
    if (error.response?.data?.message?.includes('carOdometerM')) {
      APIError.value = 'mileage';
    } else {
      router.push('/history/error');
    }
  } finally {
    isLoading.value = false;
  }
};
</script>