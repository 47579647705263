<template>
  <Teleport to="#topBarIconLeft">
    <RPButton
      data-cy="goToVouchersList"
      theme="custom"
      class="bg-transparent w-10 py-0 px-0 min-w-min"
      @click="$router.push('/profile/vouchers')"
    >
      <RPIcon type="arrowLeft" svgFill="text" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span data-cy="vouchersTitle">
      {{ $t('vouchers.title_header_list') }}
    </span>
  </Teleport>
  <LoadingOverlay v-if="isLoading" />
  <div class="view-block px-4">
    <form @submit.prevent="addVoucher">
      <h3 class="text-fs-20 text-left mb-2">
        {{ $t('vouchers.add_custom.title') }}
      </h3>
      <p class="text-fs-12 mb-6">
        {{ $t('vouchers.add_custom.sub_title') }}
      </p>
      <label class="text-tertiary-text">{{ $t('vouchers.add_custom.label_input') }}</label>
      <input v-model="voucherCode" type="text" @input="voucherError = null" />
      <p v-if="voucherError" class="text-error">
        {{ $t('vouchers.add_custom.error_valid') }}
      </p>
    </form>
    <RPButton :disabled="!voucherCode.length" @click="addVoucher">
      {{ $t('vouchers.add_custom.add') }}
    </RPButton>
  </div>
</template>

<script setup lang="ts">
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import router from '@/router';
import { ref } from 'vue';

import { useStore } from 'vuex';
const isLoading = ref(false);
const voucherError = ref(null);
const voucherCode = ref('');
const store = useStore();
const addVoucher = async () => {
  try {
    isLoading.value = true;
    await store.dispatch('vouchers/addCustomVoucher', voucherCode.value);

    router.push('/profile/vouchers');
  } catch (e) {
    console.log(e);
    voucherError.value = store.state.vouchers.error;
  } finally {
    isLoading.value = false;
  }
};
</script>