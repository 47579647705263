<template>
  <div class="view-block items-center">
    <div>
      <input name="pincode" type="text" inputmode="numeric" maxlength="4" class="pincode" data-cy="pinCode"
        autocomplete="off" :class="{ errorShake: error }" @input="checkSubmit" ref="inputRef" />
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <div v-if="errorOnlyNumbers" class="error">
        {{ errorOnlyNumbers }}
      </div>
      <RPButton v-if="props.pinRestHint" link class="pin_forgotten" @click="dialogOpen = true">
        {{ t('paymentPin.btn_forgotten') }}
      </RPButton>
    </div>
    <div class="button__setfocus">
      <RPButton theme="custom" @click="setFocus"
        class="h-16 min-w-[64px] w-16 p-0 border border-card-outline bg-primary-light">
        <RPIcon type="keypad" noFill />
      </RPButton>
      <p class="pt-2 text-center w-32">
        {{ t('paymentPin.cta') }}
      </p>
    </div>
  </div>

  <Dialog v-if="dialogOpen" @close="dialogOpen = false" class="modal">
    <h2>{{ t('paymentPin.dialog.title') }}</h2>
    <p>{{ t('paymentPin.dialog.text') }}</p>
    <RPButton @click="resetPin()">
      {{ t('paymentPin.dialog.btn_reset') }}
    </RPButton>
    <RPButton theme="secondary" v-if="props.onSwitchToPassword" @click="props.onSwitchToPassword">
      {{ t('paymentPin.dialog.btn_password') }}
    </RPButton>
    <RPButton link @click="dialogOpen = false">
      {{ t('paymentPin.dialog.btn') }}
    </RPButton>
  </Dialog>
</template>

<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import Dialog from '@/components/Atoms/Dialog.vue';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'

const props = defineProps({
  errorMsg: {
    type: String,
    default: null
  },
  pinRestHint: {
    type: Boolean,
    default: false
  },
  onSwitchToPassword: {
    type: Function,
    default: null
  }
})

const router = useRouter()
const { t } = useI18n();

const emits = defineEmits(['pin'])

const maxLength = ref<number>(4)
const changed = ref<boolean>(false)
const dialogOpen = ref<boolean>(false)
const errorOnlyNumbers = ref<string>('')
const inputRef = ref<HTMLInputElement | null>(null)

onMounted(() => {
  setFocus();
})

const setFocus = () => {
  const input = inputRef.value
  if (input) {
    input.focus()
  }
}

/**
  * Some Android soft keyboards don't have KEYPRESS event
  * Same as the event.key, some keys return "Unidentified"
  * So we have to rely only on the input change event
  *
  * @see https://developer.android.com/training/keyboard-input/commands.html
*/
const checkSubmit = (event: InputEvent) => {
  changed.value = true;
  errorOnlyNumbers.value = '';

  // prevent typing letters
  if (!/[0-9]/.test(event.data) && event.data !== null) {
    errorOnlyNumbers.value = t('paymentPin.validate.error_only_numbers');
    const deleteLetters = inputRef.value.value.replace(event.data, '');
    inputRef.value.value = deleteLetters;
  }

  // prevent copy/paste text with letters
  if (!/^[0-9]*$/.test(inputRef.value.value) && event.data === null) {
    errorOnlyNumbers.value = t('paymentPin.validate.error_only_numbers');
    inputRef.value.value = '';
    return;
  }

  if (inputRef.value.value.length === maxLength.value) {
    emits('pin', inputRef.value.value);
    changed.value = false;
  }
}

const error = computed(() => !changed.value ? props.errorMsg : null)

const resetPin = () => {
  router.push('/profile');
}
</script>

<style lang="scss" scoped>
.pincode {
  font-size: 50px;
  text-align: center;
  letter-spacing: 35px;
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
  margin-top: 15px;
  background-color: var(--colorCardBg);
  &.errorShake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
  border: 1px solid var(--colorOutline);

  &:focus ~ .button__setfocus {
    display: none;
  }
}

.error {
  text-align: center;
  max-width: 50%;
  margin: 0 auto;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  padding-top: 20px;
}

.pin_forgotten {
  margin-top: 30px;
}

.modal {
  button {
    margin-top: 15px;
    width: 100%;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
