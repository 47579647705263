<template>
  <header
    class="
      h-11 l-scape:h-20
      grid grid-cols-[50px_auto_50px] grid-rows-[44px] l-scape:grid-cols-[80px_auto_80px]
      bg-topbar-bg
      text-topbar-text
      l-scape:my-0 l-scape:mx-auto
      l-scape:max-w-[calc(var(--appMaxWidthLandscape)_-_var(--space-xxl)_*_2)] l-scape:w-full"
  >
    <div>
      <PartnerLogo v-if="path === '/landing' && partnerName" :partner="partnerName" />

      <div id="topBarIconLeft" />
      <RPButton
        v-if="backBtnVisible"
        theme="custom"
        class="p-0 min-w-min"
        data-cy="backButton"
        @click="clickOnBackBtn"
      >
        <RPIcon type="arrowLeft" svgFill="text" class="l-scape:w-20 l-scape:h-20" />
      </RPButton>
      <RPButton
        v-else-if="path === '/pay'"
        theme="custom"
        class="p-0 min-w-min"
        data-cy="goToProfile"
        @click="$router.push('/profile')"
      >
        <RPIcon type="imageProfile" svgFill="text" class="relative l-scape:w-20 l-scape:h-20" />
      </RPButton>
    </div>
    <!-- Title -->
    <h3
      id="topBarTitle"
      class="flex justify-center items-center h-full font-bold l-scape:text-fs-title l-scape:mb-0"
      data-cy="headerTitle"
    >
      {{ title }}
    </h3>
    <div id="topBarIconRight">
      <!-- Lang Flag -->
      <template v-if="langBtnVisible">
        <RPButton theme="custom" class="p-0 min-w-min rounded-none" data-cy="openLangSwitcher" @click="clickLanguage">
          <RPIcon :type="`flags/${selectedLanguage}`" no-fill class="l-scape:w-20 l-scape:h-20" />
        </RPButton>
      </template>
    </div>
  </header>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import PartnerLogo from '@/components/Atoms/PartnerLogo.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import { resetOrderURLparams, resetPaymentsURLparams } from '@/utils/utils';

export default {
  components: { PartnerLogo, RPIcon, RPButton },
  computed: {
    ...mapState({
      username: state => state.auth.firstName,
      pid: state => state.ui.query.pid,
      brand: state => state.ui.query.brand,
      transactionHistoryFilterActive: state => state.ui.transactionHistoryFilterActive,
      partnerName: state => state.ui.query.partner,
      isLandscape: state => state.ui.query.landscape,
      previousRoute: state => state.ui.previousRoute
    }),
    ...mapGetters({
      payFlowPages: 'pay/payFlowPages'
    }),
    path() {
      return this.$route.path;
    },
    backBtnVisible() {
      const pathsToShow = ['/pay', '/change-password', '/payment-method', '/settings'];

      const pathsToHide = [
        '/pay/finish',
        '/pay/pump',
        '/payment-register',
        '/pay/prepay/summary',
        '/pay/postpay/summary',
      ];

      return (
        pathsToShow.some(path => this.path.startsWith(path)) && pathsToHide.every(path => !this.path.startsWith(path))
      );
    },
    langBtnVisible() {
      const pathsToShow = ['/landing', '/onboarding', '/settings'];
      return pathsToShow.some(path => this.path.startsWith(path));
    },
    title() {
      const pathsMap = {
        '/address': this.$t('menu.address'),
        '/tax': this.$t('menu.tax'),
        '/payment-method': this.$t('menu.payment'),
        '/payment-method/mbway': 'MB Way',
        '/settings': this.$t('menu.settings'),
        '/pay/postpay/fueling': this.$t('fueling.fueling.title'),
        '/pay/prepay/fueling': this.$t('fueling.fueling.title'),
        '/pay/prepay/fuelselection': this.$t('fueling.fuelSelection.title'),
        '/pay/prepay/amount': this.$t('fueling.amountSelection.title'),
        '/pay/finish': this.$t('menu.payFinish'),
        '/maintenance': this.$t('maintenance.title')
      };
      return pathsMap[this.path] || null;
    },
    selectedLanguage() {
      return this.$root.$i18n.locale;
    }
  },
  methods: {
    clickOnBackBtn() {
      const paramWithoutPM = resetPaymentsURLparams(this.$route.query);

      if (this.previousRoute === '/onboarding' && this.path === '/settings') {
        return this.$router.replace('/pay');
      }
      if (this.previousRoute === '/' || this.previousRoute === '/error') {
        return this.$router.replace('/pay');
      }

      if (this.path.includes('/payment-method') && this.previousRoute === '/payment-method/mbway') {
        return this.$router.push({ path: '/pay', query: paramWithoutPM });
      }

      if (this.path.startsWith('/pay/')) {
        const paramsWithoutOrder = resetOrderURLparams(this.$route.query);
        return this.$router.push({ path: this.payFlowPages.previousPagePath, query: paramsWithoutOrder });
      }
      this.$router.push(this.previousRoute);
    },
    clickFilters() {
      this.$store.commit('ui/setSidebar', { type: 'transactionHistoryFilter', visible: true });
    },
    clickLanguage() {
      this.$store.commit('ui/setSidebar', { type: 'language', visible: true });
    }
  }
};
</script>
<style lang="scss" scoped>
.notification {
  position: relative;
  &::before {
    content: '';
    background-color: var(--colorPrimaryBtnBg);
    width: 7px;
    height: 7px;
    position: absolute;
    right: 15px;
    top: -12px;
    border-radius: 50%;
  }
}
</style>
