<template>
  <div class="view-block px-6">
    <div>
      <RydLogo />
      <h1>{{ $t('landing.headline') }}</h1>
      <hr class="block bg-primary-accent h-1 w-[123px] border-0 mt-9 mb-lg" />
      <ul>
        <li class="landing__list">
          <span>{{ $t('landing.list.1') }}</span>
        </li>
        <li class="landing__list">
          <span>{{ $t('landing.list.2') }}</span>
        </li>
        <li class="landing__list">
          <span>{{ $t('landing.list.3') }}</span>
        </li>
      </ul>
    </div>
    <div class="text-center">
      <RPButton @click="registerGuest" class="mb-2" data-cy="continueGuest">
        {{ $t('landing.guest_cta') }}
      </RPButton>
      <RPButton href="/register" class="mb-2" data-cy="goToRegister" outline>
        {{ $t('landing.register_login_cta') }}
      </RPButton>
      <RPButton link href="/onboarding" data-cy="goToOnboarding" class="mt-6 ">
        {{ $t('landing.onboarding_cta') }}
      </RPButton>
    </div>
  </div>
</template>

<script>
import RydLogo from '@/components/Atoms/RydLogo.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import { logout } from '@/api/auth';
import { mapActions } from 'vuex';

export default {
  components: { RydLogo, RPButton },
  methods: {
    ...mapActions({
      loginGuest: 'auth/loginGuest'
    }),
    async registerGuest() {
      await this.loginGuest();
      this.$router.push('/pay');
    }
  },
  mounted() {
    const storageToken = localStorage.getItem('auth');
    if (storageToken) {
      logout();
    }
  }
};
</script>

<style lang="scss" scoped>
.landing__list {
  position: relative;
  margin-bottom: 13px;
  padding-left: 30px;
}

.landing__list:before {
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left -2px;
  -webkit-mask-image: url('../assets/icons/check_primary.svg?external');
  mask-repeat: no-repeat;
  mask-position: left -2px;
  mask-image: url('../assets/icons/check_primary.svg?external');
  background-color: var(--colorCheckmarkPrimary);
  content: '';
  position: absolute;
  left: 0px;
  height: 20px;
  width: 20px;
}
</style>
