<template>
  <div class="text-left px-6 view-block">
    <div>
      <h3>{{ $t('passwordvalidation.title') }}</h3>
      <TextField
        v-model="password"
        type="password"
        :label="$t('account.password_label')"
        autocomplete="current-password"
        rules="required"
        :custom-error="error"
        :class="{ errorShake: error }" />
    </div>
    <RPButton @click="validate"> {{ $t('errors.button_ok') }}</RPButton>
  </div>
</template>

<script>
import TextField from '@/components/Atoms/TextField.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';

import { login } from '@/api/auth';

export default {
  components: { TextField, RPButton },
  props: {
    onFinish: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      error: null,
      password: null
    };
  },
  methods: {
    async validate() {
      this.error = null;

      try {
        this.$store.commit('ui/setLoading', true);
        await login(this.$store.state.auth.email, this.password, true);

        // Push finish callback on nextTick to done it after the finally-block
        this.$nextTick(() => this.onFinish());
      } catch (e) {
        console.debug(e);
        this.error = this.$t('errors.password_not_correct');
      } finally {
        this.$store.commit('ui/setLoading', false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.errorShake) {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
</style>
