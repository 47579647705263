<template>
  <div
    class="rp-card"
    :class="[
      {
        'cursor-pointer rp-card__clickable': hasClick,
        'bg-card-bg min-h-[56px]': small,
      },
    ]"
    v-bind="{ ...$attrs }"
  >
    <div 
      v-if="hasTitle" 
      class="bg-primary-darker
          border-b border-card-outline rounded-t-xl
          absolute w-full top-0 
          font-medium rp-fs-12-ln pl-4 h-6
          flex items-center"
    >
      <slot name="title" />
    </div>
    <div :class="{ 'rp-card--small': small }">
      <slot />
    </div>
    <slot name="button" />
  </div>
</template>
<script lang="ts">
export default {
  props: {
    hasClick: {
      type: Boolean,
      default: false,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  }
};
</script>
<style lang="scss">
.rp-card {
  @apply rounded-xl border border-solid border-card-outline
         relative text-primary-text 
         overflow-y-hidden overflow-x-hidden
         bg-card-bg;
  -webkit-tap-highlight-color: transparent;
  // Overflow hidden + round corners not working on Safari
  // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  &__clickable:after {
    content: '';
    @apply block absolute opacity-0 top-0 left-0 h-full w-full;
    background: rgba(0, 0, 0, 0.2);
    transition: all 0.8s;
  }

  &__clickable:active:after {
    opacity: 1;
    transition: 0s;
  }

  &--small {
    @apply px-4 py-1 flex items-center justify-between w-full;
  }
}
</style>  