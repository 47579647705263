<template>
  <div class="mt-4">
    <RPButton
      theme="custom"
      type="button"
      :disabled="disabled"
      @click="click"
      class="bg-white h-10 mx-auto text-black space-x-2 space-y-1">
      <RPIcon type="apple-logo" noFill />
      <div>{{ $t('signIn.login.cta', { provider: 'Apple' }) }}</div>
    </RPButton>
  </div>
</template>

<script>
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import { loadScript } from '@/utils/externalScriptLoader.js';

export default {
  components: { RPButton, RPIcon },
  props: {
    checkBeforeCallback: {
      type: Function,
      default: null
    }
  },
  emits: ['token'],
  data() {
    return {
      disabled: true
    };
  },
  async mounted() {
    try {
      if (window.AppleID) {
        await this.initAppleSSO();
        return;
      }
      await loadScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
      this.initAppleSSO();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initAppleSSO() {
      await window.AppleID.auth.init({
        clientId: import.meta.env.VUE_APP_APPLE_CLIENTID,
        redirectURI: window.location.origin.concat(window.location.pathname),
        scope: 'name email',
        usePopup: true
      });
      this.disabled = false;
    },
    async click() {
      if (this.checkBeforeCallback) {
        const check = await this.checkBeforeCallback();
        if (!check) {
          return;
        }
      }
      // https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
      try {
        const { authorization, user } = await window.AppleID.auth.signIn();
        if (user) {
          this.$emit('token', authorization.id_token, {
            email: user.email,
            firstName: user.name.firstName,
            lastName: user.name.lastName
          });
        } else {
          this.$emit('token', authorization.id_token);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
