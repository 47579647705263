import axios from './index';

// Docs: https://tt4.thinxcloud-dev.de/doc/#api-Users
export async function getUser(id) {
  const { data } = await axios.get(`/users/${id}`);
  return data;
}

export async function updateUser(id, updatePayload) {
  return axios.patch(`/users/${id}`, updatePayload);
}

export async function exportEvents(thingId) {
  await axios.get(`/events/request-data-dump?thingId=${thingId}`);
}
