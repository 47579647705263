import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import { isEmpty, omit } from 'lodash';
import { userLanguage } from '@/i18n';

import NotFound from './views/NotFound.vue';
import Landing from './views/Landing.vue';
import Onboarding from './views/Onboarding.vue';

// App
import Address from './views/App/Address.vue';
// Profile
import Profile from './views/App/Profile/Profile.vue';
import ProfileManage from './views/App/Profile/ProfileManage.vue';
import ProfilePayments from './views/App/Profile/ProfilePayments.vue';
import ProfileLoyalty from './views/App/Profile/ProfileLoyalty/ProfileLoyalty.vue';
import LoyaltyAdd from './views/App/Profile/ProfileLoyalty/LoyaltyAdd.vue';
import VoucherList from './views/App/Profile/ProfileVoucher/VoucherList.vue'
import VoucherCustomAdd from './views/App/Profile/ProfileVoucher/VoucherCustomAdd.vue'
import PaymentMethodAdd from './views/App/PaymentMethodAdd.vue';
import PaymentMethodEdit from './views/App/PaymentMethodEdit.vue';
import MBwayPaymentMethod from './views/App/MBwayPaymentMethod.vue';
import ChangePassword from './views/App/ChangePassword.vue';
import Settings from './views/App/Settings.vue';
import Error from './views/Error.vue';
import Maintenance from './views/Maintenance.vue';

import PaymentRegister from './views/App/PaymentRegister/ConnectedPaymentRegister.vue';

// Auth
import ConnectedLoginRegister from './views/Auth/LoginRegister/ConnectedLoginRegister.vue';
import RegisterMail from './views/Auth/RegisterMail.vue';
import ForgottenPassword from './views/Auth/ForgottenPassword.vue';
import ChangeTmpPassword from './views/Auth/ChangeTmpPassword.vue';

import History from './views/App/Profile/History/History.vue';
import HistoryDetails from './views/App/Profile/History/HistoryDetails.vue';
import HistoryCreate from './views/App/Profile/History/HistoryCreate.vue';
import HistoryError from './views/App/Profile/History/Error.vue';

// Pay
import PumpSelection from './views/Pay/1_PumpSelection.vue';
import Finish from './views/Pay/Finish.vue';
import Pay from './views/Pay/Pay.vue';
import FuelSelection from './views/Pay/Prepay/2_FuelSelection.vue';
import AmountSelection from './views/Pay/Prepay/3_AmountSelection.vue';
import SummaryPrepay from './views/Pay/Prepay/4_Summary.vue';
import FuelingPrepay from './views/Pay/Prepay/5_Fueling.vue';
import FuelingPostpay from './views/Pay/Postpay/2_Fueling.vue';
import SummaryPostpay from './views/Pay/Postpay/3_Summary.vue';
import AmazonSSORedirectPage from './views/Auth/AmazonSSORedirectPage.vue'

const routesContainSupportButton = ['/landing', '/login', '/register', '/settings'];

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: ({ query }) => {
        // if user comes from mpgs sca
        if (query.sca_feedback) {
          const newQuery = omit(query, 'sca_feedback');
          store.dispatch('ui/saveURLParamsForUi');
          return { path: decodeURIComponent(query.sca_feedback), query: newQuery };
        }
        return { path: '/pay' };

      }
    },
    {
      path: '/pay',
      component: Pay,
      redirect: '/pay/pump',
      meta: { requiresAuth: true, requiresPoi: true },
      children: [
        // Shared for both Flows
        { path: 'pump', component: PumpSelection },
        { path: 'finish', component: Finish },

        // Prepay
        { path: 'prepay/fuelselection', component: FuelSelection },
        { path: 'prepay/amount', component: AmountSelection },
        { path: 'prepay/summary', component: SummaryPrepay },
        { path: 'prepay/fueling', component: FuelingPrepay },

        // Postpay
        { path: 'postpay/fueling', component: FuelingPostpay },
        { path: 'postpay/summary', component: SummaryPostpay }
      ]
    },
    {
      path: '/dashboard',
      redirect: '/pay',
    },
    {
      path: '/landing',
      component: Landing
    },
    {
      path: '/onboarding',
      component: Onboarding
    },
    {
      path: '/register/mail',
      component: RegisterMail
    },
    {
      path: '/register',
      component: ConnectedLoginRegister,
    },
    {
      name: 'login',
      path: '/login',
      component: ConnectedLoginRegister,
      props: true
    },
    {
      name: 'amazonSSORedirect',
      path: '/amazonSSORedirect',
      component: AmazonSSORedirectPage,
      props: true
    },
    {
      path: '/forgotten-password',
      component: ForgottenPassword
    },
    {
      path: '/change-tmp-password',
      component: ChangeTmpPassword
    },
    {
      path: '/address',
      component: Address,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/change-password',
      component: ChangePassword,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/error',
      component: Error
    },
    {
      path: '/maintenance',
      component: Maintenance
    },
    {
      path: '/profile',
      component: Profile,
      meta: { requiresAuth: true, onlyRegisterUser: true },
    },
    {
      path: '/profile/manage',
      component: ProfileManage,
      meta: { requiresAuth: true, onlyRegisterUser: true },
    },
    {
      path: '/profile/payments',
      component: ProfilePayments,
      meta: { requiresAuth: true, onlyRegisterUser: true },
    },
    {
      path: '/profile/loyalty',
      component: ProfileLoyalty,
      meta: { requiresAuth: true, onlyRegisterUser: true },
    },
    {
      path: '/profile/loyalty/add',
      component: LoyaltyAdd,
      meta: { requiresAuth: true, onlyRegisterUser: true },
    },
    {
      path: '/profile/vouchers',
      component: VoucherList,
      meta: { requiresAuth: true, onlyRegisterUser: true },
    },
    {
      path: '/profile/vouchers/add',
      component: VoucherCustomAdd,
      meta: { requiresAuth: true, onlyRegisterUser: true },
    },
    {
      path: '/profile/payments/edit',
      component: PaymentMethodEdit,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/payment-method',
      component: PaymentMethodAdd,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/payment-method/mbway',
      component: MBwayPaymentMethod,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/payment-register',
      component: PaymentRegister,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/settings',
      component: Settings,
      meta: { requiresAuth: true }
    },
    {
      path: '/history',
      component: History,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/history/create',
      component: HistoryCreate,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/history/:id',
      component: HistoryDetails,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/history/error',
      component: HistoryError,
      meta: { requiresAuth: true, onlyRegisterUser: true }
    },
    {
      path: '/:pathMatch(.*)',
      component: NotFound
    }
  ]
});

function enableTracking() {
  try {
    if (router.app.$matomo) {
      router.app.$matomo.setCustomDimension(2, store.state.ui.query.partner);
      router.app.$matomo.rememberConsentGiven();
    }
  } catch (e) {
    console.debug(e);
  }
}

const hasQueryParams = (route) => {
  return !!Object.keys(route.query).length;
};

import { resetOrders, redirectToCallbackUrl } from './utils/utils';

const paramsAllowedOnEnterApp = ['pid', 'mtskid', 'longitude'];

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  store.commit('ui/setPreviousRoute', from.path);

  // We decide to show support button only in some pages
  // https://developer.zendesk.com/api-reference/widget/core/#hide
  if (typeof window.zE === 'function') {
    !routesContainSupportButton.includes(to.path) ? window.zE('webWidget', 'hide') : window.zE('webWidget', 'show');
  }

  // Check requiresAuth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isForced = to.meta.force ? true : false;

    try {
      await store.dispatch('auth/checkAuthStatus', isForced);
      enableTracking();
    } catch (error) {
      store.dispatch('auth/clearAllStore')
      await store.dispatch('auth/loginGuest');
    }
  }

  // check POI URL
  if (to.matched.some(record => record.meta.requiresPoi) && to.path !== '/error') {

    const checkPOIInParamsTo = Object.keys(to.query).some(param => paramsAllowedOnEnterApp.includes(param));
    const checkPOIInParamsFrom = Object.keys(from.query).some(param => paramsAllowedOnEnterApp.includes(param));
    const checkPOiInStore = paramsAllowedOnEnterApp.some(param => !isEmpty(store.state.ui.query[param]));

    const isPOIPresent = checkPOIInParamsTo || checkPOIInParamsFrom || checkPOiInStore;

    if (!isPOIPresent) {
      // if during checkout the page is refreshed and URL not contain any pid
      // we remove the order saved in the local storage

      resetOrders();
      const redirectToPartner = to.query.callback && to.query.hideRydMapLink;

      if (redirectToPartner) {
        try {
          redirectToCallbackUrl(to.query.callback);
        } catch (e) {
          window.location.href = `${import.meta.env.VUE_APP_MAP}`;
        }

        return next(false);
      }
      // to.query or from.query is empty while accessing the app with /
      // so here we use the location.search
      const getUserLang = userLanguage()

      const url = window.location.search
      const params = new URLSearchParams(url);

      params.append("lang", getUserLang);
      window.location.href = `${import.meta.env.VUE_APP_MAP}/?${params}`

      return next(false);
    }

  }

  // Check onlyRegisterUser
  if (to.matched.some(record => record.meta.onlyRegisterUser)) {
    if (store.state.auth.isGuest) return next({ path: '/login', query: to.query });
  }
  // We persist the parameters through all the pages

  if (!hasQueryParams(to) && hasQueryParams(from) && !from.query.reset) {
    next({ ...to, query: from.query });
  } else {
    // The only way to delete all the query params while navigating form ULR with param to URL with no param
    // is to pass a "reset" fake param and delete it in the next navigation
    // issue discovered on MBWay add PM RP-1672
    delete from.query.reset
    delete to.query.reset
    next();
  }
});

export default router;
