<template>
  <div class="absolute flex flex-col items-center bottom-28 mx-auto w-full left-0">
    <RPButton
      theme="custom"
      @click="$emit('click', $event)"
      class="h-16 min-w-[64px] w-16 p-0 border border-card-outline bg-primary-light">
      <RPIcon v-if="icon === 'keypad'" type="keypad" noFill />
      <RPIcon v-if="icon === 'fingerprint'" type="fingerprint" noFill />
    </RPButton>
    <span class="pt-2 text-center">
      <slot />
    </span>
  </div>
</template>

<script>
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';

export default {
  components: { RPIcon, RPButton },
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  emits: ['click']
};
</script>