<template>
  <div class="prepay-fueling px-6" :class="{ landscape: isLandscape }">
    <Dialog v-if="dialogOpen">
      <h2>{{ $t('fueling.fueling.cancel_title') }}</h2>
      <p class="pb-4" v-html="$t('fueling.fueling.cancel_text')" />
      <RPButton primary @click="cancel()">
        {{ $t('fueling.finish.button_ok') }}
      </RPButton>
    </Dialog>
    <div class="prepay-fueling__wrapper">
      <Drop :pump="chosenPump" class="prepay-fueling__drop" />
      <div class="prepay-fueling__hint">
        <h2 class="my-5">
          {{ $t('fueling.fueling.subtitle') }}
        </h2>
        <span class="font-medium">{{ $t('fueling.fueling.text', { maxAmount }) }}</span>
      </div>
    </div>
    <footer
      v-if="isLandscape"
      class="prepay-fueling__footer sm:bottom-[var(--footerMinHeight)] bottom-[var(--footerMaxHeight)]"
    >
      <div class="prepay-fueling__footer-container">
        <RPButton theme="secondary" class="ml-0" @click="dialogOpen = true">
          {{ $t('fueling.fueling.button_cancel') }}
        </RPButton>
      </div>
    </footer>
    <footer v-else class="mt-auto">
      <RPButton link class="my-4" @click="dialogOpen = true">
        {{ $t('fueling.fueling.button_cancel') }}
      </RPButton>
    </footer>
  </div>
</template>
<script>
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import Drop from '@/components/Atoms/Drop.vue';
import Dialog from '@/components/Atoms/Dialog.vue';

import { currencyString, resetOrderURLparams } from '@/utils/utils.js';
import { mapState, mapGetters } from 'vuex';

export default {
  components: { RPButton, Drop, Dialog },
  data: function () {
    return {
      dialogOpen: false
    };
  },
  computed: {
    ...mapState({
      isLandscape: state => state.ui.query.landscape,
      oderId: state => state.pay.orderStatus.id
    }),
    ...mapGetters({
      payFlowPages: 'pay/payFlowPages'
    }),
    chosenPump() {
      return this.$store.getters['station/chosenPump']?.name;
    },
    maxAmount() {
      return currencyString(
        this.$store.state.pay.orderStatus.productRequest?.price.amount,
        this.$store.state.pay.orderStatus.productRequest?.price.currency
      );
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventPageReload)
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.preventPageReload)
  },
  async mounted() {
    try {
      // If coming from external page try to resume the order
      if (this.$route.query.orderId) {
        await Promise.all([
          this.$store.dispatch('pay/resume', this.$route.query.orderId),
          this.$store.dispatch('station/getOnce')
        ]);

        const pumpId = this.$store.state.pay.orderStatus.productRequest?.pump.id;
        if (pumpId) {
          this.$store.commit('station/saveURLParamsForTrackOrder', { pumpId });
        }
        const fuelTypeId = this.$store.state.pay.orderStatus.productRequest?.fuel?.id;
        if (fuelTypeId) {
          this.$store.commit('station/saveURLParamsForTrackOrder', { fuelTypeId });
        }
      }

      await this.$store.dispatch('pay/fuel');
      const nextPagePath = this.payFlowPages.nextPagePath;
      this.$router.replace({
        path: nextPagePath,
        query: { ...this.$route.query, orderId: this.oderId, orderType: 'PREPAY' }
      });
    } catch (e) {
      if (e === 'ORDER_CANCELED') return this.$router.replace({ path: '/pay' });
      const paramsToKeep = resetOrderURLparams(this.$route.query);
      this.$router.push({
        path: '/error',
        query: { type: 'fueling_prepay', ...paramsToKeep }
      });
      console.error(e);
    }
  },
  methods: {
    async cancel() {
      await this.$store.dispatch('pay/cancel');
      this.dialogOpen = false;
    },
    preventPageReload(event) {
      event.preventDefault()
      event.returnValue = ''
    }
  }
};
</script>
<style lang="scss" scoped>
.prepay-fueling.landscape {
  .prepay-fueling {
    &__wrapper {
      display: flex;
      align-items: center;
      max-width: var(--appMaxWidthContainerLandscape);
      margin: 0 auto;
    }

    &__hint,
    &__hint h2 {
      flex: 3;
      text-align: left;
    }

    &__drop {
      flex: 1;
      height: 220px;
      margin-right: 50px;
      font-size: 140px;
    }

    &__footer {
      position: absolute;
      left: 0;
      width: 100vw;
      background-color: var(--colorPrimaryBgDarker);
      padding: var(--space-md) 0;
    }

    &__footer-container {
      max-width: var(--appMaxWidth);
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
