<template>
  <section class="view-block">
    <div>
      <h3>{{ $t('webauthn.login.title') }}</h3>

      <div class="opacity-50 pt-xxl">
        <RPIcon svg-fill="text" type="fingerprint_big" />
      </div>
      <p v-if="error" class="text-error pt-4"> {{ $t('errors.generic.text') }}</p>
    </div>
    <RPButton link class="my-4" @click="dialog_forgotten = true">
      {{ $t('webauthn.forgotten.title') }}
    </RPButton>
    <div class="flex-center-col">
      <RPButton
        theme="custom"
        @click="webauthn"
        class="h-16 min-w-[64px] w-16 p-0 border border-card-outline bg-primary-light">
        <RPIcon type="fingerprint" noFill />
      </RPButton>
      <p class="pt-2 text-center w-32">
        {{ $t('webauthn.btn_cta') }}
      </p>
    </div>

  </section>
  <Dialog v-if="dialog_forgotten" @close="dialog_forgotten = false">
    <h2>{{ $t('webauthn.forgotten.title') }}</h2>
    <p>{{ $t('webauthn.forgotten.text') }}</p>
    <RPButton class="mt-4" @click="$router.replace('/payment-method')"> {{ $t('webauthn.btn_change') }} </RPButton>
    <p />
    <RPButton theme="secondary" class="mt-2" @click="dialog_forgotten = false"> {{ $t('webauthn.forgotten.btn_close') }} </RPButton>
  </Dialog>
  <Dialog v-if="dialog_unsupported">
    <h2>{{ $t('errors.webauthn_unsupported.title') }}</h2>
    <p>{{ $t('errors.webauthn_unsupported.text') }}</p>
    <RPButton class="mt-4" @click="$router.replace('/payment-method')"> {{ $t('webauthn.btn_change') }} </RPButton>
  </Dialog>
</template>

<script>
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import { supportsWebauthn } from '@/utils/utils';
import { startAuthentication } from '@simplewebauthn/browser';
import { generateLoginOptions, loginWebauthn } from '@/api/pin';

import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import Dialog from '@/components/Atoms/Dialog.vue';
import BottomButton from '@/components/Atoms/BottomButton.vue';

export default {
  components: { RPIcon, RPButton, Dialog, BottomButton },
  props: {
    onFinish: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      error: null,
      dialog_forgotten: false,
      dialog_unsupported: false
    };
  },
  computed: {
    userId() {
      return this.$store.state.auth.id;
    }
  },
  async mounted() {
    if (await supportsWebauthn()) {
      return this.webauthn();
    }

    this.dialog_unsupported = true;
  },
  methods: {
    async webauthn() {
      this.$store.commit('ui/setLoading', true);
      this.error = null;
      try {
        await this.login();
        this.$nextTick(() => this.onFinish());
      } catch (e) {
        this.error = true;
        console.log(e);
      } finally {
        this.$store.commit('ui/setLoading', false);
      }
    },

    async login() {
      const options = await generateLoginOptions({ userId: this.userId });
      const attResp = await startAuthentication(options);

      await loginWebauthn({
        options: attResp,
        userId: this.userId
      });
    }
  }
};
</script>
