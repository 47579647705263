import { amazonCreateSignature } from '@/api/payments';
import { omit } from 'lodash';
// doc https://developer.amazon.com/docs/amazon-pay-apb-checkout/add-the-amazon-pay-button.html#code-sample



const buildUrls = (query) => {
  // TODO: cancel URL could be an error or user cancel the amazon pay
  // add logic to redirect to error page or to the summary page
  // https://developer.amazon.com/docs/amazon-pay-api-v2/checkout-session.html#states-and-reason-codes

  const paramsToKeep = omit(query, ['amazonCheckoutSessionId'])
  const paramsToString = new URLSearchParams(paramsToKeep).toString();
  const successRoute = query.orderType === 'POSTPAY' ? 'pay/postpay/summary' : 'pay/prepay/summary';

  const successUrl = `${import.meta.env.VUE_APP_PSP_REDIRECT_URL}?sca_feedback=${encodeURIComponent(
    successRoute
  )}&${paramsToString}`;

  const cancelUrl = `${import.meta.env.VUE_APP_PSP_REDIRECT_URL}?sca_feedback=${encodeURIComponent(
    successRoute
  )}&amazonIsCancel=true&${paramsToString}`;

  return {
    success: successUrl,
    cancel: cancelUrl
  };

};

const getSignature = async (currency, amount, query) => {
  const { success, cancel } = buildUrls(query);
  return await amazonCreateSignature(currency, amount, success, cancel);
};

export const amazonButton = async (element, lang, currency, amount, query) => {

  const signature = await getSignature(currency, amount, query);
  // For when guest user
  // https://amazonpaylegacyintegrationguide.s3.amazonaws.com/docs/amazon-pay-onetime/button-widgets.html#scope
  return amazon.Pay.renderButton(element, {
    ledgerCurrency: 'EUR',
    merchantId: 'A236O3KCLVYNU4',
    placement: 'Checkout',
    productType: 'PayOnly',
    checkoutLanguage: lang,
    buttonColor: 'LightGray',
    publicKeyId: import.meta.env.VUE_APP_AMAZON_PUBLIC,
    createCheckoutSessionConfig: {
      ...signature
    }
  });
};
