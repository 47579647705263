<template>
  <Teleport to="#topBarIconLeft" data-cy="addressBack">
    <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" href="/register">
      <RPIcon svg-fill="text" type="arrowLeft" />
    </RPButton>
  </Teleport>
  <Form ref="registerForm" class="view-block px-4" @submit="register" @keyup.enter="register">
    <div>
      <h2>{{ $t('signIn.register.headline') }}</h2>
      <span v-if="error" class="text-error" data-cy="error-message">
        {{ error }}
      </span>
      <TextField
        v-model="model.firstName"
        :label="$t('account.firstname_label')"
        rules="required"
        autocomplete="given-name"
      />
      <TextField v-model="model.email" type="email" :label="$t('account.email_label')" rules="required|email" />
    </div>
    <div class="text-center mt-auto">
      <RPButton
        theme="custom"
        class="text-primary-btn-text
            bg-primary-btn-bg"
        type="submit"
        data-cy="registerWithEmail"
      >
        {{ $t('account.register_cta') }}
      </RPButton>
    </div>
  </Form>
  <Dialog v-if="dialog" data-cy="dialogTempPassword" @close="dialog = false">
    <p>
      {{ $t('notifications.account_created') }}
    </p>
    <RPButton class="mt-6" data-cy="dialogButton" @click="$router.push('/login')">
      {{ $t('profile.password.success.button_ok') }}
    </RPButton>
  </Dialog>
</template>
<script>
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import Dialog from '@/components/Atoms/Dialog.vue';
import TextField from '@/components/Atoms/TextField.vue';

import { Form } from 'vee-validate';

export default {
  components: { RPButton, RPIcon, Dialog, TextField, Form },
  data() {
    return {
      model: {
        firstName: null,
        email: this.$store.state.ui.query.email
      },
      error: null,
      dialog: false
    };
  },
  methods: {
    startTracking() {
      try {
        this.$matomo.rememberConsentGiven();
        this.$matomo.trackEvent('Registration', 'NewUser', localStorage.getItem('partner'));
      } catch (e) {
        console.debug(e);
      }
    },
    async register() {
      const { valid } = await this.$refs.registerForm.validate();

      if (!valid) {
        return false;
      }

      try {
        await this.$store.dispatch('auth/register', {
          ...this.model,
          countryCode: this.$store.state.register.country,
          registrationMethod: 'email'
        });
        this.startTracking();

        localStorage.setItem('lastUserEmail', this.model.email);

        this.dialog = true;
      } catch (e) {
        console.log(e);
        if (e.message === 'email_has_account') {
          this.error = this.$i18n.t('warnings.email_has_account');
        } else {
          this.error = this.$i18n.t('errors.generic.text');
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.buttons {
  text-align: center;
  margin-top: auto;
}

.error-message {
  color: var(--colorError);
}
</style>
