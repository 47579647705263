<template>
  <!-- https://tailwindcss.com/docs/hover-focus-and-other-states#styling-based-on-parent-state -->
  <div id="app" class="l-scape" :data-landscape="isLandscape" :class="[{ landscape: isLandscape }, partnerName]">
    <div class="app__wrapper flex flex-col my-0 mx-auto pb-12" :class="{ landscape: isLandscape }">
      <TopBar />
      <div
        v-if="appIsReady"
        class="flex flex-col items-center grow l-scape:w-full l-scape:pb-0 l-scape:my-0 l-scape:mx-auto">
        <router-view />
      </div>

    </div>
    <FooterNav />
    <LoadingOverlay v-show="loading" />
    <transition name="fade">
      <LanguageSideBar v-show="visibleLanguageSideBar" />
    </transition>
  </div>
</template>
<script>
import FooterNav from './components/Layout/FooterNav.vue';
import LoadingOverlay from './components/Atoms/LoadingOverlay.vue';
import LanguageSideBar from './components/Layout/LanguageSideBar.vue';
import TopBar from './components/Layout/Topbar.vue';
import axios from 'axios';
import { loadScript } from './utils/externalScriptLoader.js';

import { setLanguage, userLanguage } from './i18n.js';

export default {
  components: { FooterNav, LoadingOverlay, LanguageSideBar, TopBar },
  data() {
    return {
      appIsReady: false
    };
  },
  computed: {
    loading() {
      return this.$store.state.ui.loading;
    },
    visibleLanguageSideBar() {
      return this.$store.state.ui.sidebars.language;
    },
    partnerName() {
      return this.$store.state.ui.query.partner;
    },
    isLandscape() {
      return this.$store.state.ui.query.landscape;
    }
  },
  async beforeCreate() {
    // Need to wait for router ready in order to grab the URL params
    // We also load the app after the load of the router because a lot of store actions are based on URL params
    await this.$router.isReady();
    this.$store.dispatch('ui/saveURLParamsForUi');
    this.cleanUpURLParameters();
    this.appIsReady = true;
  },
  async created() {
    await Promise.all([this.loadNotDefaultLanguage(), this.checkMaintenance()]);

    // Load Level 2 Dependencies
    await Promise.all([
      import('./utils/paypal'), // why?
      import.meta.env.VUE_APP_ENV != 'dev' &&
      loadScript('https://static.zdassets.com/ekr/snippet.js?key=fc8cd222-ab2d-4b9b-8bdf-5ac260a1ace1', {
        id: 'ze-snippet'
      })
    ]);


  },
  methods: {
    cleanUpURLParameters() {
      // Some users still have the ?lang=null wrong param causing the partner saved is: fuelio?lang=null
      // this was a bug in prod for 15 min but seems that someone still using the wrong URL or is stored in localstorage
      // With this func we remove the wrong param
      const URLParams = window.location.search;
      const wrongParam = '?lang=null';

      if (URLParams.includes(wrongParam)) {
        const cleanedParams = URLParams.replace(wrongParam, '');

        const paramsToKeep = cleanedParams ? cleanedParams : '?reset=true';
        this.$router.replace(paramsToKeep);
      }
    },
    async checkMaintenance() {
      try {
        const { data } = await axios.get(
          'https://firebasestorage.googleapis.com/v0/b/ryd-demo.appspot.com/o/maintenance.json?alt=media',
          {
            timeout: 20 * 1000 // 20 seconds
          }
        );

        if (data[import.meta.env.VUE_APP_ENV]) {
          this.$router.replace('/maintenance');
        }
      } catch (error) {
        console.log('error checking maintenance', error);
      }
    },
    async loadNotDefaultLanguage() {
      if (userLanguage() !== 'en') {
        await setLanguage(userLanguage(), false);
      }
    }
  }
};
</script>
<style lang="scss">
@import url('@/assets/styles/cssVariables.css');

#app {
  @apply text-fs-body w-full min-h-full relative;
  color: var(--colorPrimaryText);
  background: var(--colorPrimaryBg);

  @import './assets/styles/green';
  @import './assets/styles/purple';
  @import './assets/styles/red';
  @import './assets/styles/white';
  @import './assets/styles/yellow';
  @import './assets/styles/sixt';
  @import './assets/styles/bmwbike';

  .app__wrapper {
    max-width: var(--appMaxWidth);
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    &.landscape {
      max-width: var(--appMaxWidthLandscape);
      min-height: calc(100vh - var(--footerMaxHeight));
      padding-bottom: var(--footerMaxHeight);
    }
  }
}
</style>
