<template>
  <h2>{{ $t('signIn.register.email.headline') }}</h2>
  <Form
    ref="changeTmpPwdForm"
    class="view-block px-4"
    @submit="changeTmpPwd"
    @keyup.enter="changeTmpPwd"
  >
    <GenericError v-if="error" />
    <div>
      <TextField
        id="changePasswordField"
        v-model="newPassword"
        data-cy="changePasswordField"
        type="password"
        :label="$t('account.password_label')"
        rules="required|min:8|max:40"
        autocomplete="new-password"
      />
      <p>{{ $t('errors.temp_pass') }}</p>
    </div>
    <RPButton
      type="submit"
      theme="custom"
      class="text-primary-btn-text
        bg-primary-btn-bg"
    >
      {{ $t('account.change_tmp_pwd_cta') }}
    </RPButton>
  </Form>
</template>

<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import TextField from '@/components/Atoms/TextField.vue';
import GenericError from '@/components/Atoms/GenericError.vue';

import { Form } from 'vee-validate';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from 'vue';

const store = useStore();
const router = useRouter();
const route = useRoute();


const changeTmpPwdForm = ref(null);
const newPassword = ref(null);
const error = ref(false);

const changeTmpPwd = async () => {
  const { valid } = await changeTmpPwdForm.value.validate();
  if (!valid) {
    return;
  }

  try {
    await store.dispatch('auth/changePwd', newPassword.value);

    /** @see useLoginRegister.ts:52 */
    localStorage.setItem('previousPath', route.path);

    router.push('/address');
  } catch (e) {
    error.value = true;
  }
};
</script>