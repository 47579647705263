<template>
  <template v-if="!componentReady">
    <LoadingOverlay />
  </template>
  <div class="flex items-center mb-1 l-scape:text-fs-32 w-full justify-center px-4">
    <StationImg v-if="stationDetails.brand" :brand="stationDetails.brand" class="mr-2 l-scape:h-10" />
    <h4 class="m-0 mr-4 truncate leading-normal" data-cy="payStationName">
      {{ stationDetails.brand }}
      <span class="leading-normal font-normal" data-cy="payStationAddress">{{ stationDetails.address }}</span>
    </h4>
  </div>
  <div v-if="hasPOI" class="pt-lg text-center w-full view-block">
    <router-view :state="state" />
  </div>
</template>
<script setup lang="ts">
import StationImg from '@/components/Atoms/StationImg.vue';
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';

import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed, onMounted, watch, ref } from 'vue';
import { useMachine } from '@xstate/vue';
import { checkoutMachine } from '@/machines/checkoutMachine';
import { redirectToCallbackUrl } from '@/utils/utils';
import { userLanguage } from '@/i18n';

import { errors } from '@/rydPaySDK/src/StationData';

const store = useStore();
const route = useRoute();
const router = useRouter();

const isGuest = computed(() => store.state.auth.isGuest);
const hasPOI = computed(() => !!store.state.station.data.id);

const stationDetails = computed(() => store.state.station.data.name);

// xState machine
const POISupportGuest = computed(() => store.state.station.data.features.includes('guestCheckout'));
const activePayments = computed(() => store.state.psp.activePayments);
const checkoutPM = computed(() => store.getters['psp/checkoutMethod']);

const machineContext = computed(() => {
  return {
    userHasPMs: !!activePayments.value.length,
    guestCheckout: POISupportGuest.value,
    supportDevice: checkoutPM.value?.isSupportedDevice || false,
    supportPOI: checkoutPM.value?.isSupportedPOI || false,
    isGuest: isGuest.value
  };
});

const { state, send } = useMachine(checkoutMachine);
const assignContextMachine = () => {
  send({ type: 'FETCH_POI', checkoutContext: machineContext.value });
};

const componentReady = ref(false);
const redirectToCallback = paramsForMap => {
  const redirectToPartner = route.query.callback && route.query.hideRydMapLink;

  if (redirectToPartner) {
    try {
      redirectToCallbackUrl(route.query.callback);
    } catch (e) {
      window.location.href = `${import.meta.env.VUE_APP_MAP}/?${paramsForMap}`;
    }
    return;
  }

  window.location.href = `${import.meta.env.VUE_APP_MAP}/?${paramsForMap}`;
};

onMounted(async () => {
  localStorage.removeItem('previousPath');
  localStorage.removeItem('register_optin');
  localStorage.removeItem('register_country');
  const queryParamsForMap = new URLSearchParams(route.query as Record<string, string>);
  queryParamsForMap.append('lang', userLanguage());

  try {
    await store.dispatch('station/getOnce');
    assignContextMachine();

    if (store.state.station.data.status === 'OFFLINE' && route.query.orderId) {
      return;
    }
    if (store.state.station.data.status === 'OFFLINE') {
      router.push({
        path: '/error',
        query: { ...route.query, type: 'STATION_OFFLINE' }
      });
    }
  } catch (e) {

    [errors.NO_STATION_FOUND_LAT_LON, errors.NO_PUMPS].includes(store.state.station.error)
      ? router.push({
        path: '/error',
        query: { ...route.query, type: store.state.station.error }
      })
      : redirectToCallback(queryParamsForMap.toString());

  } finally {
    componentReady.value = true;
  }
});

watch(
  () => checkoutPM.value?.id,
  (newValue, oldValue) => {

    if (newValue !== oldValue) {
      send({
        type: 'PAYMENT_CHANGE',
        supportDevice: machineContext.value.supportDevice,
        supportPOI: machineContext.value.supportPOI
      });
    }
  },
  { deep: true }
);

</script>
