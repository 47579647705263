<template>
  <Teleport to="#topBarIconLeft">
    <RPButton
      theme="custom"
      class="bg-transparent w-10 py-0 px-0 min-w-min"
      @click="backClick">
      <RPIcon svg-fill="text" type="arrowLeft" />
    </RPButton>
  </Teleport>
  <Teleport to="#topBarTitle">
    <span>{{ isManualProgramSelected ? selectedProgramToAdd.name : $t('loyalty.header_title') }}</span>
  </Teleport>
  <LoadingOverlay v-if="!componentReady" />
  <template v-else>
    <LoyaltyManualAdd v-if="isManualProgramSelected" :program="selectedProgramToAdd" @cardNumber="registerProgram" />
    <div v-else class="w-full px-6">
      <h3 class="mb-2 text-left">{{ $t('loyalty.add_title') }}</h3>
      <div class="flex flex-col space-y-2 font-medium">
        <template v-for="(program, index) in loyaltyProgramsCanSubscribe" :key="index">
          <RPCard
            small
            hasClick
            data-cy="addCreditCard"
            class="justify-center flex"
            @click="selectProgram(program)">
            <div class="flex items-center font-medium w-full">
              <LoyaltyIcon :programName="program.name" class="w-8"/>
              {{ program.name }}
            </div>
          </RPCard>
        </template>
      </div>
      <!-- <span v-if="error" class="pt-5 text-error">{{ $t('errors.generic.text') }}</span> -->
    </div>
  </template>
</template>
<script lang="ts">
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import LoyaltyManualAdd from './components/LoyaltyManualAdd.vue';
import LoadingOverlay from '@/components/Atoms/LoadingOverlay.vue';
import LoyaltyIcon from '@/components/Layout/LoyaltyIcon.vue';

import { LoyaltyFeatureProvider } from '@/store/modules/loyalty/types';
import { useLoyalty } from '@/composable/useLoyalty';

import { createOptin } from '@/api/optin';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed, ref, onErrorCaptured, onMounted } from 'vue';
import { omit } from 'lodash';

export default {
  components: { RPIcon, RPCard, RPButton, LoyaltyManualAdd, LoadingOverlay, LoyaltyIcon },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { loyaltyProgramsCanSubscribe, supportedLoyaltyByPoi } = useLoyalty();

    const componentReady = ref(false);
    const selectedProgramToAdd = ref(null);
    const isManualProgramSelected = ref(false);

    // Check if we access from the checkout
    onMounted(async () => {
      if (route.query.loyaltyProgramId) {
        // if page was refreshed we need to retrieve the poi again
        const isPoiStored = store.state.station.data.id;
        !isPoiStored && (await store.dispatch('station/getOnce'));

        componentReady.value = true;
        selectProgram(supportedLoyaltyByPoi.value);
        return;
      }
      componentReady.value = true;
    });

    const backClick = () => {
      if (route.query.loyaltyProgramId) {
        redirectToCheckout();
      } else {
        isManualProgramSelected.value ? (isManualProgramSelected.value = false) : router.push('/profile/loyalty');
      }
    };

    const selectProgram = (program: LoyaltyFeatureProvider) => {
      selectedProgramToAdd.value = program;

      // Enable when we got some external login page for loyalty program
      // program.signup_page
      // ? window.location.href = program.signup_page
      // : isManualProgramSelected.value = true;

      isManualProgramSelected.value = true;
    };

    const redirectToCheckout = () => {
      const paramWithoutProgramId = omit(route.query, 'loyaltyProgramId');
      router.push({
        path: route.query.orderType === 'POSTPAY' ? '/pay/postpay/summary' : '/pay/prepay/summary',
        query: paramWithoutProgramId
      });
    };

    // Register a loyalty
    const accountId = computed(() => store.state.auth.accountId);
    const userId = computed(() => store.state.auth.id);

    const optinData = computed(() => ({
      name: `${selectedProgramToAdd.value.name}_${selectedProgramToAdd.value.provider}`,
      action: 'set',
      accountId: accountId.value,
      userId: userId.value
    }));

    const registerProgram = async card => {
      try {
        await createOptin(optinData.value);
        //payments request
        await store.dispatch('loyalty/registerLoyalty', {
          loyaltyId: selectedProgramToAdd.value._id,
          cardNumber: card
        });
        route.query.loyaltyProgramId ? redirectToCheckout() : router.push('/profile/loyalty');
      } catch (e) {
        console.log(e);
      }
    };

    return {
      loyaltyProgramsCanSubscribe,
      selectedProgramToAdd,
      isManualProgramSelected,
      backClick,
      selectProgram,
      registerProgram,
      componentReady
    };
  }
};
</script>