<template>
  <div class="separator-container"><slot /></div>
</template>

<script></script>

<style lang="scss" scoped>
.separator-container {
  font-size: var(--fontSizeFootnote);
  padding: 15px;
  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 30%;
    height: 1px;
    content: '\a0';
    background-color: var(--colorSecondaryBtnBg);
  }
  &:before {
    margin-left: -35%;
  }
  &:after {
    margin-left: 5%;
  }
}
</style>
