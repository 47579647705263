import axios from 'axios';
import { v4 } from 'uuid';
import * as Sentry from '@sentry/vue';

import i18n, { userLanguage } from './../i18n.js';
import { getCredentials } from '../utils/utils.js';
import store from '../store/index';

const instance = axios.create();

// Handling of 401 Response
instance.interceptors.response.use(
  response => response,
  error => {

    if (error.response?.data?.errorDescription) {
      store.commit('ui/setApiErrors', error.response?.data?.errorDescription);
    }

    // Push to sentry when not expected errors (401 = login expired/wrong password, 403 = wrong PIN, 422 = email already in use)
    if (![401, 403, 422].includes(error.response?.status)) {
      Sentry.captureException(error);
    }

    return Promise.reject(error);
  }
);

// Add Auth + locale
instance.interceptors.request.use(config => {
  const auth = getCredentials();
  if (auth?.token) {
    config.headers['x-txn-auth-token'] = auth.token;
  }

  config.headers['x-txn-locale'] = userLanguage();
  config.headers['x-txn-app-version'] = `ryd-pay-${VITE_GIT.commit}`;
  config.headers['x-txn-correlationid'] = v4();

  return config;
});

instance.defaults.baseURL = import.meta.env.VUE_APP_API_URL;
instance.defaults.timeout = 30 * 1000; // 30 seconds

export default instance;