<template>
  <RPCard v-if="!isEmpty(supportedLoyaltyByPoi)" small class="min-h-[40px] max-h-[40px] flex justify-center">
    <LoyaltyIcon :programName="supportedLoyaltyByPoi.name" class="w-6"/>
    <div class="w-full flex items-center justify-between text-left truncate">
      <strong>{{ supportedLoyaltyByPoi.name }}</strong>
      <p
        v-if="getUserLoyaltySupportedByPoi.length"
        class="m-0 mt-[2px] truncate">
        {{ getLoyaltyDetails(getUserLoyaltySupportedByPoi[0]) }}
      </p>
      <RPButton v-else link @click="addLoyalty" class="m-0">{{ $t('loyalty.save_cta') }}</RPButton>
    </div>
  </RPCard>
</template>
\
<script lang="ts">
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import LoyaltyIcon from '@/components/Layout/LoyaltyIcon.vue';

import { isEmpty } from 'lodash';
import { computed } from 'vue';
import { useLoyalty } from '@/composable/useLoyalty';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
export default {
  components: { RPCard, RPIcon, RPButton, LoyaltyIcon },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const orderSelectionState = computed(() => store.state.station.ui);

    const {
      activeLoyalty,
      getUserLoyaltySupportedByPoi,
      getLoyaltyDetails,
      supportedLoyaltyByPoi,
      supportedLoyaltyIdByPoi
    } = useLoyalty();

    const addLoyalty = () => {
      const { amount, fuelTypeId, pumpId, orderType } = orderSelectionState.value;

      const paramsToTrackOrder = orderType === 'PREPAY' ? { ...route.query, amount, fuelTypeId, pumpId } : route.query;
      router.push({
        path: '/profile/loyalty/add',
        query: { ...paramsToTrackOrder, loyaltyProgramId: supportedLoyaltyIdByPoi.value[0] }
      });
    };
    return {
      isEmpty,
      addLoyalty,
      activeLoyalty,
      getUserLoyaltySupportedByPoi,
      getLoyaltyDetails,
      supportedLoyaltyByPoi,
      supportedLoyaltyIdByPoi
    };
  }
};
</script>
