<template>
  <transition name="fade">
    <button
      class="w-full h-full fixed top-0 left-0 bg-black/50 z-20 flex items-center justify-center"
      @click.self.stop="$emit('close')"
    >
      <div class=" 
        bg-secondary-background rounded-3xl
        w-4/5 max-w-[360px]
        mx-auto p-lg
        text-primary-background"
      >
        <slot />
      </div>
    </button>
  </transition>
</template>

