import axios from './index';

// Docs see https://bitbucket.org/thinxnet/firebasepin/src/master/readme.md
export async function savePin({ pin }) {
  await axios.post('https://europe-west1-ryd-demo.cloudfunctions.net/save_pin/' + import.meta.env.VUE_APP_ENV, {
    pin
  });
}

export async function validatePin({ pin }) {
  await axios.post('https://europe-west1-ryd-demo.cloudfunctions.net/validate_pin/' + import.meta.env.VUE_APP_ENV, {
    pin
  });
}

export async function generateRegisterOptions({ userId, userName }) {
  const { data } = await axios.post(
    'https://europe-west1-ryd-demo.cloudfunctions.net/generate_register_options/' + import.meta.env.VUE_APP_ENV,
    { userId, userName }
  );

  return data;
}

export async function registerWebauthn({ options }) {
  await axios.post(
    'https://europe-west1-ryd-demo.cloudfunctions.net/register_webauthn/' + import.meta.env.VUE_APP_ENV,
    {
      ...options
    }
  );
}

export async function generateLoginOptions({ userId }) {
  const { data } = await axios.post(
    'https://europe-west1-ryd-demo.cloudfunctions.net/generate_login_options/' + import.meta.env.VUE_APP_ENV,
    { userId }
  );

  return data;
}

export async function loginWebauthn({ userId, options }) {
  await axios.post('https://europe-west1-ryd-demo.cloudfunctions.net/login_webauthn/' + import.meta.env.VUE_APP_ENV, {
    userId,
    ...options
  });
}

export async function hasUserWebauthn({ userId }) {
  const { data } = await axios.post(
    'https://europe-west1-ryd-demo.cloudfunctions.net/has_user_webauthn/' + import.meta.env.VUE_APP_ENV,
    { userId }
  );

  return data;
}

export async function userHasPin() {
  const { data } = await axios.post(
    'https://europe-west1-ryd-demo.cloudfunctions.net/user_has_pin/' + import.meta.env.VUE_APP_ENV
  );
  return data;
}
