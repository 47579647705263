<template>
  <div class="view-block px-6">
    <Teleport to="#topBarIconLeft">
      <RPButton theme="custom" class="bg-transparent w-10 py-0 px-0 min-w-min" href="/profile/payments">
        <RPIcon svg-fill="text" type="arrowLeft" />
      </RPButton>
    </Teleport>
    <Teleport to="#topBarTitle">
      <span>{{ paymentMethod.name }}</span>
    </Teleport>
    <div>
      <div class="mb-8 flex justify-center items-center">
        <RPIcon :type="paymentMethod.icon" noFill class="h-10" />
      </div>
      <div class="font-medium mb-6">
        <span class=" text-tertiary-text text-fs-12">{{ $t('profile.main.label_pm_name') }}</span>
        <p>{{ paymentMethod.name }}</p>
      </div>
      <div v-if="paymentMethod.details" class="font-medium mb-6">
        <span class=" text-tertiary-text text-fs-12">{{ $t('profile.main.label_pm_details') }}</span>
        <p>{{ paymentMethod.details }}</p>
      </div>
      <div v-if="paymentMethod.metaData.isPreferred">
        <span
          class="inline-block py-0.5 px-2.5 text-primary-btn-text text-xs font-medium bg-tertiary-text rounded-full">
          {{ $t('profile.main.payment-method.preferred') }}
        </span>
        <p class="mt-1">{{ $t('profile.main.payment-method.preferred_description') }}</p>
      </div>
    </div>

    <div v-if="!paymentMethod.metaData.isPreferred" id="buttons" class="mt-36 flex flex-col items-center">
      <RPButton
        :class="{'opacity-50 pointer-events-none': notSupportedEditPaymentMethod}"
        @click="setPreferredPayment">{{ $t('profile.main.payment-method.set_preferred') }}
      </RPButton>
      <RPButton
        theme="error"
        class="mt-2"
        @click="openRemoveDialog">
        {{ $t('profile.main.payment-method.remove') }}
      </RPButton>
    </div>

    <Dialog v-if="dialogOpen">
      <h2>{{ dialogData.title }}</h2>
      <p class="py-2">
        {{ dialogData.text }}
      </p>
      <RPButton class="w-full mt-6" @click="dialogData.buttonAction">
        {{ dialogData.buttonLabel }}
      </RPButton>
    </Dialog>

    <Dialog v-if="removeDialogOpen" @close="removeDialogOpen = false">
      <h2>{{ $t('profile.main.payment-method.delete_confirm.title') }}</h2>
      <strong>{{ $t('profile.main.payment-method.delete_confirm.text_attention') }}</strong>
      <p>{{ $t('profile.main.payment-method.delete_confirm.text_mpm') }}</p>
      <RPButton class="block w-full mt-6" @click="removePaymentMethod">
        {{ $t('profile.main.payment-method.delete_confirm.btn_confirm') }}
      </RPButton>
      <RPButton theme="error" class="block w-full mt-2" @click="removeDialogOpen = false">
        {{ $t('profile.main.payment-method.delete_confirm.btn_cancel') }}
      </RPButton>
    </Dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isEmpty } from 'lodash';

import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import Dialog from '@/components/Atoms/Dialog.vue';

import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

export default {
  components: {
    RPButton,
    Dialog,
    RPIcon
  },
  data() {
    return {
      dialogOpen: false,
      dialogData: {
        title: '',
        text: '',
        buttonLabel: '',
        buttonAction: () => null
      },
      removeDialogOpen: false
    };
  },
  computed: {
    ...mapState({
      paymentMethod: state => state.psp.editPaymentMethod
    }),
    notSupportedEditPaymentMethod() {
      return this.paymentMethod.metaData.pmIsNotSupportedOnDevice;
    }
  },
  mounted() {
    isEmpty(this.paymentMethod.metaData) && this.$router.replace({ path: '/profile/payments' });
  },
  methods: {
    ...mapActions({
      dispatchPreferredPaymentMethod: 'psp/setPreferredPaymentMethod',
      dispatchDeactivatePaymentMethod: 'psp/deactivatePaymentMethod'
    }),
    openRemoveDialog() {
      this.removeDialogOpen = true;
      this.$matomo.trackEvent('PaymentMethods', `Delete:${this.paymentMethod.name}`, 'ClickOnDeleteButton');
    },
    async setPreferredPayment() {
      try {
        await this.dispatchPreferredPaymentMethod(this.paymentMethod.metaData.id);
        this.dialogData.title = this.$t('profile.main.payment-method.preferred_success_message.title');
        this.dialogData.text = this.$t('profile.main.payment-method.preferred_success_message.text');
        this.dialogData.buttonLabel = this.$t('profile.main.payment-method.preferred_success_message.button');
        this.dialogData.buttonAction = () => this.$router.go(-1);
        this.dialogOpen = true;
      } catch (error) {
        this.handleErrors(error, () => (this.dialogOpen = false));
      }
    },
    async removePaymentMethod() {
      try {
        await this.dispatchDeactivatePaymentMethod(this.paymentMethod.metaData.id);
        this.$matomo.trackEvent('PaymentMethods', `Delete:${this.paymentMethod.name}`, 'Successful');
        this.removeDialogOpen = false;
        this.$router.go(-1);
      } catch (error) {
        this.removeDialogOpen = false;
        this.handleErrors(error, () => (this.dialogOpen = false));
      }
    },
    handleErrors(errorObject, dialogButtonAction) {
      this.dialogData.title = this.$t('errors.generic.title');
      this.dialogData.text = errorObject.response?.data?.message || this.$t('errors.generic.text');
      this.dialogData.buttonLabel = this.$t('errors.button_ok');
      this.dialogData.buttonAction = dialogButtonAction;
      this.dialogOpen = true;
    }
  }
};
</script>
