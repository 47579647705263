<template>
  <span v-if="error" class="text-error" data-cy="error-message"> {{ error }} </span>
  <VeeForm ref="loginForm" class="view-block" @submit="login()">
    <div>
      <TextField
        id="loginEmail"
        v-model="model.login.email"
        type="email"
        :label="t('account.email_label')"
        rules="required" 
        tabindex="1" 
        data-cy="loginEmailField" 
      />
      <TextField 
        id="loginPassword"
        v-model="model.login.password"
        type="password"
        :label="t('account.password_label')"
        rules="required"
        autocomplete="on"
        tabindex="2"
        data-cy="loginPasswordField"
      />
      <RPButton 
        link 
        href="/forgotten-password"
      >
        {{ $t('account.forgotten_pwd') }}
      </RPButton>
    </div>
    <div class="text-center mt-14">
      <GoogleSSO @token="token => loginSSO(token, 'GOOGLE')" />
      <AppleSSO @token="token => loginSSO(token, 'APPLE')" />
      <AmazonSSO actionType="login" />
      <Separator>{{ $t('signIn.login.separator') }}</Separator>
      <RPButton 
        type="submit" 
        theme="custom" 
        tabindex="3" 
        class="text-primary-btn-text
          bg-primary-btn-bg"
      >
        {{ t('signIn.login.mail_cta', { provider: $t('signIn.providers.mail') }) }}
      </RPButton>
    </div>
  </VeeForm>
  <Dialog v-if="dialogOpen" @close="dialogOpen = false">
    <h2>{{ t('errors.nopsp.title') }}</h2>
    <p>
      {{ t('errors.nopsp.text') }} <a href="mailto:hilfe@ryd.one?subject=Problem%20with%20account">hilfe@ryd.one</a>
    </p>
    <RPButton class="mt-6" data-cy="loginNoPSPClose" @click="dialogOpen = false">
      {{ t('errors.nopsp.btn') }}
    </RPButton>
  </Dialog>
  <Dialog v-if="userShouldRegister" @close="userShouldRegister = false">
    <h2>{{ t('signIn.register.dialog.title') }}</h2>
    <p>{{ t('signIn.register.dialog.content') }}</p>
    <RPButton class="mt-6" data-cy="loginNoAccountFoundClose" @click="handleRegisterNavigation">
      {{ t('account.register_cta') }}
    </RPButton>
  </Dialog>
</template>
<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import TextField from '@/components/Atoms/TextField.vue';
import Dialog from '@/components/Atoms/Dialog.vue';
import GoogleSSO from '@/components/Atoms/GoogleSSO.vue';
import AppleSSO from '@/components/Atoms/AppleSSO.vue';
import Separator from '@/components/Atoms/Separator.vue';
import AmazonSSO from '@/components/Atoms/AmazonSSO.vue';

import { useLoginRegister } from '@/views/Auth/composable/useLoginRegister';

import { Form as VeeForm } from 'vee-validate';
import { useStore } from 'vuex';
import { inject, onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const matomo: any = inject('Matomo');
const { redirectToCheckout } = useLoginRegister();

const model = reactive({
  login: {
    email: null,
    password: null
  }
});

const error = ref(null);
const dialogOpen = ref(false);
const userShouldRegister = ref(false);
const loginForm = ref(null);

onMounted(async () => {
  model.login.email = localStorage.getItem('lastUserEmail') || null;
});


const login = async () => {
  const { valid } = await loginForm.value.validate();
  if (!valid) {
    return;
  }
  try {
    await store.dispatch('auth/login', model.login);
    redirect();
  } catch (e) {
    checkErrorMessage(e);
  }
};

const loginSSO = async (token, provider) => {
  try {
    await store.dispatch('auth/loginOpenId', { token, provider });

    trackSSOLogin(provider);

    redirect();
  } catch (e) {
    handleIncorrectEmail(e);
  }
};

const trackSSOLogin = (provider) => {
  try {
    matomo.rememberConsentGiven();
    matomo.trackEvent('SSO', 'Login', provider);
  } catch (e) {
    console.debug(e);
  }
};

const handleRegisterNavigation = () => {
  userShouldRegister.value = false;
  router.replace('/register');
};

const checkErrorMessage = (errorParam) => {
  switch (errorParam.message) {
    case 'wrong_login_details': {
      error.value = t('errors.wrong_login_details');
      break;
    }
    case 'temp_pass':
      return router.push('/change-tmp-password');
    case 'noPSP': {
      dialogOpen.value = true;
      break;
    }
    default: {
      storeError(errorParam);
    }
  }
};

const redirect = async () => {
  // User was a guest and login from checkout prepay
  if (route.query.orderType) {
    return await redirectToCheckout();
  }

  router.push('/pay');
};

const handleIncorrectEmail = (errorParam) => {
  if (errorParam.response?.data?.message.includes('Incorrect email')) {
    userShouldRegister.value = true;
  }
  storeError(errorParam);
};

const storeError = (error) => {
  console.log(error);
  error.value = t('errors.generic.text');
};
</script>