<template>
  <div class="settings w-full px-6">
    <section class="pb-[30px]">
      <h3>{{ $t('settings.section_help.title') }}</h3>
      <strong class="block font-medium text-primary-text">{{ $t('settings.section_help.label_tel') }}</strong>
      <span class="text-fs-caption">{{ $t('settings.section_help.hotline_availability') }}</span>
      <a :href="`tel:${supportHotline}`" class="text-fs-caption block pt-[5px]">{{ supportHotline }}</a>
      <Divider class="my-md" />
      <router-link to="/onboarding" class="flex items-center justify-between">
        <strong class="block font-medium text-primary-text">{{ $t('settings.section_help.onboarding_link') }}</strong>
        <div>
          <RPIcon type="arrowRight" class="settings__icon-link" />
        </div>
      </router-link>
      <Divider class="my-md" />
    </section>
    <section>
      <h3>{{ $t('settings.section_legal.title') }}</h3>
      <a
        href="https://rydapp.page.link/terms"
        target="_blank"
        rel="noopener"
        class="flex items-center justify-between">
        <strong class="block font-medium text-primary-text">{{ $t('footer.menuItems.AGB') }}</strong>
        <div>
          <RPIcon type="arrowRight" class="settings__icon-link" />
        </div>
      </a>
      <Divider class="my-md" />
      <a href="https://rydapp.page.link/imprint" target="_blank" rel="noopener" class="flex items-center justify-between">
        <strong class="block font-medium text-primary-text">{{ $t('footer.menuItems.imprint') }}</strong>
        <div>
          <RPIcon type="arrowRight" class="settings__icon-link" />
        </div>
      </a>
      <Divider class="my-md" />
      <a
        href="https://rydapp.page.link/privacy"
        target="_blank"
        rel="noopener"
        class="flex items-center justify-between">
        <strong class="block font-medium text-primary-text">{{ $t('footer.menuItems.dataprotection') }}</strong>
        <div>
          <RPIcon type="arrowRight" class="settings__icon-link" />
        </div>
      </a>
      <Divider class="my-md" />
      <a
        href="https://rydapp.page.link/add-statutory-infos"
        target="_blank"
        rel="noopener"
        class="flex items-center justify-between">
        <strong class="block font-medium text-primary-text">{{ $t('footer.menuItems.statutoryInfos') }}</strong>
        <div>
          <RPIcon type="arrowRight" class="settings__icon-link" />
        </div>
      </a>
      <Divider class="my-md" />
    </section>
  </div>
</template>

<script>
import Divider from '../../components/Atoms/Divider.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

export default {
  components: { Divider, RPIcon },
  computed: {
    supportHotline() {
      return this.$store.state.psp.supportHotline;
    }
  }
};
</script>

<style lang="scss" scoped>
.settings {
  &__icon-link :deep(path) {
    fill: var(--colorIconPrimary);
  }
}
</style>
