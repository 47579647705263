<template>
  <div class="view-block px-6">
    <Form ref="forgotPwdForm" @submit="requestPwd">
      <RydLogo />
      <h2>{{ $t('account.forgot_pwd_title') }}</h2>
      <GenericError v-if="error" />
      <TextField v-model="email" :label="$t('account.email_label')" rules="required|email" type="email" />
    </Form>
    <div>
      <RPButton @click="requestPwd()" class="mb-4">
        {{ $t('account.forgot_pwd_cta_yes') }}
      </RPButton>
      <p />
      <RPButton theme="error" @click="$router.go(-1)">
        {{ $t('account.forgot_pwd_cta_no') }}
      </RPButton>
    </div>
    <Dialog v-if="dialog" @close="dialog = false">
      <p>
        {{ $t('notifications.new_pwd_request') }}
      </p>
      <RPButton class="w-full mt-6" @click="$router.push('/login')">
        {{ $t('profile.password.success.button_ok') }}
      </RPButton>
    </Dialog>
  </div>
</template>
<script>
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import Dialog from '@/components/Atoms/Dialog.vue';
import TextField from '@/components/Atoms/TextField.vue';
import GenericError from '@/components/Atoms/GenericError.vue';
import RydLogo from '@/components/Atoms/RydLogo.vue';
import { Form } from 'vee-validate';

export default {
  components: { RPButton, Dialog, TextField, GenericError, RydLogo, Form },
  data() {
    return {
      email: null,
      error: false,
      dialog: false
    };
  },
  methods: {
    async requestPwd() {
      const { valid } = await this.$refs.forgotPwdForm.validate();
      if (!valid) {
        return;
      }
      try {
        await this.$store.dispatch('auth/forgotPwd', this.email);
        this.dialog = true;
      } catch (e) {
        this.error = true;
      }
    }
  }
};
</script>