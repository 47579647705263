<template>
  <div class="font-medium">
    <div class="flex justify-between items-center mb-2">
      <span class="text-tertiary-text">{{ $t('fueling.finish.table.timestamp') }}</span>
      <span>{{ formatedTime }}</span>
    </div>
    <div class="flex justify-between items-center mb-2">
      <span class="text-tertiary-text">{{ $t('fueling.finish.table.fuelName') }}</span>
      <span>{{ productFinished.fuel.name }}</span>
    </div>
    <div class="flex justify-between items-center mb-2">
      <span class="text-tertiary-text">{{ $t('fueling.finish.table.pump') }}</span>
      <span>{{ $t('fueling.finish.table.pumpUnit') }} {{ productFinished.pump.name }}</span>
    </div>
    <Divider class="mt-2 mb-2" />
    <div class="flex justify-between items-center mb-2">
      <span class="text-tertiary-text">{{ $t('fueling.finish.table.unit') }}</span>
      <span>{{ formatedQuantity }} {{ paymentResult.quantity.unit }}</span>
    </div>
    <div class="flex justify-between items-center mb-2">
      <span class="text-tertiary-text">{{ $t('fueling.finish.table.pricePerUnit') }}</span>
      <span>{{ pricePerUnit }} / {{ paymentResult.quantity.unit }}</span>
    </div>
    <template v-if="voucherPreviewReady && !!voucherPreview && voucherPreview.discounts.length > 0">
      <Divider class="mt-2 mb-2" />
      <div class="flex justify-between items-center text-fs-title font-bold mb-2">
        <span class="text-tertiary-text">{{ $t('fueling.finish.table.amount') }}</span>
        <span>{{ totalBeforeDiscounts }}</span>
      </div>
    </template>
    <template v-if="paymentResult.loyaltyItem">
      <div class="flex justify-between items-center mb-2">
        <span class="text-tertiary-text">{{ paymentResult.loyaltyItem.programName }}</span>
        <div>
          <span v-if="paymentResult.loyaltyItem.totalAmount">({{ paymentResult.loyaltyItem.totalAmount }})</span>
          <span class="text-tertiary-base">+{{ paymentResult.loyaltyItem.amount }}</span>
        </div>
      </div>
    </template>
    <template v-if="props.isCheckout">
      <template v-if="!!voucherPreview && voucherPreviewReady">
        <Divider v-if="voucherPreview.discounts.length > 0" class="mt-2 mb-2" />
        <div v-for="voucher in voucherPreview.discounts" :key="voucher.name"
             class="flex justify-between items-center mb-2"
        >
          <span class="text-tertiary-text truncate pr-2">{{ voucher.name }}</span>
          <span class="text-tertiary-base text-right min-w-[30%]">-{{ getFormattedVoucherAmount(voucher.appliedDiscount.amount) }}</span>
        </div>
      </template>
      <Loadingspinner v-else-if="!voucherPreviewReady" class="h-5 w-5 p-1" />
    </template>
    <template v-if="validStatusForVoucher">
      <template v-for="voucher in paymentResult.vouchers">
        <div v-if="voucher.total" :key="voucher.id" class="flex justify-between items-center mb-2">
          <span class="text-tertiary-text truncate pr-2">{{ voucher.name }}</span>
          <span class="text-tertiary-base text-right min-w-[30%]">{{ getFormattedVoucherAmount(voucher.total.amount) }}</span>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import Divider from '../Atoms/Divider.vue';
import Loadingspinner from '../Atoms/Loadingspinner.vue';

import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const voucherPreviewReady = ref(true);
const props = defineProps({
  statusForTimestamp: {
    type: String,
    required: true
  },
  isCheckout: {
    type: Boolean,
    default: false
  },
  xState: {
    type: Object,
    default: () => ({})
  }
});
const productFinished = computed(() => store.state.pay.orderStatus.productFinished);
const paymentResult = computed(() => store.state.pay.orderStatus.paymentResult);
const statusHistory = computed(() => store.state.pay.orderStatus.statusHistory);
const voucherPreview = computed(() => store.state.vouchers.voucherPreview);

const pricePerUnit = computed(() => {
  const price = paymentResult.value.unit.amount;
  return new Intl.NumberFormat(localStorage.getItem('lang') || undefined, {
    style: 'currency',
    currency: paymentResult.value.unit.currency,
    minimumFractionDigits: paymentResult.value.unit.precision
  }).format(price);
});



const totalBeforeDiscounts = computed(() => {
  const price = paymentResult.value.totalBeforeDiscounts.amount;
  return new Intl.NumberFormat(localStorage.getItem('lang') || undefined, {
    style: 'currency',
    currency: paymentResult.value.totalBeforeDiscounts.currency,
    minimumFractionDigits: paymentResult.value.totalBeforeDiscounts.precision
  }).format(price);
});

const formatedQuantity = computed(() => {
  const amount = paymentResult.value.quantity.amount;
  return new Intl.NumberFormat(localStorage.getItem('lang') || undefined, { maximumFractionDigits: 2 }).format(
    amount
  );
});
const formatedTime = computed(() => {
  const timestamp = statusHistory.value.find(status => status.status === props.statusForTimestamp).timestamp;
  const lang = localStorage.getItem('lang') || 'en-GB';
  const formattedLang = lang === 'en' ? 'en-GB' : lang;
  const date = timestamp.toLocaleDateString(formattedLang);
  const time = timestamp.toLocaleTimeString(formattedLang, { hour: '2-digit', minute: '2-digit' });
  return `${date} - ${time}`;
});
const validStatusForVoucher = computed(() => {
  return ['COMPLETED', 'READY_FOR_INVOICE', 'PRODUCT_ACQUIRED', 'PROBLEM'].includes(props.statusForTimestamp);
});

const checkoutMethod = computed(() => store.getters['psp/checkoutMethod']);
const checkoutMethodId = computed(() => checkoutMethod.value?.id);

const getFormattedVoucherAmount = (amount) => {
  return new Intl.NumberFormat(localStorage.getItem('lang') || undefined, {
    style: 'currency',
    currency: paymentResult.value.unit.currency,
    maximumFractionDigits: 2
  }).format(amount);
};
const getVoucherPreview = async () => {
  const payload = {
    total: {
      amount: `${paymentResult.value.total.amount}`,
      currency: paymentResult.value.total.currency,
      precision: paymentResult.value.total.precision
    },
    poiId: store.state.pay.orderStatus.poi,
    paymentMethodId: checkoutMethodId.value ? checkoutMethodId.value : null,
    products: [{
      id: productFinished.value.pump.id,
      unitPrice: {
        amount: `${paymentResult.value.unit.amount}`,
        currency: paymentResult.value.unit.currency,
        precision: paymentResult.value.unit.precision
      },
      quantity: `${paymentResult.value.quantity.amount}`
    }]
  };
  if (checkoutMethodId.value) {
    try {
      voucherPreviewReady.value = false;
      await store.dispatch('vouchers/getVoucherPreview', { ...payload });
    } catch (e) {
      console.log(e);
    } finally {
      voucherPreviewReady.value = true;
    }
  }
};
onMounted(async () => {
  if (props.isCheckout && !props.xState.matches('guest')) {
    getVoucherPreview();
  }
});

watch(checkoutMethodId, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    props.isCheckout && getVoucherPreview();
  }
}
);
</script>
