<template>
  <footer
    class="
      flex justify-center items-center flex-col sm:flex-row
      px-0
      sticky -bottom-[72px] sm:-bottom-0
      sm:space-x-2 leading-3
      w-full
     bg-black
     text-fs-12 l-scape:text-fs-14 text-center
    "
    :class="{ 'hidden': $route.path === '/settings' }"
  >
    <a
      class="text-primary-text h-6 flex items-center"
      href="https://rydapp.page.link/terms"
      target="_blank"
      rel="noopener"
    >
      {{ t('footer.menuItems.AGB') }}
    </a>
    <a
      class="text-primary-text h-6 flex items-center"
      href="https://rydapp.page.link/imprint"
      target="_blank"
      rel="noopener"
    >
      {{ t('footer.menuItems.imprint') }}
    </a>
    <a
      class="text-primary-text h-6 flex items-center"
      href="https://rydapp.page.link/privacy"
      target="_blank"
      rel="noopener"
    >
      {{ t('footer.menuItems.dataprotection') }}
    </a>
    <a
      class="text-primary-text h-6 flex items-center"
      href="https://rydapp.page.link/add-statutory-infos"
      target="_blank"
      rel="noopener"
    >
      {{ t('footer.menuItems.statutoryInfos') }}
    </a>
  </footer>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>