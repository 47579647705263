import axios, { AxiosResponse, AxiosInstance } from 'axios';
import globalAxiosInstance from '../../api/index.js';
import { API_POI, Station, FuelTypes, API_PumpFuelType, Geolocation } from './types/StationData.js';

const refreshInterval = 5000;
export const errors = {
  STATION_OFFLINE: 'STATION_OFFLINE',
  NO_PUMPS: 'NO_PUMPS',
  NO_STATION_FOUND_LAT_LON: 'NO_STATION_FOUND_LAT_LON'
};

class CustomException {
  message: string;
  code: string;
  constructor(message: string) {
    this.message = message;
    this.code = 'CUSTOM_ERROR';
  }
}

export default class StationData {
  #intervalId: ReturnType<typeof setInterval> | null = null;
  #firstrun = true;
  #startTimeRequest = 0;
  #axiosCancelToken = axios.CancelToken.source();
  #axiosInstance: AxiosInstance;

  constructor() {
    this.#axiosInstance = globalAxiosInstance;
  }

  start(poiId: string, cb: (arg0: Station | null, error?: Error) => void): void {
    // Reset
    this.#firstrun = true;
    this.#axiosCancelToken = axios.CancelToken.source();
    this.#startTimeRequest = 0;

    // Inital Load
    this.loadPOI(poiId, cb);
    this.#intervalId = setInterval(() => this.loadPOI(poiId, cb), refreshInterval);
  }

  stop(): void {
    if (this.#intervalId) {
      clearInterval(this.#intervalId);
    }
    this.#axiosCancelToken.cancel();
  }

  async getOnce(poiId: string): Promise<Station> {
    const { data }: AxiosResponse = await this.#axiosInstance.get(`v4/pois/${poiId}`);
    return this.convertAPIResponse(data);
  }

  async getOnceByGelolocation({ latitude, longitude }: Geolocation): Promise<Station | null> {
    const { data }: AxiosResponse = await this.#axiosInstance.get(
      `/v4/pois/?lat=${latitude}&lon=${longitude}&radiusM=300`
    );

    if (!data.GAS_STATION?.length) {
      throw new CustomException(errors.NO_STATION_FOUND_LAT_LON);
    }

    return this.getOnce(data.GAS_STATION[0]._id);
  }

  private async loadPOI(poiId: string, cb: (arg0: Station | null, error?: Error) => void) {
    // Prev not finished
    if (this.#startTimeRequest) {
      return;
    }
    try {
      this.#startTimeRequest = new Date().getTime();
      const apiResponse: AxiosResponse<API_POI> = await this.#axiosInstance.get(`v4/pois/${poiId}`, {
        timeout: this.#firstrun ? refreshInterval * 4 - 10 : refreshInterval - 10,
        cancelToken: this.#axiosCancelToken.token
      });
      this.#startTimeRequest = 0;
    
      cb(this.convertAPIResponse(apiResponse.data));
    } catch (e) {
      // throw error only on the first run
      if (this.#firstrun) {
        cb(null, e as Error);
        this.stop();
      }
    } finally {
      this.#firstrun = false;
    }
  }

  private createFuelTypesArray(input: API_PumpFuelType[] | undefined): FuelTypes[] {
    if (!input) {
      return [];
    }

    return input.map(fuelType => {
      return {
        id: fuelType._id,
        name: fuelType.marketingName
      };
    });
  }

  private convertAPIResponse(station: API_POI): Station {
    const pumps = station.products || [];

    if (!pumps.length) {
      throw new CustomException(errors.NO_PUMPS);
    }
    
    // fix api responses
    if (!station.prices) {
      station.prices = [];
    }
    
    const stationData: Station = {
      id: station._id,
      pumps: pumps.map(product => {
        const priceLimits = product.attributes.priceAuthorizationLimits ?? false;
        return {
          id: product._id,
          name: product.name,
          available: product.attributes.status === 'AVAILABLE',
          fuelTypes: this.createFuelTypesArray(product.attributes.automaticPumpFuelTypes),
          type: product.attributes.automaticPump ? 'PREPAY' : 'POSTPAY',
          recommendedPrices: priceLimits ? priceLimits.recommendedPrices.map(price => parseFloat(price.amount)) : [],
          priceType: priceLimits ? {
            currency: priceLimits.min.currency,
            precision: priceLimits.min.precision
          } : undefined
        };
      }),
      features: station.features,
    
      name: {
        brand: station.brand,
        address: station.location?.address || null,
        country: station.location.country
      },
      fuelPrices: station.prices.map(fuelPrice => {
        return {
          priceId: fuelPrice.priceId,
          name: fuelPrice.priceTitle,
          amount: parseFloat(fuelPrice.price.amount) ?? null,
          currency: fuelPrice.price.currency,
          precision: fuelPrice.price.precision,
          isRealTimePrice: fuelPrice.isRealTimePrice
        };
      }),
      supportedPaymentMeans: station.supportedPaymentMeans,
      supportedLoyaltyPrograms: station.supportedLoyaltyPrograms || [],
      status: station.status,
      partnerName: station.partnerDetails?.partnerName || null
    };

    // if (!stationData.priceType) {
    //   delete stationData.priceType;
    // }
    return stationData;
  }
}
