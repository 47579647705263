<template>
  <RPHint
    v-if="hasHintScreen && showHintScreen"
    :type="typeOfHint"
    @closeHint="setHintScreenVisibility(false)"
  />
  <template v-else>
    <Teleport to="#topBarIconLeft">
      <RPButton
        data-cy="goToProfile"
        theme="custom"
        class="bg-transparent w-10 py-0 px-0 min-w-min"
        @click="profileRedirect"
      >
        <RPIcon svg-fill="text" type="imageProfile" />
        <span v-if="state.matches('guest')" data-cy="guest-user-indicate" class="bg-error rounded-full bottom-6 right-3 w-2.5 h-2.5 absolute" />
      </RPButton>
    </Teleport>
    <Teleport to="#topBarTitle">
      <span data-cy="pumpSelectionTitle">{{ $t('fueling.pumpSelection.title') }}</span>
    </Teleport>
    <Teleport to="#topBarIconRight">
      <RPButton theme="custom" class="p-0 min-w-min" data-cy="goToSettings" @click="$router.push('/settings')">
        <RPIcon type="gear" svgFill="text" class="relative l-scape:w-20 l-scape:h-20" />
      </RPButton>
    </Teleport>
    <div class="pump-selection h-[410px]">
      <RPButton
        v-if="paramsUi.pid && !hideRydMapLink"
        link
        class="mx-auto items-center l-scape:items-center mb-6"
        data-cy="findAnotherStationButton"
        @click="redirectToMap"
      >
        <RPIcon svg-fill="primary" type="map" class="w-6 mr-4 l-scape:w-12" />
        <span>{{ $t('fueling.find_another_poi_on_map') }}</span>
      </RPButton>
      <div class="h-[410px]">
        <section class="grid grid-cols-[auto_auto_auto] justify-center gap-[var(--space-xl)]">
          <RPButton
            v-for="pump in pumpsToShow"
            :key="pump.id"
            :data-cy="`pump-${pump.id}`"
            :disabled="!pump.available"
            theme="custom"
            class="min-w-[55px] h-[55px] text-2xl font-bold p-0 bg-card-bg border rounded-xl border-card-outline"
            @click="setPump(pump.id)"
          >
            {{ pump.name }}
          </RPButton>
        </section>
      </div>
    </div>
    <Pagination
      v-if="pumps.length > pumpsPerPage"
      :items-per-page="pumpsPerPage"
      :total-items="pumps.length"
      @page-changed="changePage"
    />
  </template>
</template>
<script lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { userLanguage } from '@/i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Pagination from '@/components/Layout/Pagination.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import RPHint from '@/components/Layout/RPHint/RPHint.vue';

import { resetOrders, resetAllURLparams } from '@/utils/utils';

export default {
  components: { Pagination, RPButton, RPIcon, RPHint },
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paramsUi = computed(() => store.state.ui.query);
    const hideRydMapLink = computed(() => store.state.ui.query.hideRydMapLink);

    const profileRedirect = () => {
      router.push({
        path: store.state.auth.isGuest ? '/login' : '/profile'
      });
    };

    const redirectToMap = () => {
      const queryParamsForMap = new URLSearchParams(route.query as Record<string, string>);
      queryParamsForMap.append('lang', userLanguage());
      window.location.assign(`${import.meta.env.VUE_APP_MAP}?${queryParamsForMap.toString()}`);
    };

    const payFlowPages = computed(() => store.getters['pay/payFlowPages']);
    const setPump = pumpId => {
      store.commit('station/saveURLParamsForTrackOrder', { pumpId });
      const nextPagePath = payFlowPages.value.nextPagePath;

      router.push({ path: nextPagePath });
    };

    //  Pagination
    const pumpsPerPage = ref(12);
    const startSlice = ref(0);
    const endSlice = ref(pumpsPerPage.value);

    const pumps = computed(() => {
      const pumpsCopy = [...store.state.station.data.pumps];
      const [lang] = userLanguage().split('-');
      return pumpsCopy.sort((a, b) => a.name.localeCompare(b.name, lang, { numeric: true }));
    });
    const pumpsToShow = computed(() => {
      return pumps.value.slice(startSlice.value, endSlice.value);
    });

    const changePage = (newStartSlice: number, newEndSliceArg: number): void => {
      startSlice.value = newStartSlice;
      endSlice.value = newEndSliceArg;
    };

    // Hint screens
    const showHintScreen = computed(() => store.state.ui.showHintScreen);
    const setHintScreenVisibility = (value: boolean) => store.commit('ui/setShowHintScreen', value);
    const fuelingStation = computed(() => store.state.station.data);
    const partnerName = computed(() => store.state.station.data?.partnerName);

    const cannotUsePhoneOutsideCar = computed(() => {
      const stations = ['repsol', 'gasodata'];
      return stations.some(station => fuelingStation.value.name?.brand.toLowerCase()?.includes(station));
    });

    const hints = computed(() => {
      return {
        cannotUsePhoneOutsideCar: cannotUsePhoneOutsideCar.value,
        prePayHint: partnerName.value === 'Aral Aktiengesellschaft',
        h2LegalHint: partnerName.value === 'H2 MOBILITY Deutschland GmbH & Co. KG'
      };
    });

    const hasHintScreen = computed(() => Object.keys(hints.value).some(key => hints.value[key] === true));
    const typeOfHint = computed(
      () => hasHintScreen.value && Object.keys(hints.value).find(key => hints.value[key] === true)
    );

    // Loading pooling
    const startLoading = async () => {
      try {
        await store.dispatch('station/startLoading');
      } catch (e) {
        router.replace('/error');
      }
    };
    const stopLoading = () => {
      store.dispatch('station/stopLoading');
    };

    onBeforeUnmount(() => {
      stopLoading();

      window.removeEventListener('online', startLoading);
      window.removeEventListener('offline', stopLoading);
    });

    onMounted(() => {
      localStorage.removeItem('odometer');

      resetAllURLparams();
      //remove orders from LocalStorage
      resetOrders();
      //reset Vuex order store
      store.commit('station/resetUIState');
      store.commit('pay/resetPayState');
      // reset Payment selected and restore the preferred one
      store.dispatch('psp/resetTemporaryPM');
      startLoading();

      window.addEventListener('online', startLoading);
      window.addEventListener('offline', stopLoading);
    });

    return {
      profileRedirect,
      redirectToMap,
      paramsUi,
      hideRydMapLink,
      setPump,
      pumps,
      pumpsPerPage,
      pumpsToShow,
      changePage,
      typeOfHint,
      hasHintScreen,
      showHintScreen,
      setHintScreenVisibility
    };
  }
};
</script>
<style lang="scss" scoped></style>
