<template>
  <div class="view-block">
    <form>
      <CountrySelect v-model="form.countrySelect" @update:model-value="updateCountry" />
      <p class="mb-1 text-fs-12" v-html="$t('signIn.register.legal_text', { terms_and_conditions: 'https://rydapp.page.link/terms', data_protection: 'https://rydapp.page.link/privacy' })"></p>
      <p ref="protectionConsentText" class="text-fs-12" v-html="$t('signIn.register.protection_consent', { declaration_of_consent: 'https://rydapp.page.link/ryders-world-data-consent/' })"></p>
      <div class="flex flex-row mt-4">
        <Checkbox
          id="emailsCheckbox"
          v-model="form.optIn.emails"
          :label="$t('signIn.register.via_email')"
        />
        <Checkbox
          id="pushesCheckbox"
          v-model="form.optIn.pushes"
          class="ml-8"
          :label="$t('signIn.register.via_push_notifications')"
        />
      </div>
    </form>
    <div class="text-center mt-7">
      <RPButton link href="/onboarding" class="mb-6 py-3">
        {{ $t('landing.onboarding_cta') }}
      </RPButton>
      <GoogleSSO @token="token => ssoRegister(token, 'GOOGLE')" />
      <AppleSSO @token="(token, user) => ssoRegister(token, 'APPLE', user)" />
      <AmazonSSO actionType="register" @click="getDobleOptInParams()" />
      <Separator>{{ $t('signIn.register.separator') }}</Separator>
      <RPButton
        type="submit"
        class="text-primary-btn-text bg-primary-btn-bg"
        data-cy="registerSubmit"
        @click="mailRegister"
      >
        {{ $t('signIn.register.cta', { provider: $t('signIn.providers.mail') }) }}
      </RPButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import Checkbox from '@/components/Atoms/Checkbox.vue';
import CountrySelect from '@/components/Layout/CountrySelect.vue';
import GoogleSSO from '@/components/Atoms/GoogleSSO.vue';
import AppleSSO from '@/components/Atoms/AppleSSO.vue';
import Separator from '@/components/Atoms/Separator.vue';
import AmazonSSO from '@/components/Atoms/AmazonSSO.vue';

import { useLoginRegister } from '@/views/Auth/composable/useLoginRegister';

import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, onMounted, reactive, inject } from 'vue';

const options = ['DE', 'AT', 'CH', 'NL', 'BE', 'LU', 'PT', 'DK', 'PL', 'ES'];

const store = useStore();
const route = useRoute();
const router = useRouter();
const matomo: any = inject('Matomo');
const { redirectToCheckout } = useLoginRegister();

const form = reactive({
  countrySelect: 'DE',
  optIn: {
    emails: false,
    pushes: false
  }
});

const formIsValid = computed(() => {
  return !!form.countrySelect && options.includes(form.countrySelect);
});

onMounted(async () => {
  updateCountry(form.countrySelect);
});

const updateCountry = (payload) => {
  form.countrySelect = payload;
  localStorage.setItem('register_country', payload);

  store.commit('register/setState', { key: 'country', value: payload });
};

const mailRegister = async () => {
  if (!formIsValid.value) return;

  store.commit('auth/setMarketingPermissions', getDobleOptInParams());
  router.push('/register/mail');
};

const ssoRegister = async (token, provider, userData = {}) => {
  if (!formIsValid.value) return;

  store.commit('auth/setMarketingPermissions', getDobleOptInParams());
  try {
    await store.dispatch('auth/register', {
      countryCode: form.countrySelect,
      registrationMethod: 'openid',
      idToken: token,
      idProvider: provider,
      ...userData
    });

    await store.dispatch('auth/loginOpenId', { token, provider });
    trackRegistration(provider);

    if (route.query.orderType) {
      return await redirectToCheckout();
    }

    /** @see useLoginRegister.ts:52 */
    localStorage.setItem('previousPath', route.path);

    router.push('/address');

  } catch (e) {
    if (e.message === 'email_has_account') {
      await store.dispatch('auth/loginOpenId', { token, provider });

      if (route.query.orderType) {
        return await redirectToCheckout();
      }
      return router.push('/pay');
    }
    console.log(e);

  } finally {
    localStorage.removeItem('register_optin');
    localStorage.removeItem('register_country');
  }
};
const trackRegistration = (provider) => {
  try {
    matomo.rememberConsentGiven();
    matomo.trackEvent('Registration', 'NewUser', localStorage.getItem('partner'));
    matomo.trackEvent('SSO', 'Registration', provider);
  } catch (e) {
    console.debug(e);
  }
};

const protectionConsentText = ref<HTMLElement | null>(null);
const getDobleOptInParams = () => {
  const nameToParam = {
    emails: 'PARTNER_EMAILS',
    pushes: 'PARTNER_PUSHES'
  };
  const params = [];
  Object.keys(form.optIn).map(paramName => {
    if (form.optIn[paramName]) {
      params.push({
        name: nameToParam[paramName],
        legalText: protectionConsentText.value.innerHTML
      });
    }
  });
  localStorage.setItem('register_optin', JSON.stringify(params));
  return params;
};
</script>