<template>
  <div class="grid grid-cols-2 border-b border-solid border-secondary-btn-bg mb-6 w-full">
    <button
      v-for="element in tabElements"
      :key="element.ref"
      class="
        text-fs-subtitle text-primary-text font-medium 
        border-0 border-transparent 
        opacity-50 p-4 pt-0"
      :class="{ 'border-b-2 border-solid border-b-primary-text opacity-100': currentRoute === element.ref }"
      @click="$router.push(element.link)">
      {{ element.label }}
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    tabElements() {
      return [
        {
          label: this.$t('signIn.login.name'),
          link: '/login',
          ref: 'login'
        },
        {
          label: this.$t('signIn.register.name'),
          link: '/register',
          ref: 'register'
        }
      ];
    },
    currentRoute() {
      return this.$route.path.replace('/', '');
    }
  }
};
</script>
