<template>
  <div class="view-block px-6">
    <VeeForm class="view-block" @submit="sendPhoneNumber">
      <div class="flex-1 w-full">
        <h3>{{ t('profile.main.payment-method.mbway.title') }}</h3>
        <small class="block mt-[30px]">{{ t('profile.main.payment-method.mbway.sub_title') }}</small>
        <TelephoneNumberInput v-model="phoneNumber" @update:customError="handleCustomError" />
      </div>
      <RPButton
        theme="custom"
        class="text-primary-btn-text
        bg-primary-btn-bg"
        type="submit"
      >
        {{ t('account.save') }}
      </RPButton>
    </VeeForm>
  </div>
</template>


<script lang="ts">
export default {
  beforeRouteEnter(_, from, next) {
    next((vm: any) => {
      vm.lastPage = from.fullPath;
    });
  },
};

</script>
<script setup lang="ts">
import RPButton from '../../components/Atoms/RPButton/RPButton.vue';
import TelephoneNumberInput from '../../components/Atoms/TelephoneNumberInput.vue';
import { Form as VeeForm } from 'vee-validate';

import { createOptin } from '../../api/optin';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';


const phoneNumber = ref(null);
const customError = ref(null);


const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const handleCustomError = (error) => {
  customError.value = error;
};

const sendPhoneNumber = async () => {

  if (!phoneNumber.value || customError.value) return;

  try {
    const provider = 'sibs';
    const method = 'MBWAY';
    const { accountId, id } = store.state.auth;
    const query = {
      phoneNumber: phoneNumber.value,
      method
    };

    const optinData = {
      name: `${method}_${provider}`,
      action: 'set',
      accountId,
      userId: id
    };

    await Promise.all([
      //optIn request
      createOptin(optinData),
      //payments request
      store.dispatch('psp/fetchProviderInformations', {
        provider,
        method,
        query
      })
    ]);
    router.push({
      path: '/payment-register',
      query: {
        provider,
        ...query,
        ...route.query
      }
    });
  } catch (e) {
    console.error(e);
  }
};

</script>

<style lang="scss" scoped>
</style>
