<template>
  <div v-if="isPumpChosen" class="px-6 l-scape:max-w-[var(--appMaxWidthContainerLandscape)] l-scape:mx-auto">
    <span class="font-bold py-[24px] inline-block">{{ $t('fueling.amountSelection.text') }}</span>
    <section class="grid grid-cols-2 gap-[var(--space-md)] font-medium">
      <RPCard
        v-for="(price, x) in prices"
        :key="x"
        :data-cy="`amount-${x}`"
        small
        hasClick
        class="text-center flex justify-center items-center l-scape:h-20"
        @click="setAmount(price.value)">
        <p class="mx-auto">{{ price.label }}</p>
      </RPCard>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { resetOrderURLparams } from '@/utils/utils';

import RPCard from '@/components/Atoms/RPCard/RPCard.vue';

import { currencyString } from '@/utils/utils.js';
export default {
  components: { RPCard },
  mounted() {
    const paramsWithoutOrder = resetOrderURLparams(this.$route.query);
    !this.isPumpChosen && this.$router.push({ path: '/pay', query: paramsWithoutOrder });
  },
  computed: {
    ...mapGetters({
      payFlowPages: 'pay/payFlowPages',
      isPumpChosen: 'station/isPumpChoosen',
      chosenPump: 'station/chosenPump'
    }),
    prices() {
      return this.chosenPump.recommendedPrices.map(price => {
        return {
          value: price,
          label: currencyString(price, this.chosenPump.priceType.currency)
        };
      });
    }
  },
  methods: {
    setAmount(amount) {
      this.$store.commit('station/saveURLParamsForTrackOrder', { amount });
      const nextPagePath = this.payFlowPages.nextPagePath;
      this.$router.push(nextPagePath);
    }
  }
};
</script>
<style lang="scss" scoped></style>
