<template>
  <div class="view-block px-6">
    <form @submit.prevent="sendSubscriptionNumber" class="view-block">
      <div class="flex-1 w-full">
        <h3 class="text-left mb-4">{{ $t('loyalty.add_card') }}</h3>
        <small
          class="block mb-[30px]"
          v-html="$t('loyalty.agb_text',
            { program: program.name, main_agb: program.tncs })" />
        <div class="flex items-start">
        <LoyaltyIcon :programName="program.name" class="w-12"/>
          <div>
            <label for="card_number" class="text-tertiary-text font-medium">{{ program.name }}
              <input
                v-model="cardNumber"
                @input="resetError"
                @focusout="validateForm"
                type="text"
                id="card_number"
                data-cy="loyalty_card_number" />
            </label>
            <p v-if="errorMessage" class="text-error">{{ $t(errorMessage) }}</p>
          </div>
        </div>
      </div>
      <RPButton
        theme="custom"
        class="text-primary-btn-text
        bg-primary-btn-bg"
        type="submit">
        {{ $t('loyalty.save_cta') }}
      </RPButton>
    </form>
  </div>
</template>

<script>
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';
import LoyaltyIcon from '@/components/Layout/LoyaltyIcon.vue';

import { ref } from 'vue';

export default {
  components: { RPButton, RPIcon, LoyaltyIcon },
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  emits: ['cardNumber'],
  setup(props, { emit }) {

    const cardNumber = ref('');
    const errorMessage = ref(null);
    const resetError = () => (errorMessage.value = null);

    // Repsol validation. This is a valid number 7896981514431830014
    const isLuhnValid = num => {
      let arr = (num + '')
        .split('')
        .reverse()
        .map(x => parseInt(x));
      let lastDigit = arr.splice(0, 1)[0];
      let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0);
      sum += lastDigit;
      return sum % 10 === 0;
    };
    const isRepsolLengthAndStartWith = () => {
      return cardNumber.value.startsWith('7896981') && cardNumber.value.length === 19;
    };
    const isRepsolValid = async () => {
      if (!isLuhnValid(cardNumber.value) || !isRepsolLengthAndStartWith()) {
        errorMessage.value = 'loyalty.error_add';
        return false;
      } else return true;
    };

    // Card validation
    const validateForm = async () => {
      errorMessage.value = '';

      if (!cardNumber.value) {
        errorMessage.value = 'loyalty.error_field_required';
        return false;
      }

      if (props.program.name === 'Repsol Move') {
        return await isRepsolValid();
      }

      return true;
    };

    const sendSubscriptionNumber = async () => {
      // TODO:validation of the field
      const isValid = await validateForm();
      isValid && emit('cardNumber', cardNumber.value);
    };

    return {
      errorMessage,
      cardNumber,
      sendSubscriptionNumber,
      resetError,
      validateForm,
    };
  }
};
</script>