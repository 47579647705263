
import { getAllHistory, getEvent, createEventCostAPI, updateEventCostAPI, deleteEventCostAPI, uploadImageAPI } from '@/api/events';
import { EnrichedEventInfoTripAPI, EnrichedPaymentAPI, EnrichedCostAPI } from '@/api/types';
import {
  HistoryStateTypes,
  HistoryTripEvent,
  HistoryPaymentEvent,
  EventFilters,
  HistoryCostEvent,
  HistoryCreateCostPayload,
  HistoryTripEventDetail,
  HistoryPaymentEventDetail,
  HistoryCostDetail
} from './types';
import { eventsMap, getStartEnd, getCostAttributesMap } from './historyUtils';

const state: HistoryStateTypes = {
  events: [],
  event: null,
  eventSelectedFilter: 'ALL',
};

const mutations = {
  setEvents(state: HistoryStateTypes, payload: HistoryTripEvent[] | HistoryPaymentEvent[] | HistoryCostEvent[] | null) {
    state.events = payload
  },
  setEvent(state: HistoryStateTypes, payload: HistoryTripEventDetail | HistoryPaymentEventDetail | HistoryCostDetail | null) {
    state.event = payload;
  },
  setEventSelectedFilter(state: HistoryStateTypes, payload: EventFilters) {
    state.eventSelectedFilter = payload;
  },
  resetHistory(state: HistoryStateTypes) {
    state.events = []
    state.event = null;
    state.eventSelectedFilter = 'ALL';
  }
};
const actions = {
  async getHistoryEvents({ commit, rootState }, { page, refresh = false }: { page: number, refresh: boolean; }) {
    const filter = state.eventSelectedFilter === 'ALL' ? null : state.eventSelectedFilter;
    if (refresh) { commit('setEvents', []); }
    try {
      const getEventsFromState = state.events;

      const { items } = await getAllHistory(page, filter, rootState.auth.selectedVehicle.thingId);

      const historyEvents = items.reduce(
        (acc, event): HistoryTripEvent[] | HistoryPaymentEvent[] | HistoryCostEvent[] => {
          if (event.type === eventsMap['TRIP']) {
            const info = event.information.trip;

            const parsedEvent: HistoryTripEvent = {
              ...event,
              distanceM: info.distanceM,
              drivingScore: info.drivingScore,
              information: getStartEnd(info)
            };

            acc.push(parsedEvent);
          }
          if (event.type === eventsMap['PAYMENT']) {
            const info = event.information.payment;
            const parsedEvent: HistoryPaymentEvent = {
              ...event,
              information: info
            };
            acc.push(parsedEvent);
          }
          if (event.type === eventsMap['COST']) {
            const info = event.information.cost;
            const parsedEvent: HistoryCostEvent = {
              ...event,
              information: info
            };
            acc.push(parsedEvent);
          }
          return acc;
        }, []);
      const addEventsInState = [...getEventsFromState, ...historyEvents];

      commit('setEvents', addEventsInState);
    } catch (error) {
      commit('setEvents', []);
      throw error;
    }
  },

  async getEventById({ commit }, id: string) {
    try {
      const response = await getEvent(id);

      if (response.type === eventsMap['TRIP']) {
        const info = response.information.trip as EnrichedEventInfoTripAPI;

        commit('setEvent', {
          ...response,
          distanceM: info.distanceM,
          drivingScore: info.drivingScore,
          information: getStartEnd(info),
          timeliness: info.timeliness
        });
      }
      if (response.type === eventsMap['PAYMENT']) {
        const info = response.information.payment as EnrichedPaymentAPI;
        commit('setEvent', {
          ...response,
          information: info
        });
      }
      if (response.type === eventsMap['COST']) {
        const info = response.information.cost as EnrichedCostAPI;
        commit('setEvent', {
          ...response,
          information: info
        });
      }
    } catch (error) {
      commit('setEvent', null);
      throw error;
    }
  },
  setEventFilter({ commit }, event: EventFilters) {
    commit('setEventSelectedFilter', event);
  },
  async createEventCost(
    { dispatch, rootState },
    { payload, images }: {
      payload: HistoryCreateCostPayload,
      images: {
        imageBuffer: ArrayBuffer;
        type: string;
      }[];
    }
  ) {
    const parsePayload = {
      ...getCostAttributesMap(payload),
      thingId: rootState.auth.selectedVehicle.thingId
    };
    try {
      const response = await createEventCostAPI(parsePayload);
      if (images.length && response) {
        images.map(async (image) => await dispatch('uploadImage', { image: image.imageBuffer, type: image.type, id: response.id }));
      }
      await dispatch('getHistoryEvents', { page: 1, refresh: true });
    } catch (e) {
      throw e;
    }
  },
  async updateEventCost(
    { dispatch },
    { payload, id }: { payload: HistoryCreateCostPayload, id: string; }
  ) {
    const parsePayload = getCostAttributesMap(payload);

    try {
      await updateEventCostAPI(parsePayload, id);
      await dispatch('getHistoryEvents', {page: 1, refresh: true});
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteEventCost(
    { dispatch },
    { category, id, type }: { category: string, id: string, type: string; }
  ) {
    try {
      await deleteEventCostAPI(category, id, type);
      await dispatch('getHistoryEvents', {page: 1, refresh: true});
    } catch (error) {
      console.log(error);
      throw error;

    }
  },
  async uploadImage(_,{ image, type, id = null }: { image: ArrayBuffer, type: string, id: string; }) {
    const eventId = id ?? state.event.id;
    
    try {
      await uploadImageAPI(image, type, eventId);
    } catch (error) {
      console.log(error);

    }
  }
};
export default {
  state,
  actions,
  mutations,
};
