import { loadScript } from './externalScriptLoader.js';

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0
};

const allowedPaymentMethods = [
  {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: ['CRYPTOGRAM_3DS'],
      allowedCardNetworks: ['MASTERCARD', 'VISA']
    }
  }
];

if (import.meta.env.VUE_APP_ENV === 'dev') {
  allowedPaymentMethods[0].parameters.allowedAuthMethods.push('PAN_ONLY');
}

let gpayClient;

function getPaymentDataRequest({ orderId, amount, countryCode, currencyCode, gatewayMerchantId, isGuestUser }) {
  allowedPaymentMethods[0].parameters = {
    ...allowedPaymentMethods[0].parameters,
    ...(isGuestUser && { billingAddressRequired: true, billingAddressParameters: { format: 'FULL' } }),
  };

  const allowedPaymentMethodsWithTokenization = [...allowedPaymentMethods];
  allowedPaymentMethodsWithTokenization[0].tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'mpgs',
      gatewayMerchantId
    }
  };

  return {
    ...baseRequest,
    allowedPaymentMethods: allowedPaymentMethodsWithTokenization,
    ...(isGuestUser && { emailRequired: true }),
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPrice: amount.toFixed(2),
      currencyCode,
      transactionId: orderId,
      countryCode
    },
    merchantInfo: {
      merchantName: 'ryd',
      merchantId: 'BCR2DN4T7DOIFJJP'
    }
  };
}

export async function checkGPay() {
  try {
    if (!gpayClient) {
      await loadScript('https://pay.google.com/gp/p/js/pay.js');
      gpayClient = new window.google.payments.api.PaymentsClient({ environment: import.meta.env.VUE_APP_GPAY_ENV });
    }

    const { result } = await gpayClient.isReadyToPay({
      ...baseRequest,
      allowedPaymentMethods
    });

    return result;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export async function pay({ orderId, amount, countryCode, currencyCode, gatewayMerchantId, isGuestUser }) {
  return gpayClient.loadPaymentData(
    getPaymentDataRequest({ orderId, amount, countryCode, currencyCode, gatewayMerchantId, isGuestUser })
  );
}

export async function prefetch({ orderId, amount, countryCode, currencyCode, gatewayMerchantId }) {
  return gpayClient.prefetchPaymentData(
    getPaymentDataRequest({ orderId, amount, countryCode, currencyCode, gatewayMerchantId })
  );
}

export async function createButton(onClick) {
  return gpayClient.createButton({
    onClick,
    allowedPaymentMethods,
    buttonType: 'pay',
    buttonLocale: localStorage.getItem('lang'),
    buttonColor: 'white'
  });
}
