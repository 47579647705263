<template>
  <!-- Why all this repeated classes? 
      Hight resolution devices amazon button get smaller over time
      Forcing h and w is the only way I found
  -->
  <div class="amazon">
    <RPButton 
      v-if="amazonReady"
      theme="custom" 
      class="relative text-black rounded-sm bg-gradient-to-t from-[#f7f8fa] to-[#e7e9ec]"
      @click="triggerAmazonButton()"
    >
      <RPIcon type="amazon/amazon-pay" noFill class="mt-2" />
      <RPIcon type="amazon/amazon-arrows" noFill class="h-3 absolute right-8 top-3.5" />
    </RPButton>
    <div id="AmazonPayButton" class="hidden" />
  </div>
</template>

<script setup lang="ts">
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

import { stringMapper } from '@/i18n';
import { amazonButton } from '@/utils/amazonPay';
import { loadScript } from '@/utils/externalScriptLoader';
import { useI18n } from 'vue-i18n';
import { onMounted, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { omit } from 'lodash';

const props = defineProps({
  amount: {
    type: Number,
    required: true
  },
  currency: {
    type: String,
    required: true
  }
});

const route = useRoute();
const { locale } = useI18n();
const store = useStore();

const orderStepsStored = computed(() => omit(store.state.station.ui, ['orderId']));
const localeNormalized = () => {
  const findLocale = stringMapper[locale.value];
  const replaceUnderscore = findLocale.replace('-', '_');
  return replaceUnderscore === 'en_US' ? 'en_GB' : replaceUnderscore;
};

const amazonReady = ref(false);
const triggerAmazonButton = () => {
  document.getElementById('AmazonPayButton').click();
};

onMounted(async () => {
  await loadScript('https://static-eu.payments-amazon.com/checkout.js');
  const amountPrecisionTwo = props.amount.toFixed(2);
  try {
    const paramsToKeep =
      orderStepsStored.value.orderType === 'PREPAY'
        ? { ...route.query, ...orderStepsStored.value }
        : route.query;
    await amazonButton('#AmazonPayButton', localeNormalized(), props.currency, amountPrecisionTwo, paramsToKeep);
    amazonReady.value = true;
  } catch (e) {
    console.log(e);
  }
});
</script>
