import { RegisterTypes } from './types';

const state: RegisterTypes = {
  country: 'DE'
};

const mutations = {
  setState(state, { key, value }) {
    state[key] = value;
  }
};

export default {
  state,
  mutations
};
