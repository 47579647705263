<template>
  <button
    class="
      checkout-method-picker
      block
      border-none border-0 outline-none
      w-screen h-screen
      bg-black/50
      cursor-default
      fixed top-0 left-0
      z-50
      p-0
    "
    @click.self.stop="$emit('close')">
    <div
      class="bg-primary-background h-2/5 w-full rounded-t-lg box-border fixed bottom-0 z-[52] px-4 py-6 overflow-y-auto"
      @click.stop>
      <div class="checkout-method-picker__cards-container mx-auto">
        <h4 class="text-left text-card-text text-base l-scape:text-fs-32 mt-0 mb-8">
          {{ $t('fueling.finish.checkout_modal_title') }}
        </h4>
        <RPCard
          class="mb-2 l-scape:py-4 flex justify-center"
          :class="{
            'border-primary-accent': payment.id === checkoutMethod.id,
            'border-card-disabled pointer-events-none': payment.pmIsNotSupportedOnDevice
          }"
          hasClick
          small
          v-for="payment in activePaymentsSupportedByPoi"
          :key="payment.id"
          @click="paymentClick(payment)">
          <div class="flex items-center font-medium">
            <RPIcon no-fill :type="getPaymentSvg(payment.method)" class="h-xxl l-scape:w-20 mr-3" />
            <p v-if="payment.pmIsNotSupportedOnDevice" class="m-0 mt-[2px]">
              {{ $t("profile.main.payment-method.not_supported_pm") }}
            </p>
            <span v-else class="truncate">{{ getDetails(payment) }}</span>
          </div>
        </RPCard>
        <RPButton outline class="mx-auto mt-5" @click="$emit('addPm')">
          {{ $t('fueling.add_PM') }}
        </RPButton>
      </div>
    </div>
  </button>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { usePayments } from '@/composable/usePayments';

import RPCard from '@/components/Atoms/RPCard/RPCard.vue';
import RPButton from '@/components/Atoms/RPButton/RPButton.vue';
import RPIcon from '@/components/Atoms/RPIcon/RPIcon.vue';

export default {
  components: { RPCard, RPButton, RPIcon },
  emits: ['close', 'changedPM', 'addPm'],
  setup() {
    const { PAYMENT_METHODS_MAP, PAYMENTS_WALLET } = usePayments();
    return {
      PAYMENT_METHODS_MAP,
      PAYMENTS_WALLET
    };
  },
  computed: {
    ...mapState({
      activePaymentsSupportedByPoi: state => state.psp.activePaymentsSupportedByPoi,
      isLandscape: state => state.ui.query.landscape
    }),
    ...mapGetters({
      checkoutMethod: 'psp/checkoutMethod'
    })
  },
  methods: {
    getPaymentSvg(method) {
      return this.PAYMENT_METHODS_MAP[method].svg;
    },
    getPaymentText(method) {
      return this.PAYMENT_METHODS_MAP[method].text;
    },
    getDetails(payment) {
      const normalizeMethod = payment.method.toUpperCase();

      if (this.PAYMENTS_WALLET.includes(normalizeMethod)) {
        return this.getPaymentText(normalizeMethod);
      }

      return normalizeMethod == 'MBWAY'
        ? `+${payment.details.phoneNumber.replace('#', '')}`
        : normalizeMethod == 'PAYPAL'
          ? payment.details.email
          : `${payment.details.brand} ${payment.details.cardNumber}`;
    },
    paymentClick(payment) {
      this.$emit('changedPM', payment);
    }
  }
};
</script>

<style lang="scss" scoped>
.checkout-method-picker {
  &__cards-container {
    max-width: var(--appMaxWidth);
  }

  &__modal {
    left: 50%;
    width: var(--appMaxWidth);
    transform: translateX(-50%);
  }
}
</style>
