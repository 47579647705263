<template>
  <component :is="iconComponent" class="rp-icon nofill" :class="{'rp-icon__fill': !noFill }" />
</template>

<script >
import { defineAsyncComponent, computed } from 'vue';
export const ICON_THEMES = {
  primary: 'var(--colorIconPrimary)',
  secondary: 'var(--colorIconSecondary)',
  text: 'var(--colorIconText)'
};

export default {
  props: {
    svgFill: {
      type: String,
      default: 'primary',
      validator: value => Object.keys(ICON_THEMES).includes(value)
    },
    type: {
      type: String,
      required: true
    },
    noFill: {
      type: Boolean,
      default: false
    },
    fallback: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const svgColor = computed(() => {
      return ICON_THEMES[props.svgFill];
    });

    const fallbackComponent = computed(() => {
      return defineAsyncComponent({
        loader: () => {
          if (props.fallback) return import(`./assets/fallbacks/${props.fallback}.svg`);
        }
      });
    });

    const iconComponent = computed(() => {
      const icon = props.type;
      return defineAsyncComponent({
        loader: () => {
          if (icon.includes('/')) {
            // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#globs-only-go-one-level-deep
            const splitIconType = icon.split('/');
            return import(`./assets/${splitIconType[0]}/${splitIconType[1]}.svg`);
          } else {
            return import(`./assets/${icon}.svg`);
          }
        },
        errorComponent: props.fallback ? fallbackComponent.value : null
      });
    });

    return { svgColor, iconComponent };
  }
};
</script>

<style lang="scss">
svg.rp-icon {
  @apply inline-block;

  &__fill {
    path,
    rect {
      fill: v-bind(svgColor);
    }
    circle {
      stroke: v-bind(svgColor);
    }
  }
}
</style>
