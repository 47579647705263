import StationData from '@/rydPaySDK/src/StationData';
import router from '@/router.js';
import mtskJSON from '@/assets/mtsk/mtsk.json';

import { StationUITypes, StationTypes } from './types';
import { Station as StationDataTypes, PumpData } from '@/rydPaySDK/src/types/StationData';
import { omitBy, pick, pickBy } from 'lodash';

const stationData = new StationData();

const meansToMethodsMap = {
  VISA: 'DIRECT_CREDIT',
  MAESTRO: 'DIRECT_CREDIT',
  MASTERCARD: 'DIRECT_CREDIT',
  DIRECT_DEBIT: 'DIRECT_DEBIT',
  AMERICAN_EXPRESS: 'DIRECT_CREDIT',
  GOOGLE_PAY: 'GOOGLE_PAY',
  APPLE_PAY: 'APPLE_PAY',
  AMAZON_PAY: 'AMAZON_PAY',
  PAYPAL: 'PAYPAL',
  MBWAY: 'MBWAY'
};

const defaultUi: StationUITypes = {
  pumpId: null,
  fuelTypeId: null,
  amount: null,
  orderType: null,
  orderId: null
};

const defaultData: StationDataTypes = {
  id: '',
  pumps: [],
  fuelPrices: [],
  features: [],
  name: {
    brand: '',
    address: '',
    country: ''
  },
  supportedPaymentMeans: [],
  supportedLoyaltyPrograms: [],
  status: 'OFFLINE',
  partnerName: null
};

const getDefaultState = (): StationTypes => {
  return {
    loading: false,
    ui: { ...defaultUi },
    data: { ...defaultData },
    error: null
  };
};
const state: StationTypes = getDefaultState();

const mutations = {
  setData(state: StationTypes, data) {
    state.data = data;
  },
  /**
   * @name saveURLParamsForTrackOrder
   * @description Retrieve all URL params and store only the one that belongs to this state.ui
   * to track the order state
   * @param state
   * @param payload URL parameters
   */
  saveURLParamsForTrackOrder(state: StationTypes, payload) {
    const attributesToKeep = pick(payload, Object.keys(state.ui));

    for (const stateKey in attributesToKeep) {
      if (stateKey === 'pumpId' && !payload.orderType) {
        const pumpData = state.data.pumps.find(i => i.id === payload.pumpId);
        state.ui.orderType = pumpData.type;
      }
      state.ui[stateKey] = attributesToKeep[stateKey];
    }
  },
  resetUIState(state: StationTypes) {
    Object.assign(state.ui, getDefaultState().ui);
  },
  setLoading(state: StationTypes, payload) {
    state.loading = payload;
  },
  setError(state: StationTypes, error) {
    state.error = error;
  }
};

const actions = {
  async startLoading({ commit, dispatch, rootState }) {

    return new Promise<void>(function (resolve, reject) {
      commit('ui/setLoading', true, { root: true });
      stationData.start(rootState.ui.query.pid, (response, err) => {
        if (rootState.ui.loading) {
          commit('ui/setLoading', false, { root: true });
        }
        if (err) {
          return reject(err);
        }
        commit('setData', response);
        resolve();
      });
    });
  },
  stopLoading() {
    stationData.stop();
  },
  async getOnce({ commit, rootState, dispatch }) {

    commit('setError', null);
    commit('setLoading', true);

    try {
      const poiId = rootState.ui.query.pid;
      const accountId = rootState.auth.accountId;

      if (poiId) {
        const getOnceData = await stationData.getOnce(poiId);
        
        return commit('setData', getOnceData);
      }

      const mtskId = rootState.ui.query.mtskid;

      if (mtskId) {
        const mtskMapper = mtskJSON;

        if (mtskMapper[mtskId]) {

          const location: string = window.document.location.toString();
          const url = new URL(location);

          url.searchParams.append('pid', mtskMapper[mtskId]);
          url.searchParams.delete('mtskid');

          await router.replace(url.pathname + url.search);

          dispatch('ui/saveURLParamsForUi', undefined, { root: true });

          const data = await stationData.getOnce(mtskMapper[mtskId]);
          return commit('setData', data);
        }
      }
      // TODO Remove this. No longer used
      if (rootState.ui.query.longitude) {
        const { latitude, longitude } = rootState.ui.query;
        commit('ui/clearLonLat', undefined, { root: true });

        await dispatch('fetchPOIbyLocations', { latitude, longitude });
      }

    } catch (e) {
      commit('setError', e.code === 'CUSTOM_ERROR' ? e.message : e);
      throw new Error(e);

    } finally {
      commit('setLoading', false);
    }
  },
  async fetchPOIbyLocations(
    { commit, rootState, dispatch },
    { latitude, longitude }: { latitude: number; longitude: number; }
  ) {
    try {
      const station = await stationData.getOnceByGelolocation({ latitude, longitude });
      if (station) {
        commit('setData', station);
        const currentPath = router.currentRoute.value.path;
        const getOtherParamsFormStore = pickBy(rootState.ui.query, attr => attr !== null);

        await router.replace({
          path: currentPath,
          query: {
            pid: station.id,
            ...getOtherParamsFormStore
          }
        });

        dispatch('ui/saveURLParamsForUi', undefined, { root: true });
        await dispatch('getOnce');
      }
    } catch (e) {
      console.log('No station at this location');
      throw (e);
    }


  }
};

const getters = {
  chosenPump(state: StationTypes): PumpData | null {
    if (!state.ui.pumpId) {
      return null;
    }
    return state.data.pumps.find(i => i.id === state.ui.pumpId);
  },
  chosenFuel(state) {
    if (!state.ui.pumpId) {
      return null;
    }
    return state.data.fuelPrices.find(i => i.priceId === state.ui.fuelTypeId);
  },
  isPumpChoosen(state: StationTypes) {
    return !!state.ui.pumpId;
  },
  buildURLParamsFromState(state) {
    const removeNullValues = omitBy(state.ui, attr => attr === null);
    return new URLSearchParams(removeNullValues).toString();
  },
  currentPID(state: StationTypes) {
    return state.data?.id;
  },
  supportedPaymentMethods(state: StationTypes) {
    return state.data.supportedPaymentMeans.reduce((acc, supportedPaymentMean) => {
      const paymentMethod = meansToMethodsMap[supportedPaymentMean];

      if (paymentMethod && !acc.includes(paymentMethod)) {
        acc.push(paymentMethod);
      }

      return acc;
    }, []);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
